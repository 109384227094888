import axios from "axios";
// http://test.aiapidjm.imoreme.com/vivo/detect/free
 export const baseUrl = "http://aiapidjm.imoreme.com";
// export const baseUrl = "http://test.aiapidjm.imoreme.com";

// axios的实例及拦截器配置
const axiosInstance = axios.create({
  baseURL: baseUrl
});

axiosInstance.interceptors.response.use(
  res => res.data,
  err => {
    console.log(err, "网络错误");
  }
);

export { axiosInstance };
