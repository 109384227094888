import React from 'react';
import { connect } from 'react-redux';
// import logo from '../logo.svg';
import actions from "../../action";
import "animate.css";
import WOW from 'wowjs';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

/**
 * @Description: AI检测识别技术Icon
 */
import './App.scss';
import FaceRecognition from '../../static/home/Face-recognition-icon@2x.png';
import FaceRecognitionActive from '../../static/home/Face-recognition-icon-active@2x.png';
import SkinTestIcon from '../../static/home/skin-test-icon@2x.png';
import SkinTestIconActive from '../../static/home/skin-test-icon-active@2x.png';
import PetectionIcon from '../../static/home/pathological-detection-icon@2x.png';
import PetectionIconActive from '../../static/home/pathological-detection-icon-active@2x.png';
// import BodyDetectionIcon from '../../static/home/Body-detection-icon@2x.png';
// import BodyDetectionIconActive from '../../static/home/Body-detection-icon-active@2x.png';

import FacialParalysisDiagnosisIcon from '../../static/home/Facial-paralysis-diagnosis-icon@2x.png';
import FacialParalysisDiagnosisIconActive from '../../static/home/Facial-paralysis-diagnosis-icon-active@2x.png';
import ImageRecognitionIcon from '../../static/home/Image-recognition-icon@2x.png';
import ImageRecognitionIconActive from '../../static/home/Image-recognition-icon-active@2x.png';


import FaceDetection from '../../static/home/Face_detection@2x.png';
import FaceKeyPoints from '../../static/home/FaceKeyPoints@2x.png';
import FaceAttributeAnalysis from '../../static/home/Face_attribute_analysis@2x.png';

import SkinBeautyOne from '../../static/home/skin-beauty-one@2x.png';
import SkinBeautyTwo from '../../static/home/skin-beauty-two@2x.png';
import SkinBeautyThree from '../../static/home/skin-beauty-three@2x.png';
import FacialParalysisOne from '../../static/home/Facial-paralysis-one@2x.png';
import FacialParalysisTwo from '../../static/home/Facial-paralysis-two@2x.png';
import FacialParalysisThree from '../../static/home/Facial-paralysis-three@2x.png';
import ConventionalPathologyOne from '../../static/home/Conventional-pathology-one@2x.png';
import ConventionalPathologyTwo from '../../static/home/Conventional-pathology-two@2x.png';
import ConventionalPathologyThree from '../../static/home/Conventional-pathology-three@2x.png';
import ARProductsOne from '../../static/home/AR-products-one@2x.png';
import ARProductsTwo from '../../static/home/AR-products-two@2x.png';
import ARProductsThree from '../../static/home/AR-products-three@2x.png';


import Swiper from '../../component/swiper/swiper'
import AccessButton from '../../component/widget/AccessButton'


import HomeBanner from '../../static/home/Home_Banner@2x.png';
import StandardProtocolIcon from '../../static/home/Standard-protocol-icon@2x.png';
import BillionsSupport from '../../static/home/Billions-support@2x.png'
import Authentication from '../../static/home/authentication@2x.png'
import Technology from '../../static/home/technology@2x.png'


import enterpriseServiceIcon from '../../static/home/enterprise-service-icon@2x.png';
import BeautyMakeupStores from '../../static/home/Beauty_makeup_stores@2x.png';
import HealthAndBeautyIcon from '../../static/home/Health-and-beauty-icon@2x.png';
import HardwareFacilitiesIcon from '../../static/home/Hardware-facilities-icon@2x.png';
import InternetThingsIcon from '../../static/home/Internet-things-icon@2x.png';
import WEBAPIIllustrator from '../../static/home/WEB-API-Illustrator@2x.png';
import MobileSDKIllustrator from '../../static/home/Mobile-SDK-Illustrator@2x.png';
import SloveAppIllustrator from '../../static/home/Slove-app-Illustrator@2x.png';
// import partnersImg from '../../static/home/partners@2x.png';
import enterpriseService from '../../static/home/1.enterprise-service.png';
import PicturesBeautyShop from '../../static/home/2.Pictures-beauty-shop.png';
import MedicalCosmetologyPictures from '../../static/home/3.Medical-cosmetology-pictures.png';
import PictureHardwareFacilities from '../../static/home/4.Picture-hardware-facilities.png';
import InternetThingsPictures from '../../static/home/5.Internet-things-pictures.png';
import Mask from '../../static/home/mask.png';
import Partners1 from '../../static/home/partners/1.png'
import Partners2 from '../../static/home/partners/2.png'
import Partners3 from '../../static/home/partners/3.png'
import Partners4 from '../../static/home/partners/4.png'
import Partners5 from '../../static/home/partners/5.png'
import Partners6 from '../../static/home/partners/6.png'
import Partners7 from '../../static/home/partners/7.png'
import Partners8 from '../../static/home/partners/8.png'
import Partners9 from '../../static/home/partners/9.png'
import Partners10 from '../../static/home/partners/10.png'

const PartnersImgs = [Partners1, Partners2, Partners3, Partners4, Partners5, Partners6, Partners7, Partners8, Partners9, Partners10]
// import ThemeFactory, {ThemeFlags} from "../../res/styles/ThemeFactory";


interface AppState {
    aiDetectionContainerActive: number,
    aiDetectionContainer: any
}

interface Props extends React.Props<any> {
    onThemeChange: Function,
    dispatch: Function,
    themeColor: any
    AIslidesPerView: number
}

class App extends React.Component<Props, AppState> {
    constructor(Props: any) {
        super(Props)
        // console.log(Props);
        // dispatch({ type: 'INCREMENT' });
        // dispatch({ type: 'DECREMENT' });
        this.state = {
            aiDetectionContainerActive: 0,
            aiDetectionContainer: [
                {
                    name: "人脸识别", imgSrc: FaceRecognition, activeImgSrc: FaceRecognitionActive,
                    centent: [
                        {
                            name: "人脸识别",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "人脸关键点检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "人脸属性分析",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }]
                },
                {
                    name: "皮肤美容", imgSrc: SkinTestIcon, activeImgSrc: SkinTestIconActive,
                    centent: [
                        {
                            name: "色斑、毛孔、细纹、痘痘",
                            centent: "检测图像美学方面表现力，输出各属性针对性的 评分。分数属性包括内容、光效、色彩、和谐度、 构图以及总分。",
                            imgSrc: SkinBeautyOne,
                        }, {
                            name: "年轻老化模拟",
                            centent: "针对面部多个年龄段的皱纹、色斑等维度进行年轻/老化程度模拟。",
                            imgSrc: SkinBeautyTwo,
                        }, {
                            name: "棕色图",
                            centent: "识别图像中的人或物和景，输出人、景、物的方框 及关键点坐标。",
                            imgSrc: SkinBeautyThree,
                        }]
                },
                {
                    name: "常规病理", imgSrc: PetectionIcon, activeImgSrc: PetectionIconActive,
                    centent: [
                        {
                            name: "银屑病",
                            centent: "检测图像美学方面表现力，输出各属性针对性的 评分。分数属性包括内容、光效、色彩、和谐度、 构图以及总分。",
                            imgSrc: ConventionalPathologyOne,
                        }, {
                            name: "丘疹",
                            centent: "检测并定位人脸118个关键点坐标，几乎 所有人脸编辑功能都需要用到此技术。",
                            imgSrc: ConventionalPathologyTwo,
                        }, {
                            name: "病毒性痤疮",
                            centent: "识别图像中的人或物和景，输出人、景、物的方框 及关键点坐标。",
                            imgSrc: ConventionalPathologyThree,
                        }]
                },
                {
                    name: "面瘫检测", imgSrc: FacialParalysisDiagnosisIcon, activeImgSrc: FacialParalysisDiagnosisIconActive,
                    centent: [
                        {
                            name: "面部表情",
                            centent: "检测图像美学方面表现力，输出各属性针对性的 评分。分数属性包括内容、光效、色彩、和谐度、 构图以及总分。",
                            imgSrc: FacialParalysisOne,
                        }, {
                            name: "眼睛、鼻子、嘴巴",
                            centent: "检测并定位人脸118个关键点坐标，几乎 所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FacialParalysisTwo,
                        }, {
                            name: "面部行为",
                            centent: "识别图像中的人或物和景，输出人、景、物的方框 及关键点坐标。",
                            imgSrc: FacialParalysisThree,
                        }]
                },
                {
                    name: "AR产品应用", imgSrc: ImageRecognitionIcon, activeImgSrc: ImageRecognitionIconActive,
                    centent: [
                        {
                            name: "AR试妆",
                            centent: "检测图像美学方面表现力，输出各属性针对性的 评分。分数属性包括内容、光效、色彩、和谐度、 构图以及总分。",
                            imgSrc: ARProductsOne,
                        }, {
                            name: "AR眼镜穿戴",
                            centent: "检测并定位人脸118个关键点坐标，几乎 所有人脸编辑功能都需要用到此技术。",
                            imgSrc: ARProductsTwo,
                        }, {
                            name: "AR珠宝试戴",
                            centent: "识别图像中的人或物和景，输出人、景、物的方框 及关键点坐标。",
                            imgSrc: ARProductsThree,
                        }]
                },
            ]
        }
    }

    componentDidMount() {
        const wow = new WOW.WOW({live:false,mobile:true});
        wow.init();
    }
    handleMouseUserOver(index: any) {
        this.setState({
            aiDetectionContainerActive: index
        })
    }

    handleMouseOut() {
        console.log('handleMouseOut')
    }

    getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    bannerContainer() {
        const views: any = [];
        [HomeBanner, HomeBanner, HomeBanner, HomeBanner, HomeBanner].forEach((imgSrc, index) => {
            views.push(
                <div className="swiper-slide" key={index}>
                    <div className={"ad"}>
                        <strong>DJM VisionSeed智能视觉模组</strong>
                        <span>AI算法+算力+摄像头一体化，VisionSeed让AI视觉触手可及</span>
                    </div>
                    <img src={imgSrc} alt="" />
                </div>
            )
        })
        return views;
    }

    aiDetectionContainer() {
        const views: any = [];
        this.state.aiDetectionContainer[this.state.aiDetectionContainerActive].centent.forEach((item: any, index: number) => {
            views.push(
                <div className="swiper-slide" key={index}>
                    <div className={"bottom-item " + (!index ? "active" : "")} key={index}>
                        <img src={item.imgSrc} alt="" />
                        <div className="name">
                            <strong>{item.name}</strong>
                            <AccessButton />
                        </div>
                        <p>{item.centent}</p>
                    </div>
                </div>
            )
        })
        return views;
    }

    render() {
        const { AIslidesPerView } = this.props;
        return (
            <div id="index" ref={"index"}>
                <Swiper domClass={'banner-container'} domList={this.bannerContainer()} option={{
                    autoplay: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                }} />
                <div className="ai-detection-identification">
                    <div className="wow slideInRigh animate__animated animate__fadeInDown title">AI检测识别技术</div>
                    <div className="tab-nav">
                        {this.state.aiDetectionContainer.map((item: any, index: any) => {
                            return (
                                <div
                                    className={"tab-item " + (this.state.aiDetectionContainerActive === index ? "active" : "")}
                                    key={index}
                                    onMouseOver={() => {
                                        this.handleMouseUserOver(index)
                                    }}
                                >
                                    <img
                                        src={this.state.aiDetectionContainerActive === index ? item.activeImgSrc : item.imgSrc}
                                        alt="" />
                                    <span>{item.name}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div className="bottom">
                        <Swiper domClass={'ai-detection-container'}
                            domList={this.aiDetectionContainer()} option={{
                                // loop: true,
                                on: {
                                    transitionEnd: function () {
                                        // @ts-ignore
                                        // console.log(this.activeIndex)
                                    },
                                },
                                // autoplay: true,
                                slidesPerView: AIslidesPerView,
                                spaceBetween: 30,
                                pagination: {
                                    el: AIslidesPerView === 2 ? '.swiper-pagination' : undefined,
                                    clickable: true,
                                }
                            }} />
                    </div>
                    {/*</div>*/}
                </div>

                <div className="core-strengths">
                    <div className="wow slideInRigh animate__animated animate__fadeInDown title" data-wow-offset="300">核心优势</div>
                    <ReactCSSTransitionGroup
                        transitionLeave={true}
                        transitionEnterTimeout={2500}
                        transitionLeaveTimeout={1500}
                        transitionName="animate__animated"
                    >
                        <div className="core-strengths-box" key="amache">
                            {[
                                {
                                    name: "标准协议",
                                    centent: "支持MQTT/CoAP等物联网\n" +
                                        "                                        协议实现设备与云端双向\n" +
                                        "                                        连接和控制。",
                                    imgSrc: StandardProtocolIcon
                                }, {
                                    name: "百亿数据支撑",
                                    centent: "算法的落地场景丰富，用户\n" +
                                        "反馈广泛且快速。独有的海\n" +
                                        "量数据，提供了强大的支撑。",
                                    imgSrc: BillionsSupport
                                }, {
                                    name: "数亿用户验证",
                                    centent: "前沿技术能快速与产品结合，\n" +
                                        "日调用量逾亿次，兼具稳定性\n" +
                                        "与实用性，是您可靠的选择。",
                                    imgSrc: Authentication
                                }, {
                                    name: "技术艺术交融",
                                    centent: "拥有长期的审美积累，艺术\n" +
                                        "基因深植其中，能够把握美\n" +
                                        "的趋势，实现艺术与技术的\n" +
                                        "有机结合。",
                                    imgSrc: Technology
                                },
                            ].map((item, index) => {
                                return (
                                    <div className="wow slideInRigh animate__animated animate__fadeInUp centent-box" data-wow-offset="100" key={index}>
                                        <img className="centent-img" src={item.imgSrc} alt="" />
                                        <div className="centent-name">{item.name}</div>
                                        <div className="centent">
                                            {item.centent}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </ReactCSSTransitionGroup>
                </div>
                <div className="application-scenarios">
                    <div className="wow slideInRigh animate__animated animate__fadeInDown top-title">丰富业务场景 助力行业前行</div>
                    <div className="bottom-centent">
                        {[
                            {
                                name: "企业服务",
                                bgSrc: {
                                    backgroundImage: 'url(' + enterpriseService + ')'
                                },
                                imgSrc: enterpriseServiceIcon,
                                centent: "提供多样化解决方案<br/>" +
                                    "协助企业实现业务升级"
                            }, {
                                name: "美妆门店",
                                bgSrc: {
                                    backgroundImage: 'url(' + PicturesBeautyShop + ')'
                                },
                                imgSrc: BeautyMakeupStores,
                                centent: "精准分析   智能试妆<br/>" +
                                    "丰富技术实现智能线下美业"
                            }, {
                                name: "医疗美容",
                                bgSrc: {
                                    backgroundImage: 'url(' + MedicalCosmetologyPictures + ')'
                                },
                                imgSrc: HealthAndBeautyIcon,
                                centent: "独家AI皮肤分析<br/>" +
                                    "打造智慧医美门店"
                            }, {
                                name: "硬件设施",
                                bgSrc: {
                                    backgroundImage: 'url(' + PictureHardwareFacilities + ')'
                                },
                                imgSrc: HardwareFacilitiesIcon,
                                centent: "丰富自研AI技术<br/>" +
                                    "助力硬件产品智能化",
                            }, {
                                name: "物联网",
                                bgSrc: {
                                    backgroundImage: 'url(' + InternetThingsPictures + ')'
                                },
                                imgSrc: InternetThingsIcon,
                                centent: "精准识别及精细化影像处理<br/>" +
                                    "灵活适配各类业务场景"
                            }, {
                                name: "医疗美容",
                                bgSrc: {
                                    backgroundImage: 'url(' + MedicalCosmetologyPictures + ')'
                                },
                                imgSrc: HealthAndBeautyIcon,
                                centent: "独家AI皮肤分析<br/>" +
                                    "打造智慧医美门店"
                            }
                        ].map((item, index) => {
                            return (
                                <div className="bottom-item" key={index} style={item.bgSrc}>
                                    <img className={"mask"} src={Mask} alt="" />
                                    <img className={"img"} src={item.imgSrc} alt="" />
                                    <span className={"name"}>{item.name}</span>
                                    <p className={"centent"} dangerouslySetInnerHTML={{ __html: item.centent }}></p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="adapt-scene">
                    <ReactCSSTransitionGroup
                        transitionLeave={true}
                        transitionEnterTimeout={2500}
                        transitionLeaveTimeout={1500}
                        transitionName="animated"
                    >
                        <div key="amache">
                            <div className="wow slideInRigh animate__animated animate__fadeInDown top-title" data-wow-offset="100">多样接入方式 灵活适配场景</div>
                            <div className="bottom-centent">
                                {
                                    [
                                        {
                                            name: "WEB API",
                                            centent: "通过Web API调用，快速灵活接入DJM   AI开放平台的算法服务。",
                                            imgSrc: WEBAPIIllustrator
                                        }, {
                                            name: "MOBILE SDK",
                                            centent: "集成Mobile SDK到您的应用中，接入各种离线功能和实时算法。",
                                            imgSrc: MobileSDKIllustrator
                                        }, {
                                            name: "行业解决方案",
                                            centent: "根据您的业务场景和需求，为您定制完整的软硬件解决方案。",
                                            imgSrc: SloveAppIllustrator
                                        },
                                    ].map((item, index) => {
                                        return (
                                            <div className="wow slideInRigh animate__animated animate__fadeInLeftBig bottom-item" data-wow-offset="100" key={index}>
                                                <img className={"img"} src={item.imgSrc} alt="" />
                                                <strong className={"name"}>{item.name}</strong>
                                                <div className={"centent"}>{item.centent}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </ReactCSSTransitionGroup>

                </div>
                <div className="partners">
                    <ReactCSSTransitionGroup transitionEnter={true}
                        transitionLeave={true}
                        transitionEnterTimeout={2500}
                        transitionLeaveTimeout={1500}
                        transitionName="animated"
                    >
                        <div className="wow slideInRigh animate__animated animate__slideInDown top-title" data-wow-offset="100">合作伙伴</div>
                        <div key="amache" className="wow slideInRigh animate__animated animate__fadeInUp bottom-centent" data-wow-offset="100">
                            {
                                PartnersImgs.map((item, index) => {
                                    return (
                                        <img key={index} src={item} alt="" />
                                    )
                                })
                            }
                        </div>
                    </ReactCSSTransitionGroup>

                </div>
            </div>
        );
    }
}

const mapPopularStateToProps = (state: any) => ({
    themeColor: state.themeStore.themeColor,
    AIslidesPerView: state.themeStore.AIslidesPerView
});
const mapPopularDispatchToProps = (dispatch: Function) => ({
    onThemeChange: (theme: any) => dispatch(actions.onThemeChange(theme))
});
export default connect(mapPopularStateToProps, mapPopularDispatchToProps)(App);
