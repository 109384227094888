import React from 'react';
import {connect} from 'react-redux';
import actions from '../../action/index'
import '../header/header.scss';
import logo from '../../static/home/DJM-Logo@2x.png';
import TelIocn from '../../static/home/tel-icon2@2x.png';
import QrImg from '../../static/home/qrcode-gzh.png';
// import _ from 'underscore'
import {
    NavLink
} from "react-router-dom";

import MetisPositive from '../../static/productService/metis_positive@2x.png';
import MetisSide from '../../static/productService/metis_side@2x.png';
import MaomiPositive from '../../static/productService/maomi_positive@2x.png';
import PH from '../../static/home/PH.png';

interface Props extends React.Props<any> {
    themeColor: any,
    width: any
    onThemeChange: any
}

class headerCus extends React.Component<Props, any> {
    // private backgroundColor: any;
    constructor(Props: any) {
        super(Props);
        this.state = {
            scrollTop: 0,
            width: Props.width
        };
        // alert(Props.width);
        // console.log(Props);
        this.changeMargin = this.changeMargin.bind(this);
        // This binding is necessary to make `this` work in the callback /productService.html
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<any>, nextContext: any) {
        // console.log(nextProps, nextState);
        if (nextState.width !== nextProps.width) {
            this.setState({
                width: nextProps.width
            })
        }
        return true
    }

    componentDidMount() {
        // if (this.state.width > 500) {
        window.addEventListener('scroll', this.handleScroll.bind(this))
        window.removeEventListener('scroll', this.handleScroll.bind(this));
        // }
    }



    handleScroll() {
        // console.log(document.documentElement.scrollTop,`background-color: rgba(28,28,28,0.5);`);
        // if (this.state.width > 500) {
        let scrollTop = document.documentElement.scrollTop;
        this.setState({
            scrollTop: scrollTop
        })
        // }
    }

    changeMargin() {
        //获取网页可见区域宽度
        const {onThemeChange} = this.props;
        var width = document.documentElement.clientWidth;
        onThemeChange({width: width, AIslidesPerView: width > 500 ? 3 : 2});
        var remRoot = width / 1920 * 100;
        document.documentElement.style.fontSize = width > 500 ? remRoot + "px" : Math.floor(remRoot * 2.5) + "px"
    };

    UNSAFE_componentWillMount() {
        this.changeMargin();
        window.onresize = this.changeMargin;
        // window.addEventListener('onresize', _.throttle((e: any) => this.changeMargin, 250))
    }

    scrollTopZero() {
        let scrollTop = document.documentElement.scrollTop, animate = (duration: any, timing: any, draw: any) => {
            let start = performance.now();
            requestAnimationFrame(function animate(time) {
                // timeFraction 从 0 增加到 1
                let timeFraction = (time - start) / duration;
                if (timeFraction > 1) timeFraction = 1;
                // 计算当前动画状态
                let progress = timing(timeFraction);
                draw(progress); // 绘制
                if (timeFraction < 1) {
                    requestAnimationFrame(animate);
                }
            });
        };
        animate(300, function (timeFraction: any) {
            return timeFraction;
        }, function (progress: any) {
            document.documentElement.scrollTop = scrollTop - progress * scrollTop;
        });
    }

    render() {
        // `rgba(28,28,28,${this.state.scrollTop})`
        // console.log(this.state.width);
        let top = this.state.width > 500 ? this.state.scrollTop : 1;
        return (
            <>
                <div id="header" className="animate__animated animate__fadeInDown" style={{backgroundColor: `rgba(28,28,28,${top})`}}>
                    <div className="logo-name">
                        <img className="logo" src={logo} alt=""/>
                        <span className="name">AIoT开放平台</span>
                    </div>
                    <div className={"nav"}>
                        <div className={"mobile-nav"}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <ul className="navMeun">
                            {
                                [{name:"首页",like:"/index"},{name:"产品服务",like:"/productService"},{name:"解决方案",like:"/solution"},
                                {name:"文档中心",like:"/openPlatform"},{name:"关于我们",like:"/aboutUs"}].map((item,index)=>{
                                    return <li className={`${index===1?'productService':`${index === 3 ? 'openPlatform' : ''}`}`} key={index}>
                                                <NavLink className={"to-like"} activeClassName="active" key={item.like}
                                                         onClick={(e) => {
                                                             if(index === 1){e.preventDefault();e.stopPropagation();}
                                                         }} to={item.like}>{item.name}
                                                </NavLink>
                                                {
                                                    index === 1 ?
                                                        <div className={"pop-up"}>
                                                            <div className={"product-img-box"}>
                                                                {[{imgSrc: MetisSide, name: "Metis检测仪"}, {
                                                                    imgSrc: MetisPositive,
                                                                    name: "猫咪检测仪"
                                                                }, {imgSrc: MaomiPositive, name: "猫咪智能镜"}, {imgSrc: PH, name: "水油PH检测"}]
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <div className={"product-img-item"} key={index}>
                                                                                <img src={item.imgSrc} alt=""/>
                                                                                <span>{item.name}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>
                                                            <div className={"face-technology"}>
                                                                {/*<strong className={"face-technology-title"}>人脸技术</strong>*/}
                                                                <div className={"face-technology-name-box"}>
                                                                    {["人脸检测", "肤质判断", "皱纹检测", "粗大毛孔检测", "敏感检测", "色斑检测", "痤疮检测", "皮肤病识别",
                                                                        "五官分析", "面瘫检测", "头皮检测", "AR试妆"].map((item, index) => {
                                                                        return (
                                                                            // <div key={index}>{item}</div>
                                                                            <NavLink className={"face-technology-name"} key={index} to="/productService/user">
                                                                                {item}
                                                                                {/*<div className={"exclusive"}>独家</div>*/}
                                                                            </NavLink>
                                                                        )
                                                                    })}
                                                                    {/*    onClick={()=>{*/}
                                                                    {/*    // to="/productService/user"*/}
                                                                    {/*    window.location.href = "/productService/user";*/}
                                                                    {/*}}>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="login-register">
                    <span className="login">
                        <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer" target="_blank">登录</a>
                    </span>
                        <span className={"dividing-line"}></span>
                        <span className="register">
                        <a href="http://iotdjm.imoreme.com/registerretrievepassword.html?key=register"
                           rel="noopener noreferrer" target="_blank">注册</a>
                    </span>
                    </div>
                    {/*<div className="language">*/}
                    {/*    <span className="cn">CN/EN</span>*/}
                    {/*    /!*<span className="en">EN</span>*!/*/}
                    {/*</div>*/}
                    <div className={"tel"}>
                        <img src={TelIocn} alt=""/>
                        <a href={"tel:0755-8867 4958"}>0755-8867 4958</a>
                    </div>
                </div>
                {
                    <div className={"dev-ext-container"}
                         style={{top: "400px"}}>
                        {/*<a href="/console/inbox/" target="_blank"*/}
                        {/*   className="ext-inbox-entrance"*/}
                        {/*   style={{display:"none"}}><span*/}
                        {/*    className="ext-inbox-unread-count"></span></a>*/}
                        <div className="ext-top" onClick={this.scrollTopZero} style={{display: this.state.scrollTop > 200 ? "block" : "none"}}></div>
                        {/* <div className="ext-feedback">*/}
                        {/*     <div>问题反馈</div>*/}
                        {/*</div>*/}
                        <div className="ext-contact">
                            <div className="ext-contact-text">联系我们</div>
                        </div>
                        <div className="ext-qrcode">
                            <div className="img-container">
                                <img src={QrImg} alt=""/>
                                <div>官方微信扫描关注</div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

const mapThemeStateToProps = (state: any) => ({
    themeColor: state.themeStore.themeColor,
    width: state.themeStore.width
});
const mapThemeDispatchToProps = (dispatch: Function) => ({
    onThemeChange: (width: any) => dispatch(actions.onThemeChange(width))
});
export default connect(mapThemeStateToProps, mapThemeDispatchToProps)(headerCus);

// export default  headerCus
