import React from 'react';
import { Alert, Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
// @ts-ignore
import ReactJsonBeautify from 'react-json-beautify';
import 'react-json-beautify/react-json-beautify.css';
// import ReactDOM from 'react-dom';
import './productService.scss'
// import HeaderCus from "../../component/header/header";
// import FooterCus from "../../component/footer/footer";
import AccessGuide from "../../component/AccessGuide/accessGuide";
import Swiper from "../../component/swiper/swiper";
import * as clipboard from "clipboard-polyfill"

import HomeBanner from '../../static/solution/Home_Banner2@2x.png';
import Api from '../../static/productService/API-Icon@2x.png';
import Sdk from '../../static/productService/SDK-Icon@2x.png';
// import BigGirl from '../../static/productService/big_girl.png';

import sousuo from '../../static/productService/sousuo@2x.png';
import face_1 from '../../static/productService/face/1.jpg';
import face_2 from '../../static/productService/face/2.jpg';
import face_3 from '../../static/productService/face/3.jpg';
import face_4 from '../../static/productService/face/4.jpg';
import face_5 from '../../static/productService/face/5.jpg';
import face_6 from '../../static/productService/face/6.jpg';

import loading from '../../static/productService/loading.png'
import { getFaceRequest } from '../../api/request';
// import {log} from "util";
// import {url} from "inspector";

interface State {
    examplesHistory: any,
    // history: any,
    tabResReqIndex: number,
    isDisease: number,
    isloading: boolean,
    bigImgSrc: string,
    updateSrc: string,
    imgType: string,
    algorithmResults: any,
    resdata: any,
    reqdata: any,
    hoverIndex: any
}

const examples = [
    // { imgSrc: "https://obs.mtlab.meitu.com/public/itc2_gumd4GoGYxjW9Ncq5rzVlVzmnnT/demo/skin/1.jpg" },
    // { imgSrc: "https://obs.mtlab.meitu.com/public/itc2_gumd4GoGYxjW9Ncq5rzVlVzmnnT/demo/skin/2.jpg" },
    // { imgSrc: "http://www.ps-xxw.cn/uploadfile/201704/26/23163432649.jpg" },
    // { imgSrc: "http://newiotdjm.imoreme.com/static/media/5.ccaddd12.jpg" },
    { imgSrc: face_1 },
    { imgSrc: face_2 },
    { imgSrc: face_3 },
    { imgSrc: face_4 },
    { imgSrc: face_5 },
    { imgSrc: face_6 },
]

// const disease = ["黑头", "痘痘", "黑眼圈", "肤质", "额头细纹", "法令纹",];

export default class ProductService extends React.Component<{}, State> {
    private imgFile: any;

    constructor(Props: any) {
        super(Props);
        this.state = {
            examplesHistory: {
                key: "examples",
                examples: examples,
                history: [],
            },
            tabResReqIndex: 0,
            isDisease: 0,
            imgType: 'url',
            isloading: false,
            bigImgSrc: examples[0].imgSrc,
            updateSrc: '',
            algorithmResults: [],
            resdata: { data: [] },
            reqdata: {},
            hoverIndex: null
        }
    }

    componentDidMount() {

    }

    componentWillMount() {

    }

    componentDidUpdate(prevProps: any, prevState: any) {

    }

    _loadImg(target: any) {
        // @ts-ignore
        // console.log([].indexOf.call(target.currentTarget.parentNode.querySelectorAll(target.currentTarget.tagName),target.currentTarget),this.state.updateSrc)
        if (!this.state.updateSrc) {
            this._ImgUpdate(target);
        }
    }

    _examplesHistory(key: string) {
        this.setState({
            examplesHistory: {
                key: key,
            }
        })
    }

    _ImgUpdate(target: any) {
        let _update = (imgSrc: any, imgType: any) => {
            let verifyImgSrc = imgSrc;
            switch (imgType) {
                case "url":
                    verifyImgSrc = imgSrc;
                    break;
                case "jpg":
                    verifyImgSrc = imgSrc.slice(imgSrc.indexOf("/") + 1, imgSrc.indexOf(";base64"));
                    // .slice(imgSrc.result.indexOf(",") + 1)
                    break
            }

            // console.log(verifyImgSrc);
            if (!/(gif|jpg|jpeg|png|GIF|JPG|PNG|image)$/.test(verifyImgSrc)) {
                return Alert.error('图片链接不对', 3000);
            } else {
                this.setState({
                    isloading: true,
                    imgType: imgType,
                    updateSrc: imgSrc
                }, this._loading);
            }
        }
        if (target.currentTarget.nodeName === "IMG") {
            _update(target.currentTarget.src, (target.currentTarget.src.indexOf("base64") === -1 ? "url" : "jpg"));
        } else if (target.currentTarget.nodeName === "DIV") {
            _update((target.currentTarget.previousElementSibling || target.currentTarget.previousSibling).value, "url");
        } else if (target.currentTarget.nodeName === "INPUT") {
            var reader = new FileReader(), file = target.currentTarget.files[0];
            reader.readAsDataURL(file);
            reader.onloadend = function (evt: any) {
                _update(evt.target.result, "jpg")
            }
        }
    }

    _DiseaseUpdate(index: number) {
        if (this.state.algorithmResults[index].img_path) {
            this.setState({
                isloading: true,
                isDisease: index
            }, this._loading);
        } else {
            Alert.error('未检测到此问题', 3000)
        }
    }

    _loading() {
        const result = this.state.examplesHistory.history.filter((item: any) => item.imgSrc.includes(this.state.updateSrc));
        if (result.length) {
            setTimeout(() => {
                this.setState({
                    reqdata: {
                        "img_type": this.state.imgType,
                        "img_data": this.state.updateSrc,
                        // "detect_type": this.state.isDisease.toString(),
                        "detect_type": "0",
                        "language": "cn"
                    },
                    algorithmResults: result[0].algorithmResults,
                    resdata: result[0].obj,
                    bigImgSrc: result[0].algorithmResults[this.state.isDisease].img_path,
                    isloading: false
                })
            }, 1000)
        } else {
            getFaceRequest(this.state.imgType, this.state.updateSrc.slice(this.state.updateSrc.indexOf(",") + 1), "0")
                .then((res: any) => {
                    if (res.code === 200) {
                        res = JSON.parse(JSON.stringify(res).replace(/null/g, "\"\""));
                        console.log(res);
                        // console.log(Object.keys(res).map((item, index) => {
                        //     if (res[item] instanceof Object) {
                        //         res[item].name = item;
                        //         return res[item]
                        //     }
                        // }));
                        //     let coordinate = obj.coordinate;
                        let classification = (type: string) => {
                            switch (type) {
                                case "artery":
                                    return "血管型";
                                case "pigment":
                                    return "色素型";
                                case "oily":
                                    return "油性";
                                case "neutral":
                                    return "中性";
                                case "dry":
                                    return "干性";
                                default:
                                    return "无"
                            }
                        }
                        let position = (type: string, isFace = false) => {
                            if ((type === "left" || type === "right") && isFace) {
                                type += "Face";
                            }
                            switch (type) {
                                case "left":
                                    return "左眼";
                                case "right":
                                    return "右眼";
                                case "forehead":
                                    return "额头";
                                case "nose":
                                    return "鼻子";
                                case "leftFace":
                                    return "左侧脸";
                                case "rightFace":
                                    return "右侧脸";
                                case "chin":
                                    return "下巴";
                                default:
                                    return "无"
                            }
                        }
                        let level = (type: string) => {
                            switch (type) {
                                case "none":
                                    return "无";
                                case "light":
                                    return "轻度";
                                case "medium":
                                    return "中度";
                                default:
                                    return "重度"
                            }
                        }
                        let algorithmResults = [
                            { name: '黑头', enName: 'black_head', ...res.black_head },
                            { name: '痘痘', enName: 'dou_dou', ...res.dou_dou },
                            {
                                name: '黑眼圈', enName: 'dark_circle', ...res.dark_circle, textArr: [
                                    { name: '黑眼圈_左侧', text: level(res.dark_circle.coordinate.rectangularAreas[0].level) },
                                    { name: '黑眼圈_右侧', text: level(res.dark_circle.coordinate.rectangularAreas[1].level) },
                                    {
                                        name: '黑眼圈_左侧类型',
                                        text: classification(res.dark_circle.coordinate.rectangularAreas[0].classification)
                                    },
                                    {
                                        name: '黑眼圈_右侧类型',
                                        text: classification(res.dark_circle.coordinate.rectangularAreas[1].classification)
                                    },
                                ]
                            },
                            {
                                name: '肤质', enName: 'skin_type', ...res.skin_type, textArr: [
                                    {
                                        name: '肤质_' + position(res.skin_type.coordinate.rectangularAreas[0].position, true),
                                        text: classification(res.skin_type.coordinate.rectangularAreas[0].classification)
                                    },
                                    {
                                        name: '肤质_' + position(res.skin_type.coordinate.rectangularAreas[1].position, true),
                                        text: classification(res.skin_type.coordinate.rectangularAreas[1].classification)
                                    },
                                    {
                                        name: '肤质_' + position(res.skin_type.coordinate.rectangularAreas[2].position, true),
                                        text: classification(res.skin_type.coordinate.rectangularAreas[2].classification)
                                    },
                                    {
                                        name: '肤质_' + position(res.skin_type.coordinate.rectangularAreas[3].position, true),
                                        text: classification(res.skin_type.coordinate.rectangularAreas[3].classification)
                                    },
                                    {
                                        name: '肤质_' + position(res.skin_type.coordinate.rectangularAreas[4].position, true),
                                        text: classification(res.skin_type.coordinate.rectangularAreas[4].classification)
                                    },
                                ]
                            },
                            {
                                name: '额头细纹', enName: 'wrinkle_thin', ...res.wrinkle_thin, textArr: [
                                    { name: '额头细纹', text: level(res.wrinkle_thin.coordinate.commonLevel) },
                                ]
                            },
                            // {
                            //     name: '法令纹', enName: 'wrinkle_seg', ...res.wrinkle_seg, textArr: [
                            //         {
                            //             name: '法令纹_' + position(res.wrinkle_seg.coordinate.rectangularAreas[0].position, true),
                            //             text: level(res.wrinkle_seg.coordinate.rectangularAreas[0].level)
                            //         },
                            //         {
                            //             name: '法令纹_' + position(res.wrinkle_seg.coordinate.rectangularAreas[1].position, true),
                            //             text: level(res.wrinkle_seg.coordinate.rectangularAreas[1].level)
                            //         },
                            //     ]
                            // },
                        ];
                        // console.log(algorithmResults);
                        this.setState({
                            examplesHistory: {
                                ...this.state.examplesHistory,
                                history: [...this.state.examplesHistory.history,
                                {
                                    imgSrc: this.state.updateSrc,
                                    algorithmResults,
                                    obj: res
                                }],
                            },
                            algorithmResults,
                            reqdata: {
                                "img_type": this.state.imgType,
                                "img_data": this.state.updateSrc,
                                "detect_type": this.state.isDisease.toString(),
                                "language": "cn"
                            },
                            resdata: res,
                            bigImgSrc: algorithmResults[0].img_path,
                            isloading: false
                        })
                    } else {
                        this.setState({
                            reqdata: {
                                "img_type": this.state.imgType,
                                "img_data": this.state.updateSrc,
                                "detect_type": this.state.isDisease.toString(),
                                "language": "cn"
                            },
                            isloading: false
                        })
                        Alert.error(res.message, 3000)
                    }
                }).catch((error) => {
                    // console.log(error);
                    this.setState({
                        isloading: false
                    })
                    Alert.error('请求错误', 3000)
                });
        }
    }

    _tabResReq(index: number) {
        this.setState({
            tabResReqIndex: index
        })
    }

    _tabExamplesHistory(type: string) {
        // console.log({...this.state.examplesHistory,key: type});
        this.setState({
            examplesHistory: {
                ...this.state.examplesHistory,
                key: type
            }
        })
    }
    handleMouseOver(index: number) {
        this.setState({ hoverIndex: index })
    }
    handleMouseOut() {
        this.setState({ hoverIndex: null })
    }

    // 改变图片问题
    // _updateDisease(item:any){
    //     console.log(item)
    //     if(item.coordinate.rectangularAreas && item.coordinate.rectangularAreas.length > 0){

    //     } else {
    //         Alert.error('未检测到此问题', 3000)
    //     }
    // }
    render() {
        console.log(this.state.algorithmResults)
        return (
            this.props.children ||
            <div id="ProductService">
                {/*<HeaderCus active={2}/>*/}
                <Swiper domClass={"swiper-container"} domList={
                    [HomeBanner, HomeBanner, HomeBanner, HomeBanner, HomeBanner].map((imgSrc, index) => {
                        return (
                            <div className="swiper-slide" key={index}>
                                <div className={"ad"}>
                                    <strong>皮肤分析</strong>
                                    <span>通过面部照片检测多种皮肤问题，分析维度包括肤质、痘痘、皱纹、黑眼圈、黑头等。</span>
                                    <div className={"button_box"}>
                                        <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer"
                                            target="_blank">
                                            <button className={"applyIntervention"}>申请接入</button>
                                        </a>
                                        <a href="/" rel="noopener noreferrer" target="_blank">
                                            <button className={"technicalDocumentation"}>技术文档</button>
                                        </a>
                                    </div>
                                </div>
                                <img src={imgSrc} alt="" />
                            </div>
                        )
                    })
                } option={{
                    loop: true,
                }} />
                <div className="ai-experience">
                    <div className="top-title">多样接入方式 灵活适配场景</div>
                    <div className="ai-centent">
                        <div className="ai-left">
                            <div className={"ai-left-top"}>
                                <div className={"ai-left-top-left"}>
                                    {/* <div className="top_bg_img" style={{ backgroundImage: `url(${this.state.updateSrc})` }}>
                                        {
                                            this.state.algorithmResults.map((item: any, index: number) => {
                                                return (
                                                    <div className={item.enName} key={index}>
                                                        {
                                                            item.coordinate.rectangularAreas && item.coordinate.rectangularAreas.length > 0 ? item.coordinate.rectangularAreas.map((j: any, index: number) => {
                                                                return (
                                                                <span key={index} style={{left:j.rectangularPoint.x1,top:j.rectangularPoint.y1,width:j.rectangularPoint.x2 - j.rectangularPoint.x1,height:j.rectangularPoint.y2 - j.rectangularPoint.y1}}></span>
                                                                )
                                                            }) : ''
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> */}
                                    <img className={"img"} src={this.state.bigImgSrc} alt=""
                                        onLoad={() => this.setState({ isloading: false })} />
                                    <div className={"loading-box"}
                                        style={{ display: (this.state.isloading ? 'block' : 'none') }}>
                                        <img className={"loading"} src={loading} alt="" />
                                    </div>
                                    <div className={"disease_box"}>
                                        {
                                            this.state.algorithmResults.map(
                                                (item: any, index: number) =>
                                                    <div key={index}
                                                        // className={"disease" + (index === this.state.isDisease ? " active" : "")}
                                                        className={"disease" + (item.coordinate.rectangularAreas && item.coordinate.rectangularAreas.length > 0 ? " active" : "")}
                                                        onClick={() => this._DiseaseUpdate(index)}
                                                        // onClick={this._updateDisease.bind(this,item)}
                                                        >
                                                        <span className={"checkbox_cus"} />{item.name}</div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"ai-left-top-right"}>
                                    <img className={"img"} src={this.state.bigImgSrc} alt="" />
                                </div>
                            </div>
                            <div className={"ai-left-bottom"}>
                                <div className={"img-input"}>
                                    <strong className={"input-title"}>请上传测试图片，或从样例图片中选择一张开始测试</strong>
                                    <div className={"input-box"}>
                                        <div className={"input"}>
                                            <input type="text" placeholder={"请粘贴图片URL"} />
                                            <div className={"search"} onClick={(div) => this._ImgUpdate(div)}>
                                                <img src={sousuo} alt="" />
                                            </div>
                                        </div>
                                        <input type={"file"} ref={(input) => {
                                            this.imgFile = input
                                        }} style={{ display: "none" }}
                                            onChange={(input) => this._ImgUpdate(input)} />
                                        <button className={"button"} onClick={() => this.imgFile.click()}>上传</button>
                                    </div>
                                </div>
                                <div className={"img-box " + this.state.examplesHistory.key}>
                                    {
                                        this.state.examplesHistory[this.state.examplesHistory.key].map((item: any, index: number) => {
                                            let active = this.state.updateSrc.includes(item.imgSrc) ? 'active' : '';
                                            return (<img onLoad={(img) => this._loadImg(img)} className={active}
                                                src={item.imgSrc}
                                                key={index} alt="" onClick={(img) => this._ImgUpdate(img)} />)
                                        })
                                    }
                                </div>
                                <div className={"examples-history"}>
                                    <span
                                        className={"examples " + (this.state.examplesHistory.key === "examples" ? 'active' : '')}
                                        onClick={() => this._tabExamplesHistory("examples")}>样例</span>
                                    <span
                                        className={"history " + (this.state.examplesHistory.key === "history" ? 'active' : '')}
                                        onClick={() => this._tabExamplesHistory("history")}>历史</span>
                                </div>
                                <div className={"placeholder"}></div>
                            </div>
                        </div>
                        <div className="ai-right">
                            <div className={"ai-right-tab"}>
                                {["算法结果", "Response", "Request"].map((item, index) => {
                                    return (<span
                                        className={"ai-right-tab-button " + (this.state.tabResReqIndex === index ? "active" : "")}
                                        key={index} onClick={() => this._tabResReq(index)}>{item}</span>)
                                })}
                            </div>
                            <div className={"ai-right-centent"} ref={"copy"}>
                                {
                                    !this.state.tabResReqIndex
                                        ? this.state.algorithmResults.map((item: any, index: number) => {
                                            if (item.textArr) {
                                                return item.textArr.map((item: any, index: number) => {
                                                    return (
                                                        <div className={'li'} key={index}>
                                                            <span
                                                                className={"ai-right-centent-li-name"}><i />{item.name}：</span>
                                                            <span
                                                                className={"ai-right-centent-li-value"}>{item.text}</span>
                                                        </div>
                                                    )
                                                })
                                            } else {
                                                return (
                                                    <div className={'li'} key={index}>
                                                        <span className={"ai-right-centent-li-name"}><i />{item.name}：</span>
                                                        <span
                                                            className={"ai-right-centent-li-value"}>{item.num || "无"}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                        : <ReactJsonBeautify
                                            data={(this.state.tabResReqIndex === 1 ? this.state.resdata : this.state.reqdata)}
                                        // deep={options.deep}
                                        // showDoubleQuotes={options.showDoubleQuotes}
                                        // showLength={options.showLength}
                                        // showLine={options.showLine}
                                        // highlightMouseoverNode={options.highlightMouseoverNode}
                                        // collapsedOnClickBrackets={options.collapsedOnClickBrackets}
                                        // onClick={handleClick}
                                        />
                                }
                            </div>
                            <Button className={"ai-right-copy"} color="orange" appearance="ghost" onClick={() => {
                                // @ts-ignore
                                clipboard.writeText(this.refs.copy.innerText).then(() => Alert.success('复制成功。', 13000))
                            }}>一键复制
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="access-service">
                    <div className="top-title">接入服务</div>
                    <div className="bottom-centent">
                        {[
                            {
                                name: "API接入",
                                centent: "通过Web API调用，快速灵活接入DJM  AIOT开放平台的算法服务。",
                                imgSrc: Api,
                            }, {
                                name: "SDK接入",
                                centent: "通过SDK调用，在移动设备、PC或其它设备上调用DJM  AI能力。",
                                imgSrc: Sdk,
                            }
                        ].map((item, index) => {
                            return (
                                <div className="bottom-item" key={index} onMouseOver={this.handleMouseOver.bind(this, index)} onMouseLeave={this.handleMouseOut.bind(this)}>
                                    <div className={"name-icon"}>
                                        <img className={this.state.hoverIndex === index ? "hover-img" : "img"} src={item.imgSrc} alt="" />
                                        <strong className={this.state.hoverIndex === index ? "name-right" : "name"}>{item.name}</strong>
                                    </div>
                                    <div className={"centent"}>{item.centent}</div>
                                    <div className={"buttons"}>
                                        <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer"
                                            target="_blank">
                                            <button>申请接入</button>
                                        </a>
                                        <a href="/" rel="noopener noreferrer" target="_blank">
                                            <button>技术文档</button>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
                <div className="access-process">
                    <div className="top-title">接入流程</div>
                    <AccessGuide />
                </div>
                {/*<FooterCus name="FooterCus"/>*/}
            </div>
        );
    }
}

// ReactDOM.render( <ProductService/> , document.getElementById('root'));
