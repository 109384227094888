import React from 'react';
import './footer.scss';
import qrcodeKefu from '../../static/home/qrcode-kefu.png';
import qrcodeGzh from '../../static/home/qrcode-gzh.png';
import "animate.css";
import WOW from 'wowjs';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

interface Props {
    name: string;
}

class footerCus extends React.Component<Props, {}> {
    componentDidMount() {
        const wow = new WOW.WOW({live:false,mobile:true});
        wow.init();
    }
    render() {
        return (
            <ReactCSSTransitionGroup
                transitionLeave={true}
                transitionEnterTimeout={2500}
                transitionLeaveTimeout={1500}
                transitionName="animate__animated"
            >
            <div id="footer" key="amache">
                <div className="centent">
                    <div className="wow slideInRigh animate__animated animate__fadeInLeft left" data-wow-offset="200">
                        <div className="resources">
                            <strong>资源</strong>
                            <a href={"http://newiotdjm.imoreme.com/openPlatform"} rel="noopener noreferrer"
                               target="_blank">接入文档</a>
                            <span>常见问题</span>
                        </div>
                        <div className="resources">
                            <strong>关于</strong>
                            <a href={'http://newiotdjm.imoreme.com/aboutUs'} rel="noopener noreferrer" target="_blank">关于我们</a>
                            <span>隐私政策</span>
                            <span>用户协议</span>
                        </div>
                        <div className="resources">
                            <strong>友情链接</strong>
                            <a href={"http://mirror.idankee.com"} rel="noopener noreferrer"
                               target="_blank">智能镜配件运营中心</a>
                            <a href={"http://djm.hoyar.com.cn"} rel="noopener noreferrer" target="_blank">物联网数据中心</a>
                            <a href={"http://metisadmin.hoyar.com.cn"} rel="noopener noreferrer"
                               target="_blank">皮肤数据中心</a>
                        </div>
                        <div className="resources">
                            <strong>联系我们</strong>
                            <a href={"tel:0755-8867 4958"}>客服电话：0755-8867 4958</a>
                            <a href={"tel:18028003239"}>电话（微信）：18028003239</a>
                            <span>公司地址：深圳南山区北理工创新大厦501</span>
                        </div>
                    </div>
                    <div className="wow slideInRigh animate__animated animate__fadeInRight right" data-wow-offset="200">
                        <div className="idankee-img">
                            <img src={qrcodeKefu} alt=""/>
                            <span>DJM官方客服</span>
                        </div>
                        <div className="public-img">
                            <img src={qrcodeGzh} alt=""/>
                            <span>公众号</span>
                        </div>
                    </div>
                </div>
                <div className="wow slideInRigh animate__animated animate__fadeInUp right-reserved" data-wow-offset="10">
                    Copyright © 德国DJM中国 All Right Reserved | 粤ICP备15000685号
                </div>
            </div>
            </ReactCSSTransitionGroup>
        );
    }
}

export default footerCus
