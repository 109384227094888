import {combineReducers} from 'redux'
import theme from './theme'


/**
 * 3.合并reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const index = combineReducers({
    themeStore: theme,
});

export default index;
