import React from 'react';
// import ReactDOM from 'react-dom';
import './openPlatform.scss'
import AccessGuide from "../../component/AccessGuide/accessGuide";
import AccessButton from '../../component/widget/AccessButton'

import data from './data'

import HomeBanner from '../../static/home/Home_Banner@2x.png';
// import ArrowDown from '../../static/openPlatform/down-icon@2x.png';
// import ArrowUp from '../../static/openPlatform/up-icon@2x.png';

import Swiper from "../../component/swiper/swiper";
// import FaceRecognition from "../../static/home/Face-recognition-icon@2x.png";
import { Menu } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem';
import 'antd/dist/antd.css'
const { SubMenu } = Menu;

// const SubMenu = Menu.SubMenu;
export default class OpenPlatform extends React.Component<any, any> {
    constructor(Props: any) {
        super(Props)
        const menuList = this.renderMenu(data.leftNav);
        this.state = {
            leftNav: data.leftNav,
            leftActive: data.leftActive,
            textContent: data.leftNav[0].text,
            needFixed: false,
            collapsed: false,
            menuList
        }

    }

    componentDidMount() {
        // console.log(data)
        // this.leftFixed()
    }





    /**
     * @Description: 是否显示
     * @parpm item 显示的内容
     * @parpm index 下标
     * @parpm nav 隶属层级
    */
    // isShow({ item, index, nav }: { item: { text: string, navOne: any }, index: number, nav: number }) {
    //     switch (nav) {
    //         case 1:
    //             this.setState({
    //                 leftActive: {
    //                     one: index,
    //                 },
    //             })
    //             if (item.text) {
    //                 this.setState({
    //                     textContent: item.text
    //                 })
    //             } else if (item.navOne) {
    //                 this.setState({
    //                     leftActive: {
    //                         one: index,
    //                         two: index + '-' + 0,
    //                     },
    //                     textContent: item.navOne[0].text
    //                 })
    //             }
    //             break
    //         case 2:
    //             if (item.text) {
    //                 this.setState({
    //                     leftActive: {
    //                         ...this.state.leftActive,
    //                         two: this.state.leftActive.one + "-" + index,
    //                     },
    //                     textContent: item.text
    //                 })
    //             }
    //             break
    //     }
    // }

    //使用递归
    renderMenu(data: any) {
        return data.map((item: any) => {
            if (item.navOne) {
                return (
                    <SubMenu key={item.navName} title={item.navName}>
                        {this.renderMenu(item.navOne)}
                    </SubMenu>
                )
            } else {
                return (
                    <MenuItem key={item.navName} title={item.navName}>
                        <a  onClick={this.getTextContent.bind(this, item)}>
                            {item.navName}
                        </a>
                    </MenuItem>
                )
            }
        })
    }

    getTextContent(item: any) {
        // this.setState({ textContent: item.text })
        document.documentElement.scrollTop = 0;
        if (item.text) {
            this.setState({
                textContent: item.text
            })
        } else if (item.navOne) {
            this.setState({
                textContent: item.navOne[0].text
            })
        }
    }

    
    /**
     * @Description: 左侧列表
     */
    getNavLeft() {
        return (
            <Menu
                defaultSelectedKeys={['接入指南']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                theme="dark"
                inlineCollapsed={this.state.collapsed}
                style={{ background: '#111321',width:'2.72rem' }}
            >
                {/* {
                    this.state.leftNav.map((item: any, index: any) => {
                        if (item.navOne) {
                            return <SubMenu key={index} title={
                                <span>
                                    <span>{item.navName}</span>
                                </span>
                            }>
                                {
                                    item.navOne.map((o: any, i: any) => {
                                            return <SubMenu key={i} title={o.navName} onTitleClick={this.getTextContent.bind(this,o)}>
                                                {
                                                    o.navTwo.map((j: any) => {
                                                        return <MenuItem key={j.navName} title={j.navName} >
                                                            <a href={j.href} onClick={this.getTextContent.bind(this,o)}>{j.navName}</a>
                                                        </MenuItem>
                                                    })
                                                }
                                            </SubMenu>
                                    })
                                }
                            </SubMenu>
                        } else {
                            return (
                                <MenuItem key={item.navName} title={item.navName}>
                                    <a href={item.href} onClick={this.getTextContent.bind(this, item)}>
                                        {item.navName}
                                    </a>
                                </MenuItem>
                            )
                        }

                    })
                } */}
                {this.state.menuList}
            </Menu>
        )
        // return this.state.leftNav.map((item: any, index: any) => {
        //     return (
        //         <div className={"tab-item "} key={index}>
        //             <div className={"tab-item-name " + (index === this.state.leftActive.one ? "active" : "")}
        //                 onClick={() => this.isShow({ item: item, index: index, nav: 1 })}>
        //                 {item.navName}
        //                 {item.navOne ?
        //                     <img src={ArrowDown} alt="" />
        //                     : null
        //                 }
        //             </div>
        //             <div className={"tab-item-centent"}
        //                 style={{ marginLeft: "0.25rem", display: index === this.state.leftActive.one ? "block" : "none" }}>
        //                 {
        //                     item.navOne && item.navOne.map((navOneItem: any, indexOne: number) => {
        //                         return (<div className={"tab-item-name " + (index + '-' + indexOne === this.state.leftActive.two ? "active" : "")}
        //                             onClick={() => this.isShow({ item: navOneItem, index: indexOne, nav: 2 })} key={indexOne}>
        //                             <a href={navOneItem.href}>
        //                                 {navOneItem.navName}
        //                                 {navOneItem.navTwo ?
        //                                     <img src={ArrowDown} alt="" />
        //                                     : null
        //                                 }
        //                             </a>
        //                             <div className={"tab-item-centent"} style={{
        //                                 marginLeft: "0.3rem",
        //                                 display: (index + '-' + indexOne === this.state.leftActive.two ? "block" : "none")
        //                             }}>
        //                                 {
        //                                     navOneItem.navTwo && navOneItem.navTwo.map((navTwoItem: any, indexTwo: number) => {
        //                                         return (<div className={"tab-item-name"} key={indexTwo}>
        //                                             <a href={navTwoItem.href}>
        //                                                 {navTwoItem.navName}
        //                                             </a>
        //                                         </div>)
        //                                     })
        //                                 }
        //                             </div>
        //                         </div>)
        //                     })
        //                 }
        //             </div>
        //         </div>)
        // })
    }
    /**
     * @Description: 右侧内容
     */
    geitRightCentent() {
        let { textContent } = this.state;
        return (
            <div className={"right-item"}>
                <div className="title top-title" id={textContent.addressHref}>{textContent.title}</div>
                <div className="test-address" id={textContent.id}>
                    {
                        textContent.test.map((item: any, index: number) => {
                            return (
                                <div key={index}>{item}</div>
                            )
                        })
                    }
                </div>
                <div className="title" id={textContent.requestHref}>{textContent.requestTitle}</div>
                {this.getTable(textContent.thContent, textContent.trContent)}
                <div className="title" id={textContent.returnHref}>{textContent.returnTitle}</div>
                {this.getTable(textContent.returnThContent, textContent.returnContent)}


                {
                    textContent.returnContents ? textContent.returnContents.map((titleitem: any, titleindex: number) => {
                        return (
                            <>
                                <div className="title" key={titleindex}>{titleitem.title}</div>
                                {this.getTable(titleitem.content[0].tdContent, titleitem.content)}
                            </>
                        )
                    }) : null
                }

                <div className="title" id={textContent.exampleHref}>{textContent.exampleTitle}</div>
                <pre>{textContent.pre}</pre>
                <div className="title" id={textContent.otherHref}>{textContent.otherTitle}</div>
                <div className="test-address" id={textContent.testHref}>
                    <div>{textContent.otherMore}</div>
                </div>

            </div>
        )
    }
    /**
     * @Description: 生产table
     */
    getTable(thArr: Array<any>, trArr: Array<any>) {
        return (
            <table className="test-table">
                {/*{  <thead>
                <tr>
                    {
                        thArr?thArr.map((item: any, index: number) => {
                            return (
                                <th key={index}>{item}</th>
                            )
                        }):null
                    }
                </tr>
                </thead>}*/}
                <tbody>
                    {
                        trArr ? trArr.map((item: any, index: number) => {
                            return (
                                <tr key={index}>
                                    {
                                        item.tdContent.map((tdItem: any, tdIndex: number) => {
                                            return (
                                                <td key={tdIndex}>
                                                    <pre>{tdItem}</pre>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        }) : null
                    }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <div id="openPlatform">
                {/*<HeaderCus active={4}/>*/}
                <Swiper domClass={"banner-container"} domList={
                    [HomeBanner, HomeBanner, HomeBanner, HomeBanner, HomeBanner].map((imgSrc, index) => {
                        return (
                            <div className="swiper-slide" key={index}>
                                <div className={"ad"}>
                                    <strong>AI开放平台服务支持</strong>
                                    <span>欢迎使用DJM  AI开放平台，丰富接入服务助力产品升级</span>
                                    <AccessButton />
                                </div>
                                <img src={imgSrc} alt="" />
                            </div>
                        )
                    })
                } option={{
                    loop: true,
                }} />
                <div className="openPlatform-centent">
                    <div className='left' id='left-wrapper'>
                        {this.getNavLeft()}
                    </div>
                    {
                        this.state.textContent.title ?
                            this.geitRightCentent()
                            :
                            <div className="right">
                                <div className={"top"}>
                                    <div className="title">接入指南/如何接入DJM AI开放平台</div>
                                    <AccessGuide />
                                </div>
                                <div className={"bottom-centent"}>{
                                    this.state.textContent.centent.map((item: any, index: number) => {
                                        return (
                                            <div className={"bottom-item"} key={index}>
                                                <div className={"title"}>{item.name}</div>
                                                <div className={"centent"}>
                                                    <pre>{item.centent}</pre>
                                                </div>
                                                <div className='centent-img' >
                                                    <img src={item.src1} alt="" />
                                                    <img src={item.src2} alt="" />
                                                </div>

                                            </div>
                                        )
                                    })
                                }</div>
                            </div>
                    }
                </div>
                {/*<FooterCus name="FooterCus"/>*/}
            </div>
        );
    }


    /**
     * @description: 左侧列表滚动到移动高度时固定住
     */
    // leftFixed(){
    //     const fixedTop:any = document.getElementById('left-wrapper')
    //     const top:number = fixedTop.offsetTop
    //     window.onscroll = () =>{
    //         let scrollTop:number = Math.max(document.body.scrollTop,document.documentElement.scrollTop)
    //         if(scrollTop >= top){
    //             this.setState({needFixed:true})
    //         } else if(scrollTop <= top){
    //             this.setState({needFixed:false})
    //         }
    //     }

    // }
}

// ReactDOM.render(<OpenPlatform/>, document.getElementById('root'));
