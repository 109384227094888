import React from 'react';
// import ReactDOM from 'react-dom';
import './solution.scss'
// import HeaderCus from "../../component/header/header";
// import FooterCus from "../../component/footer/footer";
import AccessButton from '../../component/widget/AccessButton'


import HomeBanner from '../../static/solution/Home_Banner2@2x.png';
import SkinTestSDK from '../../static/solution/skin_test_SDK@2x.png';
import MedicalPathologySDK from '../../static/solution/Medical_pathology_SDK@2x.png';
import ARSDK from '../../static/solution/AR_SDK@2x.png';
import FaceFecognitionSDK from '../../static/solution/Face_recognition_SDK@2x.png';
import DetectionHair from '../../static/solution/Detection_hair@2x.png';
import InternetThingsSolutionsSDK from '../../static/solution/Internet_things_solutions_SDK@2x.png';
import Swiper from "../../component/swiper/swiper";
import "animate.css";
import WOW from 'wowjs';
// import ImmediateAdvisoryAccess from '../../static/solution/Immediate_advisory_access@2x.png';

// import FaceRecognition from "../../static/home/Face-recognition-icon@2x.png";
// import FaceRecognition from "../../static/home/Face-recognition-icon@2x.png";

export default class Solution extends React.Component {
    componentDidMount() {
        const wow = new WOW.WOW({live:false,mobile:true});
        wow.init();
    }
    render() {
        return (
            <div id="solution">
                {/*<HeaderCus active={3}/>*/}
                <Swiper domClass={"swiper-container"} domList={
                    [HomeBanner, HomeBanner, HomeBanner, HomeBanner, HomeBanner].map((imgSrc, index) => {
                        return (
                            <div className="swiper-slide" key={index}>
                                <div className={"ad"}>
                                    <strong>五官分析</strong>
                                    <span>识别人脸五官的属性，包括眉毛、眼睛、鼻子、嘴巴、颧骨、下巴、脸型、眼皮、胡子的类型。</span>
                                    <div className={"button_box"}>
                                        <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer" target="_blank">
                                            <button className={"applyIntervention"}>申请接入</button>
                                        </a>
                                        <a href="http://newiotdjm.imoreme.com/openPlatform" target="_blank" rel="noopener noreferrer">
                                            <button className={"technicalDocumentation"}>技术文档</button>
                                        </a>
                                    </div>
                                </div>
                                <img src={imgSrc} alt="" />
                            </div>
                        )
                    })
                } option={{
                    loop: true,
                    // pagination: {
                    //     el: '.swiper-pagination',
                    //     clickable :true,
                    // },
                }} />
                <div className={"technical-solution"}>
                    <div className={"wow slideInRigh animate__animated animate__fadeInDown title"}>一站式解决方案</div>
                    <div className={"wow slideInRigh animate__animated animate__fadeInUp centent"}>
                        基于自身核心的AI算法能力与软硬件结合能力，DJM提供第三方平台接入、手机测肤H5和物联网智慧管控等解决方案，同时也通过开放算法接口赋能开发者实现应用创新。
                        </div>
                </div>
                <div className="sdk-list">
                    {[
                        {
                            name: "手机测肤 H5",
                            describe: "测肤给消费者提供新颖购物场景，为电商平台、线上门店、微信公众号引流拓客",
                            centent: "消费者通过手机拍照检测皮肤多种维度问题，\n" +
                                "商家根据皮肤问题精准推荐产品并引流到线上商城或线下门店,\n" +
                                "实现拓客吸粉，\n" +
                                "提升营销效果，布局新零售",
                            apply: "适用于Android,ios,Windows和Web。",
                            imgSrc: SkinTestSDK
                        }, {
                            name: "第三方平台接入测肤",
                            describe: "为美业SaaS服务商、美业品牌、大健康产业从业者赋能AI测肤技术",
                            centent: "搭配专业仪器设备，如AI测肤仪，智能镜，水油pH检测仪，肌肤放大镜等，通过接口同步顾客档案和检测数据，为顾客带来准医疗级别的皮肤检测，提升专业度与品牌形象。",
                            imgSrc: MedicalPathologySDK
                        }, {
                            name: "皮肤病理诊断",
                            describe: "10年300万+客户案例积累，建立面部皮肤大数据库",
                            centent: "AI技术检测面部皮肤常见问题如银屑病、脂溢性皮炎、病毒性痤疮、丘疹等，并用大数据算法计算百分比，可提前预防并降低发展概率。",
                            imgSrc: DetectionHair
                        }, {
                            name: "美业AI测肤",
                            describe: "通过专业皮肤检测为门店带来业绩增长",
                            centent: "提供个性化护理建议，根据顾客皮肤状态自动匹配关联产品，记录每次测肤结果并有效比对，提升顾问专业度及效率，降低人力成本，培养顾客粘性和对品牌的忠诚度。",
                            imgSrc: FaceFecognitionSDK
                        }, {
                            name: "零售AR解决方案",
                            describe: "AR试妆、AR眼镜穿戴、AR珠宝试戴、AR服装试穿等",
                            centent: "用于妆容、发型模拟，立体展示商家彩妆，有限空间展示海量商品，协助顾问提高销售机会。提高店内坪效、人效销售转化，线上线下全渠道融合，助力商家全渠道运营。",
                            imgSrc: ARSDK
                        }, {
                            name: "DJM物联网",
                            describe: "仪器联网管控，达成数据同步，助力美业经营数字化",
                            centent: "实时记录仪器设备使用过程，包括轨迹定位、故障上报、操作参数、治疗次序与时长、操作师服务质量、耗材防伪、订单消耗等，并通过实时大数据看板实现数据可视化。",
                            apply: "适用于Android,ios,Windows和Web。",
                            imgSrc: InternetThingsSolutionsSDK
                        }
                    ].map((item, index) => {
                        return (
                            <div className={"sdk-item"} key={index} >
                                <div className={'top'}>
                                    <img className={'img'} src={item.imgSrc} alt="" />
                                    <div className={'text-centent'}>
                                        <strong>{item.name}</strong>
                                        <span>{item.describe}</span>
                                    </div>
                                </div>
                                <div className={"describe"}>
                                    {item.centent}
                                </div>
                                {/* <div className={"apply"}>
                                    {item.apply}
                                </div> */}
                                <AccessButton />
                                {/*<div className={"left"}>*/}
                                {/*    <strong className={"name"}>{item.name}</strong>*/}
                                {/*    <span className={"describe"}>{item.describe}</span>*/}
                                {/*    <p className={"centent"}>{item.centent}</p>*/}
                                {/*    <span className={"apply"}>{item.apply}</span>*/}
                                {/*    <AccessButton />*/}
                                {/*</div>*/}
                                {/*<div className={"right"}>*/}
                                {/*    <img src={item.imgSrc} alt=""/>*/}
                                {/*</div>*/}
                            </div>)
                    })}

                </div>
                <div className={"immediate-advisory-access"}>
                    <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer" target="_blank">
                        <div className="title">立即咨询接入</div>
                    </a>
                    <div className="describe">集成SDK到您的应用中，在移动设备、PC或其他设备上调用DJM   AI能力购买咨询</div>
                    <div className="contact">
                        <a href="http://iotdjm.imoreme.com/login.html" rel="noopener noreferrer" target="_blank">
                            <button>立即咨询</button>
                        </a>
                        <span className={"tel"}>400-88888888</span>
                    </div>
                    <div className={"mask"}></div>
                </div>
                {/*<FooterCus name="FooterCus"/>*/}
            </div>
        );
    }
}

// ReactDOM.render( <Solution/> , document.getElementById('root'));
