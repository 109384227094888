import React from 'react';
import "animate.css";
// import WOW from 'wowjs';

// import ReactDOM from 'react-dom';
import './aboutUs.scss';
// import HeaderCus from "../../component/header/header";
// import FooterCus from "../../component/footer/footer";
// import AboutUsBanner from '../../static/aboutUs/aboutUs_banner@2x.png';
import logo from '../../static/aboutUs/DJM_big_Logo@2x.png';
import skin from '../../static/aboutUs/skin_laboratory_icon@2x.png';

// import HomeEnter from "../../static/home/Home_Enter-icon@2x.png";

export default class AboutUs extends React.Component {
    componentWillMount(){
    }
    render() {
        return (
            <div id="aboutUs">
                {/*<HeaderCus active={5}/>*/}
                <div className={"centent_box"}>
                    {
                        [
                            {
                                titleName: "AIoT开放平台",
                                describe: "AIoT open platform",
                                cententText: "DJM  AI开放平台是DJM公司推出的AI服务平台，专注与人脸技术、人体技术、图像识别、图像处理、图像生成\n" +
                                    "                            等核心领域，为客户提供市场验证的专业AI算法服务和解决方案。",
                                contact: {},
                                img: logo
                            },{
                            titleName: "皮肤影像实验室",
                            describe: "Skin imaging laboratory",
                            cententText: "DJM  影像实验室（DJM,Skin imaging laboratory）是DJM公司致力于计算机视觉、机器学习、增强现实、云计\n" +
                                "算机等领域的算法研究、工程开发和产品化落地的团队。DJM  AIoT开放平台由DJM提供技术支持和保障。",
                            contact: {
                                tel: "0755-8867 4958",
                                mail: "1967180237@qq.com"
                            },
                            img: skin
                        }
                        ].map((item,index)=>{
                            return (
                                <div className="centent_box_child" key={index}>
                                    <div className={"animate__animated animate__fadeInDown logo_title"}>
                                        <img className={"logo"} src={item.img} alt=""/>
                                        <div className={"title"}>
                                            <span className={"name"}>{item.titleName}</span>
                                            <span className={"describe"}>{item.describe}</span>
                                        </div>
                                    </div>
                                    <p className={"animate__animated animate__flipInX centent_box_child_text"}>
                                        {item.cententText}
                                    </p>
                                    <div className={"animate__animated animate__fadeInLeft contact"}>
                                        <a href={`tel:${item.contact.tel}`} className={"tel"}>{item.contact.tel}</a>
                                        <a href={`mailto:${item.contact.mail}`} className={"mail"}>{item.contact.mail}</a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/*<FooterCus name="FooterCus"/>*/}
            </div>
        );
    }
}

// ReactDOM.render( <AboutUs/> , document.getElementById('root'));
