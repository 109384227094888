import {applyMiddleware, createStore} from 'redux'
import reducers from './reducer'
import thunk from 'redux-thunk'

/**
 * 自定义log中间件
 * https://cn.redux.js.org/docs/advanced/Middleware.html
 * @param store
 */
const logger = (store:any) => (next:any) => (action:any) => {
    if (typeof action === 'function') {
        // console.log('dispatching a function');
    } else {
        // console.log('dispatching ', action);
    }
    const result = next(action);
    // console.log('nextState ', store.getState());
    return result;
};

const middlewares = [
    logger,
    thunk,
];
let store = createStore(reducers, applyMiddleware(...middlewares));

export default store

/**
 * This is a reducer, a pure function with (state, action) => state signature.
 * It describes how an action transforms the state into the next state.
 *
 * The shape of the state is up to you: it can be a primitive, an array, an object,
 * or even an Immutable.js data structure. The only important part is that you should
 * not mutate the state object, but return a new object if the state changes.
 *
 * In this example, we use a `switch` statement and strings, but you can use a helper that
 * follows a different convention (such as function maps) if it makes sense for your
 * project.
 */


// Create a Redux store.tsx holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
// let store = createStore(counter,applyMiddleware(thunk))

// export default store

// You can use subscribe() to update the UI in response to state changes.
// Normally you'd use a view binding library (e.g. React Redux) rather than subscribe() directly.
// However it can also be handy to persist the current state in the localStorage.

// store.subscribe(() => console.log(store.getState()))
