import {axiosInstance} from "./config";

export const getFaceRequest = (
    imgType, url, detectType = "0", language = 'cn'
) => {
    let encodedData = window.btoa(`group=idankee&stamp=${new Date().getTime()}&faceTechnology=skinanlysis`);
    return axiosInstance.post('/vivo/detect/free', {
        "img_type": imgType,
        "img_data": url,
        "imgresult_type": detectType,
        "language": language
    }, {
        headers: {
            Authorization: encodedData + 1
        }
    })
    // .then(function (response) {
    //     console.log(response);
    // })
    // .catch(function (error) {
    //     console.log(error);
    // });
};
