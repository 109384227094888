import React from 'react';
import Swiper from 'swiper'
import './package/css/swiper.css'

interface Props extends React.Props<any> {
    domList: any,
    option: any,
    domClass: any
}
interface myState {
    imgArr: any,
    mySwiper: any
}

class SimpleSwiperWithParams extends React.Component<Props, myState> {
    constructor(Props: any) {
        super(Props);
        this.state = {imgArr: Props.imgArr,mySwiper:null};
        // console.log(Props)
    }
    // mySwiper = null;
    componentDidMount () {
        // this.setState({
        //     mySwiper:
        // })
        new Swiper('.'+this.props.domClass, this.props.option)
    }

    // componentWillUpdate(nextProps:any, nextState:any) {
        // this.mySwiper.update()
        // if(this.state.mySwiper){
            // console.log('Component WILL UPDATE!',nextProps,nextState);
            // this.state.mySwiper.update()
        // }
        // console.log(this.state.mySwiper.update())
    // }

    swiperButton(navigation:any) {
        // const isLoggedIn = props.isLoggedIn;
        if (navigation) {
            return(
                <>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </>
            )
        }
        return '';
    }
    render() {
        return (
            <div className={"swiper-container " +this.props.domClass}>
                <div className="swiper-wrapper">
                    {this.props.domList}
                </div>
                <div className="swiper-pagination"></div>
                {this.swiperButton(this.props.option.navigation)}
            </div>
        )
    }
}

export default SimpleSwiperWithParams;
