import Types from '../types'

/**
 * 主题变更
 * @param theme
 * @returns {{type: string, theme: *}}
 */
export function onThemeChange(theme:any) {
    return {type: Types.THEME_CHANGE, theme: theme}
}

/**
 * 初始化主题
 * @returns {Function}
 */
export function onThemeInit(data:any) {
    return (dispatch:any) => {
        dispatch(onThemeChange(data))
    }
}
