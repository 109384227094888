export default {
    leftNav: [
        {
            navName: "接入指南",
            show: !1,
            href: "#getMoney",
            text: {
                top: [
                    {
                        name: "申请接入",
                        centent: "填写联系方式并提交",
                        imgSrc: ""
                    }, {
                        name: "确认需求",
                        centent: "专员会与您电话联系确认服务需求",
                        imgSrc: ""
                    }, {
                        name: "获得权限",
                        centent: "签订服务合同，获得开发者权限",
                        imgSrc: ""
                    }, {
                        name: "接入服务",
                        centent: "创建应用，获取密码，接入服务",
                        imgSrc: ""
                    },
                ],
                centent: [
                    {
                        name: "01/ 注册账号",
                        centent: "点击DJM AIoT开放平台页面右上角的“登录/注册”按钮，填写注册信息，注册成功后即可登录开放平台。",
                        src1: require('../../static/openPlatform/step1_1.png'),
                        src2: require('../../static/openPlatform/step1_2.png')
                    }, {
                        name: "02/ 申请认证",
                        centent: "使用开放平台的服务需要申请企业认证，信息提交后请等待专员审核。如审核通过，平台会为您分发开发密钥，可在“个人中心”->“账号信息”下查看",
                        src1: require('../../static/openPlatform/step2_1.png'),
                        src2: require('../../static/openPlatform/step2_2.png')
                    }, {
                        name: "03/ 充值",
                        centent: "请根据您所需要的服务，在平台预先充值一定金额。目前支持支付宝和微信两种方式，均为即时到账。",
                        src1: require('../../static/openPlatform/step3_1.png'),
                        src2: require('../../static/openPlatform/step3_2.png')
                    }, {
                        name: "04/ 调用接口",
                        centent: "现在您可以根据接口文档接入服务，进行开发了。",
                        src1: require('../../static/openPlatform/step4_1.png'),
                        src2: ''
                    },
                ]
            }
        },
        {
            navName: "收费说明",
            show: !1,
            href: "#",
            text: {
                title: "收费说明",
                test: ["", "各功能模块收费详情"],
                thContent: ["收费模块", "收费金额", "收费说明"],
                testHref: "getMoney",
                trContent: [{
                    tdContent: ["设备注册", "5元/台", "设备按数量计费，前1000台免费，后面每增加一台5元/月。"]
                }, {
                    tdContent: ["设备续期", "5元/台", "设备过期后，续期5元/台/月。设备到期自动续费。若余额不足，设备将被停用。"]
                }, {
                    tdContent: ["计费模块", "20元/月", "计费模块包含后台管理模块中的IOT管理-统计信息，IOT管理-大屏展示，IOT管理-消息推送。\n按月收费，20元/月，到期后需手动续费。"]
                }, {
                    tdContent: ["AI生成图片api", "0.1元/张", "调用AI生成图片接口，按申请生成图片张数收费，1张0.1元，8张0.8元。"]
                }, {
                    tdContent: ["AI检测报告api", "1元/次", "调用AI检测报告API获取检测结果，每次1元"]
                }, {
                    tdContent: ["AI图像处理api", "0.1元/维度", "调用AI分析api获取检测结果，每个维度0.1元\n接口维度包括：肤质、痘痘、黑眼圈、黑头、抬头纹、法令纹"]
                }, {
                    tdContent: ["AI分析接口api", "0.1元/维度", "调用AI图像处理api获取图像处理结果，每个维度0.1元\n接口维度包括：人脸关键点、脸型年龄等基础数据、棕色图、红色图、肤色图、斑点图、紫外黑白图、黑头检测、毛孔检测、皱纹图"]
                }, {
                    tdContent: ["图片API调用", "0.000001元/次", "调用图片上传、图片下载、图片删除API一次一扣，调用完直接扣除。"]
                }, {
                    tdContent: ["图片存储收费", "0.00017元/GB", "图片上传后，存储空间按小时计费，计算前一小时的费用，计量单位GB。\n费用截取到分。低于1分钱的部分将不扣除费用。"]
                }, {
                    tdContent: ["图片下载收费", "0.5元/GB", "下载图片后，流量按小时计费，计算前一小时的下载总流量，计量单位GB。"]
                }]
            }
        },
        {
            navName: "访问域名",
            show: !1,
            text: {
                title: "访问域名",
                test: ["测试环境：", "dev_domain_test=http://test.devdjm.imoreme.com", "iot_domain_test=http://test.iotapidjm.imoreme.com", "ai_domain_test=http://test.aiapidjm.imoreme.com", "image_domain_test=http://test.imagedjm.imoreme.com", "topicport_test=1883",

                    "正式地址：", "dev_domain=http://devdjm.imoreme.com", "iot_domain=http://iotapidjm.imoreme.com", "ai_domain=http://aiapidjm.imoreme.com", "image_domain=http://imagedjm.imoreme.com", "topicport=1883"]
            }
        },
        {
            navName: "功能码列表",
            show: !1,
            href: "#",
            text: {
                title: "功能码列表",
                test: ["", "各接口返回的结果code码"],
                thContent: ["功能码", "功能码说明"],
                testHref: "codeList",
                trContent: [{
                    tdContent: ["功能码", "功能码说明"]
                }, {
                    tdContent: ["200", "请求成功"]
                }, {
                    tdContent: ["404", "请求路径不存在"]
                }, {
                    tdContent: ["500", "请求失败"]
                }, {
                    tdContent: ["501", "参数不全"]
                }, {
                    tdContent: ["502", "参数错误"]
                }, {
                    tdContent: ["503", "系统繁忙"]
                }, {
                    tdContent: ["509", "请求异常"]
                }, {
                    tdContent: ["206", "token已过期"]
                }, {
                    tdContent: ["207", "token不存在，请重新获取token"]
                }, {
                    tdContent: ["208", "验证不通过，不允许登录"]
                }, {
                    tdContent: ["209", "sign签名错误"]
                }, {
                    tdContent: ["210", "appid对应的开发者未注册"]
                }, {
                    tdContent: ["301", "图片上传失败"]
                }, {
                    tdContent: ["302", "图片格式错误"]
                }, {
                    tdContent: ["303", "图片路径不存在"]
                }, {
                    tdContent: ["407", "注册的设备已存在"]
                }, {
                    tdContent: ["408", "此设备不存在"]
                }, {
                    tdContent: ["409", "设备之前绑定的账号还在有效期，请先修改"]
                }, {
                    tdContent: ["410", "还未添加此固件，请先添加"]
                }, {
                    tdContent: ["411", "此设备类型名称与之前设备类型名称不符，请确认"]
                }, {
                    tdContent: ["412", "设备类型名称不允许修改"]
                }, {
                    tdContent: ["413", "鉴权未通过，该账号未授权或授权已失效"]
                }, {
                    tdContent: ["414", "日期参数错误"]
                }, {
                    tdContent: ["415", "设备鉴权为按次数租赁时times字段必须大于0"]
                }, {
                    tdContent: ["416", "此账号下该设备类型之前绑定的鉴权还有效"]
                }, {
                    tdContent: ["417", "此账号下此类型设备无鉴权或最新鉴权不是本账号"]
                }, {
                    tdContent: ["418", "设备鉴权验证未通过，该账号授权已过期或次数用完"]
                }, {
                    tdContent: ["419", "设备类型总次数未分配或已用完"]
                }, {
                    tdContent: ["420", "需要验证校验数据，请传入校验单号"]
                }, {
                    tdContent: ["421", "未查到校验信息"]
                }, {
                    tdContent: ["422", "不允许用此设备"]
                }, {
                    tdContent: ["423", "校验数据已过期"]
                }, {
                    tdContent: ["424", "校验数据中没有结束日期"]
                }, {
                    tdContent: ["425", "校验数据次数已用完或剩余次数不足"]
                }, {
                    tdContent: ["426", "不允许用此设备类型"]
                }, {
                    tdContent: ["427", "没有锁定的校验记录"]
                }, {
                    tdContent: ["428", "设备或校验信息已经锁定"]
                }, {
                    tdContent: ["429", "数据库中已存在此条数据信息"]
                }, {
                    tdContent: ["430", "版本相同，无须更新"]
                }, {
                    tdContent: ["431", "校验规则已存在"]
                }, {
                    tdContent: ["432", "设备鉴权为按时间租赁时enddate字段不能为空"]
                }, {
                    tdContent: ["433", "设备类型已存在"]
                }, {
                    tdContent: ["434", "设备类型下有设备存在"]
                }, {
                    tdContent: ["435", "设备类型不存在"]
                }, {
                    tdContent: ["445", "包含不允许上传的文件类型"]
                }, {
                    tdContent: ["446", "保存文件失败"]
                }, {
                    tdContent: ["447", "文件不存在"]
                }, {
                    tdContent: ["600", "设备已过期，请及时充值"]
                }, {
                    tdContent: ["606", "余额不足"]
                }, {
                    tdContent: ["620", "未传入图片地址"]
                }, {
                    tdContent: ["622", "未选择需要生成的图片"]
                }, {
                    tdContent: ["625", "请输入有效的图片参数"]
                }, {
                    tdContent: ["633", "系统繁忙，请稍后再试"]
                }, {
                    tdContent: ["644", "AI检测图片URL不存在"]
                }, {
                    tdContent: ["645", "您传入的图片未检测到人脸！"]
                }, {
                    tdContent: ["655", "您传入的图片人脸比例太小！"]
                }, {
                    tdContent: ["656", "您传入的图片人脸不完整！"]
                },{
                    tdContent: ["661", "从图片的url下载图片失败！"]
                }, {
                    tdContent: ["662", "无法解析人脸,检查图片质量！"]
                }, {
                    tdContent: ["663", "人脸有被遮挡,请勿遮挡面部！"]
                }, {
                    tdContent: ["664", "人脸模糊,人脸图片模糊,前端页面可以提示用户拍摄时不要晃动手机！"]
                }, {
                    tdContent: ["665", "人脸光照不好,提示用户到光线适宜的地方拍摄！"]
                }, {
                    tdContent: ["666", "活体检测未通过,此次活体检测结果为非活体！"]
                }, {
                    tdContent: ["667", "质量检测未通过 左眼遮挡程度过高,提示用户请勿遮挡左眼！"]
                }, {
                    tdContent: ["668", "质量检测未通过 右眼遮挡程度过高,提示用户请勿遮挡右眼！"]
                }, {
                    tdContent: ["669", "质量检测未通过 左脸遮挡程度过高,提示用户请勿遮挡左脸颊！"]
                }, {
                    tdContent: ["670", "质量检测未通过 右脸遮挡程度过高,提示用户请勿遮挡右脸颊！"]
                }, {
                    tdContent: ["671", "质量检测未通过 下巴遮挡程度过高,提示用户请勿遮挡下巴！"]
                }, {
                    tdContent: ["672", "质量检测未通过 鼻子遮挡程度过高,提示用户请勿遮挡鼻子！"]
                }, {
                    tdContent: ["673", "质量检测未通过 嘴巴遮挡程度过高,提示用户请勿遮挡嘴巴！"]
                }, {
                    tdContent: ["674", "人脸未面向正前方（人脸的角度信息大于30度）,请使用面向正前方的人脸图片！"]
                }, {
                    tdContent: ["675", "图片尺寸太大,请确保图片尺寸在1920x1080以下！"]
                }, {
                    tdContent: ["676", "图片非法 鉴黄未通过,请重新上传合法的图片！"]
                }, {
                    tdContent: ["677", "图片非法 含有政治敏感人物,请重新上传合法的图片！"]
                }, {
                    tdContent: ["678", "图片不规范！"]
                }, {
                    tdContent: ["102001", "bucket已经存在！"]
                }, {
                    tdContent: ["102002", "bucket数量已达上限！"]
                },{
                    tdContent: ["102003", "bucket名称不符合命名规范！"]
                }, {
                    tdContent: ["102004", "bucket不存在！"]
                },{
                    tdContent: ["102005", "bucket中存在文件！"]
                },{
                    tdContent: ["102006", "object已经存在！"]
                },{
                    tdContent: ["102007", "object不存在！"]
                },{
                    tdContent: ["102008", "链接超时！"]
                },{
                    tdContent: ["102009", "链接非法！"]
                }
                ]
            }
        },
        {
            navName: "请求token授权",
            show: !1,
            navOne: [{
                navName: "获取token",
                show: !1,
                text: {
                    title: "获取token",
                    test: ["请求方式：post，请求地址：{dev_domain}/auth/devoauth/authToken"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"],
                    testHref: "authTokenAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "authTokenAddress",
                    requestHref: "authTokenRequest",
                    returnHref: "authTokenReturn",
                    exampleHref: "authTokenExample",
                    otherHref: "authTokenOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"]
                    }, {
                        tdContent: ["appid", "String", "否", "开发者ID", "32"]
                    }, {
                        tdContent: ["appsecret", "String", "否", "开发者签名秘钥（注册后生成）", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["message", "String", "否", "消息：成功/失败/异常"]
                    }, {
                        tdContent: ["code", "int", "否", "对应目录2功能码200/500"]
                    }, {
                        tdContent: ["data", "json", "否", '返回得数据，Json格式数据\n{\n\t"accessToken":获取的token,\n\t"appkey":获取的appkey,\n\t"expire_time":token有效时间（单位：秒）\n},']
                    }],
                    pre: '成功：\n{\n    "data": {\n       "accessToken":"1",\n       "appkey":"11111",\n       "expiresIn":"7200"\n    },\n    "code": 200,\n    "messages": "注册成功"\n}\n\n失败：\n{\n    "data": null,\n    "code": 208,\n    "messages": "验证不通过"\n}',
                    otherMore: "每次获取accessToken如果之前accessToken有效，则返回之前accessToken并返回过期剩余时间"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#authTokenAddress"
                }, {
                    navName: "请求参数",
                    href: "#authTokenRequest"
                }, {
                    navName: "返回参数",
                    href: "#authTokenReturn"
                }, {
                    navName: "数据格式",
                    href: "#authTokenExample"
                }, {
                    navName: "其他说明",
                    href: "#authTokenOther"
                }]
            }, {
                navName: "刷新token",
                show: !1,
                text: {
                    title: "刷新token",
                    test: ["请求方式：post，请求地址：{dev_domain}/auth/devoauth/refreshToken"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"],
                    testHref: "refreshTokenAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "refreshTokenAddress",
                    requestHref: "refreshTokenRequest",
                    returnHref: "refreshTokenReturn",
                    exampleHref: "refreshTokenExample",
                    otherHref: "refreshTokenOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"]
                    }, {
                        tdContent: ["appid", "String", "否", "开发者ID", "32"]
                    }, {
                        tdContent: ["accessToken", "String", "否", "开发者签名秘钥（上个接口获取）", "32"]
                    }, {
                        tdContent: ["appkey", "String", "否", "开发者分配的appkey（上个接口获取）", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["message", "String", "否", "消息：成功/失败/异常"]
                    }, {
                        tdContent: ["code", "int", "否", "对应目录2功能码200/500"]
                    }, {
                        tdContent: ["data", "json", "否", '返回得数据，Json格式数据\n{\n\t"accessToken":获取的token,\n\t"appkey":获取的appkey,\n\t"expire_time":token有效时间（单位：秒）\n},']
                    }],
                    pre: '成功：\n{\n    "data": {\n\t\t"accessToken":"1",\n\t\t"appkey":"11111",\n\t\t"expiresIn":"7200"\n    },\n    "code": 200,\n    "messages": "注册成功"\n}\n失败：\n{\n    "data": null,\n    "code": 208,\n    "messages": "验证不通过"\n}',
                    otherMore: "先验证accessToken是否存在，不存在则要求重新调用获取accessToken接口，否则，如果存在未过期的accessToken，则返回未过期accessToken，不存在则生成。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#refreshTokenAddress"
                }, {
                    navName: "请求参数",
                    href: "#refreshTokenRequest"
                }, {
                    navName: "返回参数",
                    href: "#refreshTokenReturn"
                }, {
                    navName: "数据格式",
                    href: "#refreshTokenExample"
                }, {
                    navName: "其他说明",
                    href: "#refreshTokenOther"
                }]
            }]
        },
        {
            navName: "公共参数",
            show: !1,
            href: "#",
            text: {
                title: "公共参数",
                test: ["以下请求参数是任何一个接口都必须携带的参数，返回参数是每个接口都会返回的公共参数。",
					   "签名规则：",
					   "设接口有参数： c ,  b  ,  a , timestamp，appid，appkey，accessToken，sign，将参数集合内除sign之外的所有非空参数值的参数，按照参数名ASCII码从小到大进行字典排序，",
					   "再拼接成URL键值对的格式：a=value&accessToken=value&appid=value&appkey=value&b=value&c=value&timestamp=value，得到字符串Str。拼接得到的字符串Str再拼接上appsecret。",
					   "特别注意：",
					   "◆ 参数名ASCII码从小到大进行字典排序；",
					   "◆ 如参数的值为空不参与签名；",
					   "◆ 接口参数如非特别注明，参数都是query参数，body参数不参与签名；",
					   "◆ 请求参数都需要UrlEncoder，但拼接成URL键值对时，参数不用进行UrlEncoder。"
					   ],
                thContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"],
                testHref: "publicParam",
                requestTitle: "请求参数列表",
                returnTitle: "返回参数列表",
                requestHref: "publicParamRequest",
                returnHref: "publicParamReturn",
                trContent: [{
                    tdContent: ["请求参数", "类型", "可否为空", "属性", "最大长度"]
                }, {
                    tdContent: ["appid", "String", "否", "开发者ID", "32"]
                }, {
                    tdContent: ["accessToken", "String", "否", "开发者签名秘钥（上个接口获取）", "32"]
                }, {
                    tdContent: ["appkey", "String", "否", "开发者分配的appkey（上个接口获取）", "32"]
                }, {
                    tdContent: ["sign", "String", "否", "所有参数+时间戳+appsecret，排序后经过MD加密，上传图片（文件）接口中，图片和文件不需要参与加密", "32"]
                }, {
                    tdContent: ["timestamp", "String", "否", "时间戳，当前毫秒数", "15"]
                }],
                returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                returnContent: [{
                    tdContent: ["返回参数", "类型", "可否为空", "属性"]
                }, {
                    tdContent: ["message", "String", "否", "消息：成功/失败/异常"]
                }, {
                    tdContent: ["code", "int", "否", "对应目录2功能码200/500"]
                }, {
                    tdContent: ["data", "json", "否", "返回得数据，Json格式数据"]
                }]
            },
            navTwo: [{
                navName: "请求参数",
                show: !1,
                href: "#publicParamRequest"
            }, {
                navName: "返回参数",
                show: !1,
                href: "#publicParamReturn"
            }]
        },
        {
            navName: "图片操作",
            show: !1,
            href: "#imageAPI",
            navOne: [{
                navName: "上传图片",
                show: !1,
                text: {
                    title: "上传图片",
                    test: ["请求方式：post，请求地址：{image_domain}/api/uploadFile"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "imageUploadAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "imageUploadAddress",
                    requestHref: "imageUploadRequest",
                    returnHref: "imageUploadReturn",
                    exampleHref: "imageUploadExample",
                    otherHref: "imageUploadOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["files", "file", "否", "开发者上传的图片（支持多图片）"]
                    }, {
                        tdContent: ["useOldName", "String", "/", "是否保存原文件名1.保存2.不保存（默认1.保存）"]
                    }, {
                        tdContent: ["isPrivate", "Boolean", "/", "是否私有化 true 是（默认）;false 否。\n    当为true时，返回api地址，只有通过token访问；\n    当为false时，返回公开访问地址，可直接地址访问"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["image_array", "String[]", "/", "返回的路径数组"]
                    }],
                    pre: '成功：\n{\n    "data": {\n\t"image_array":[\n\t    "http://sqqssqsq.jpg",\n\t    "http://sqqssqsdsq.jpg"\n\t]\n    },\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "身份认证失败",\n    "code": "504"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#imageUploadAddress"
                }, {
                    navName: "请求参数",
                    href: "#imageUploadRequest"
                }, {
                    navName: "返回参数",
                    href: "#imageUploadReturn"
                }, {
                    navName: "数据格式",
                    href: "#imageUploadExample"
                }, {
                    navName: "其他说明",
                    href: "#imageUploadOther"
                }]
            }, {
                navName: "根据url上传图片",
                show: !1,
                text: {
                    title: "根据url上传图片",
                    test: ["请求方式：post，请求地址：{image_domain}/api/uploadFileByUrl"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "imageUrlUploadAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "imageUrlUploadAddress",
                    requestHref: "imageUrlUploadRequest",
                    returnHref: "imageUrlUploadReturn",
                    exampleHref: "imageUrlUploadExample",
                    otherHref: "imageUrlUploadOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["urls", "String[]", "否", "开发者上传的图片地址（支持多图片）urls通过逗号隔开。"]
                    }, {
                        tdContent: ["useOldName", "String", "/", "是否保存原文件名1.保存2.不保存（默认1.保存）"]
                    }, {
                        tdContent: ["isPrivate", "Boolean", "/", "是否私有化 true 是（默认）;false 否。\n    当为true时，返回api地址，只有通过token访问；\n    当为false时，返回公开访问地址，可直接地址访问"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["image_array", "String[]", "/", "返回的路径数组"]
                    }],
                    pre: '成功：\n{\n    "data": {\n\t"image_array":[\n\t    "http://sqqssqsq.jpg",\n\t    "http://sqqssqsdsq.jpg"\n\t]\n    },\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "身份认证失败",\n    "code": "504"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#imageUrlUploadAddress"
                }, {
                    navName: "请求参数",
                    href: "#imageUrlUploadRequest"
                }, {
                    navName: "返回参数",
                    href: "#imageUrlUploadReturn"
                }, {
                    navName: "数据格式",
                    href: "#imageUrlUploadExample"
                }, {
                    navName: "其他说明",
                    href: "#imageUrlUploadOther"
                }]
            }, {
                navName: "图片删除",
                show: !1,
                text: {
                    title: "图片删除",
                    test: ["请求方式：post，请求地址：{image_domain}/api/del"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "deleteImageAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "deleteImageAddress",
                    requestHref: "deleteImageRequest",
                    returnHref: "deleteImageReturn",
                    exampleHref: "deleteImageExample",
                    otherHref: "deleteImageOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["hashPaths", "string[]", "否", "需要删除的图片路径（允许批量删除）"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["del_num", "int", "否", "删除图片的数量"]
                    }],
                    pre: '成功：\n{\n    "data": {\n\t"del_num":2\t\t\t\n    },\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": {},\n    "message": "请求失败",\n    "code": "500"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#deleteImageAddress"
                }, {
                    navName: "请求参数",
                    href: "#deleteImageRequest"
                }, {
                    navName: "返回参数",
                    href: "#deleteImageReturn"
                }, {
                    navName: "数据格式",
                    href: "#deleteImageExample"
                }, {
                    navName: "其他说明",
                    href: "#deleteImageOther"
                }]
            }, {
                navName: "私有图片下载",
                show: !1,
                text: {
                    title: "私有图片下载",
                    test: ["请求方式：post", "请求地址：上传图片时返回的图片地址({image_domain}/api/get/{hash}/{subPath})", "需要accessToken和sign签名等公共参数"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "downloadImageAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "downloadAddress",
                    requestHref: "downloadRequest",
                    returnHref: "downloadReturn",
                    exampleHref: "downloadExample",
                    otherHref: "downloadOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: "成功：\n    获得图片byte[]\n失败：",
                    otherMore: "需要accessToken和sign签名等公共参数"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#downloadAddress"
                }, {
                    navName: "请求参数",
                    href: "#downloadRequest"
                }, {
                    navName: "返回参数",
                    href: "#downloadReturn"
                }, {
                    navName: "数据格式",
                    href: "#downloadExample"
                }, {
                    navName: "其他说明",
                    href: "#downloadOther"
                }]
            }, {
                navName: "公共图片下载",
                show: !1,
                text: {
                    title: "公共图片下载",
                    test: ["请求方式：post", "请求地址：上传图片时返回的图片地址({image_domain}/image/{appid}/get/{hash}/{subPath})", "不需要accessToken和sign签名等公共参数"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "downloadPublicImageAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "downloadPublicAddress",
                    requestHref: "downloadPublicRequest",
                    returnHref: "downloadPublicReturn",
                    exampleHref: "downloadPublicExample",
                    otherHref: "downloadPublicOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: "成功：\n    获得图片byte[]\n失败：",
                    otherMore: "不需要accessToken和sign签名等公共参数，但需要appid"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#downloadPublicAddress"
                }, {
                    navName: "请求参数",
                    href: "#downloadPublicRequest"
                }, {
                    navName: "返回参数",
                    href: "#downloadPublicReturn"
                }, {
                    navName: "数据格式",
                    href: "#downloadPublicExample"
                }, {
                    navName: "其他说明",
                    href: "#downloadPublicOther"
                }]
            }]
        },
        {
            navName: "对象存储",
            show: !1,
            href: "#storageAPI",
            navOne: [
                {
                navName: "创建桶",
                show: !1,
                text: {
                    title: "创建桶",
                    test: ["请求方式：put，请求地址：{image_domain}/api/bucket"],
                    thContent: ["请求参数", "类型", "可否为空", "属性"],
                    testHref: "putBucketAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式",
                    otherTitle: "其他说明",
                    addressHref: "putBucketAddress",
                    requestHref: "putBucketRequest",
                    returnHref: "putBucketReturn",
                    exampleHref: "putBucketExample",
                    otherHref: "putBucketOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["", "", "", ""]
                    }],
                    pre: '成功：\n{\n    "data": null,\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "Bucket名称不符合命名规范",\n    "code": "100009"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#putBucketAddress"
                }, {
                    navName: "请求参数",
                    href: "#putBucketRequest"
                }, {
                    navName: "返回参数",
                    href: "#putBucketReturn"
                }, {
                    navName: "数据格式",
                    href: "#putBucketExample"
                }, {
                    navName: "其他说明",
                    href: "#putBucketOther"
                }]
            },
                {
                    navName: "删除桶",
                    show: !1,
                    text: {
                        title: "删除桶",
                        test: ["请求方式：delete，请求地址：{image_domain}/api/bucket"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "deleteBucketAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "deleteBucketAddress",
                        requestHref: "deleteBucketRequest",
                        returnHref: "deleteBucketReturn",
                        exampleHref: "deleteBucketExample",
                        otherHref: "deleteBucketOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["", "", "", ""]
                        }],
                        pre: '成功：\n{\n    "data": null,\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "Bucket名称不符合命名规范",\n    "code": "100009"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#deleteBucketAddress"
                    }, {
                        navName: "请求参数",
                        href: "#deleteBucketRequest"
                    }, {
                        navName: "返回参数",
                        href: "#deleteBucketReturn"
                    }, {
                        navName: "数据格式",
                        href: "#deleteBucketExample"
                    }, {
                        navName: "其他说明",
                        href: "#deleteBucketOther"
                    }]
                },
                {
                    navName: "获取桶列表",
                    show: !1,
                    text: {
                        title: "获取桶列表",
                        test: ["请求方式：get，请求地址：{image_domain}/api/bucket/list"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "listBucketAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "listBucketAddress",
                        requestHref: "listBucketRequest",
                        returnHref: "listBucketReturn",
                        exampleHref: "listBucketExample",
                        otherHref: "listBucketOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["", "", "", ""]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["id", "string", "否", "桶标识"]
                        }, {
                            tdContent: ["bucketName", "string", "否", "桶名称"]
                        }, {
                            tdContent: ["endpoint", "string", "否", "端点"]
                        }, {
                            tdContent: ["size", "long", "/", "存储的文件大小(B)"]
                        }, {
                            tdContent: ["objectNums", "long", "/", "存储的文件数量"]
                        }, {
                            tdContent: ["createTime", "string", "/", "桶创建时间"]
                        }],

                        pre: '成功：\n{\n    "data": null,\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "请求异常",\n    "code": "509"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#listBucketAddress"
                    }, {
                        navName: "请求参数",
                        href: "#listBucketRequest"
                    }, {
                        navName: "返回参数",
                        href: "#listBucketReturn"
                    }, {
                        navName: "数据格式",
                        href: "#listBucketExample"
                    }, {
                        navName: "其他说明",
                        href: "#listBucketOther"
                    }]
                },
                {
                    navName: "上传文件流",
                    show: !1,
                    text: {
                        title: "上传文件流",
                        test: ["请求方式：put，请求地址：{image_domain}/api/object"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "putObjectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "putObjectAddress",
                        requestHref: "putObjectRequest",
                        returnHref: "putObjectReturn",
                        exampleHref: "putObjectExample",
                        otherHref: "putObjectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["objectName", "String", "/", "表示上传文件到ceph时需要指定包含文件扩展名在内的完整名称，例如: 123.jpg。"]
                        }, {
                            tdContent: ["isPrivate", "boolean", "/", "文件访问权限，true表示私有,false表示公开"]
                        }, {
                            tdContent: ["file", "file", "/", "开发者上传的文件"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["", "", "", ""]
                        }],
                        pre: '成功：\n{\n    "data": null,\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "服务异常",\n    "code": "509"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#putObjectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#putObjectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#putObjectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#putObjectExample"
                    }, {
                        navName: "其他说明",
                        href: "#putObjectOther"
                    }]
                },
                {
                    navName: "获取文件元数据",
                    show: !1,
                    text: {
                        title: "获取文件元数据",
                        test: ["请求方式：get，请求地址：{image_domain}/api/object/meta"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "getObjectMetaAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "getObjectMetaAddress",
                        requestHref: "getObjectMetaRequest",
                        returnHref: "getObjectMetaReturn",
                        exampleHref: "getObjectMetaExample",
                        otherHref: "getObjectMetaOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["objectName", "String", "/", "表示上传文件到ceph时需要指定包含文件扩展名在内的完整名称，例如: 123.jpg。"]
                        }, ],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "string", "否", "桶名称"]
                        }, {
                            tdContent: ["objectName", "string", "否", "文件名称"]
                        }, {
                            tdContent: ["size", "long", "/", "存储的文件大小(B)"]
                        }, {
                            tdContent: ["etag", "string", "/", "文件标识码"]
                        }, {
                            tdContent: ["accessRights", "boolean", "/", "访问权限 true私有访问 false公开访问"]
                        }, {
                            tdContent: ["storeFree", "boolean", "/", "存储是否计费 true收费，false免费"]
                        }, {
                            tdContent: ["downloadFree", "boolean", "/", "下载是否计费 true收费，false免费"]
                        }, {
                            tdContent: ["contentType", "string", "/", "文件格式"]
                        }],
                        pre: '成功：\n{\n' +
                            '    "data": {\n' +
                            '        "bucketName": "default",\n' +
                            '        "objectName": "a.jpg",\n' +
                            '        "size": 318549,\n' +
                            '        "etag": "ca6bc7946042c08aed569d8f25f937a9",\n' +
                            '        "accessRights": true,\n' +
                            '        "storeFree": true,\n' +
                            '        "downloadFree": true,\n' +
                            '        "contentType": "image/jpeg"\n' +
                            '    },\n' +
                            '    "code": 200,\n' +
                            '    "message": "操作成功"\n' +
                            '}' +
                            '\n失败：\n{\n    "data": null,\n    "message": "请求异常",\n    "code": "509"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#getObjectMetaAddress"
                    }, {
                        navName: "请求参数",
                        href: "#getObjectMetaRequest"
                    }, {
                        navName: "返回参数",
                        href: "#getObjectMetaReturn"
                    }, {
                        navName: "数据格式",
                        href: "#getObjectMetaExample"
                    }, {
                        navName: "其他说明",
                        href: "#getObjectMetaOther"
                    }]
                },
                {
                    navName: "获取文件列表",
                    show: !1,
                    text: {
                        title: "获取文件列表",
                        test: ["请求方式：get，请求地址：{image_domain}/api/object/list"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "listObjectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "listObjectAddress",
                        requestHref: "listObjectRequest",
                        returnHref: "listObjectReturn",
                        exampleHref: "listObjectExample",
                        otherHref: "listObjectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "string", "否", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["searchWordPrefix", "string", "否", "分页条件关键字前缀"]
                        }, {
                            tdContent: ["current", "int", "否", "当前页"]
                        }, {
                            tdContent: ["size", "int", "否", "每页条数"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "string", "否", "桶名称"]
                        }, {
                            tdContent: ["objectName", "string", "否", "文件objectName"]
                        }, {
                            tdContent: ["fileUrl", "string", "/", "包含文件目录的文件名"]
                        }, {
                            tdContent: ["size", "long", "/", "存储的文件大小(B)"]
                        }, {
                            tdContent: ["etag", "string", "/", "文件的唯一码，可以用来判断是否事同一个文件"]
                        }, {
                            tdContent: ["accessRights", "boolean", "/", "访问权限：true表示私有访问，false表示公有访问"]
                        }, {
                            tdContent: ["storeFree", "boolean", "/", "存储收费：true表示收费，false表示免费"]
                        }, {
                            tdContent: ["downloadFree", "boolean", "/", "下载收费：true表示收费，false表示免费"]
                        }, {
                            tdContent: ["contentType", "string", "/", "文件的content-type"]
                        }, {
                            tdContent: ["createTime", "string", "/", "文件创建时间"]
                        }],
                        pre: '{\n' +
                            '    "data": [\n' +
                            '        {\n' +
                            '            "bucketName": "default",\n' +
                            '            "objectKey": "dat.rar",\n' +
                            '            "fileUrl": "dat.rar",\n' +
                            '            "size": 8640483,\n' +
                            '            "etag": "975a86d60176e8e18085ef4d8c2b6033",\n' +
                            '            "accessRights": false,\n' +
                            '            "storeFree": true,\n' +
                            '            "downloadFree": true,\n' +
                            '            "contentType": "application/octet-stream",\n' +
                            '            "createTime": "2020-09-01 17:08:04"\n' +
                            '        }\n' +
                            '    ],\n' +
                            '    "code": 200,\n' +
                            '    "message": "操作成功"\n' +
                            '}' +
                            '\n失败：\n{\n    "data": null,\n    "message": "请求异常",\n    "code": "509"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#listObjectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#listObjectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#listObjectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#listObjectExample"
                    }, {
                        navName: "其他说明",
                        href: "#listObjectOther"
                    }]
                },
                {
                    navName: "获取公有文件",
                    show: !1,
                    text: {
                        title: "获取公有文件",
                        test: ["请求方式：get", "请求地址：上传图片时返回的图片地址({image_domain}/image/{bucketName}/{objectName})", "不需要accessToken和sign签名等公共参数"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "downloadObjectPublicAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "downloadObjectPublicAddress",
                        requestHref: "downloadObjectPublicRequest",
                        returnHref: "downloadObjectPublicReturn",
                        exampleHref: "downloadObjectPublicExample",
                        otherHref: "downloadObjectPublicOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["objectName", "String", "/", "表示上传文件到ceph时需要指定包含文件扩展名在内的完整名称，例如: 123.jpg。"]
                        }, ],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }],
                        pre: "成功：\n    获得图片byte[]\n失败：",
                        otherMore: "不需要accessToken、appid和sign签名等公共参数"
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#downloadObjectPublicAddress"
                    }, {
                        navName: "请求参数",
                        href: "#downloadObjectPublicRequest"
                    }, {
                        navName: "返回参数",
                        href: "#downloadObjectPublicReturn"
                    }, {
                        navName: "数据格式",
                        href: "#downloadObjectPublicExample"
                    }, {
                        navName: "其他说明",
                        href: "#downloadObjectPublicOther"
                    }]
                },
                {
                    navName: "获取私有文件链接",
                    show: !1,
                    text: {
                        title: "获取预览链接",
                        test: ["请求方式：get，请求地址：{image_domain}/api/object/link"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "linkObjectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式",
                        otherTitle: "其他说明",
                        addressHref: "linkObjectAddress",
                        requestHref: "linkObjectRequest",
                        returnHref: "linkObjectReturn",
                        exampleHref: "linkObjectExample",
                        otherHref: "linkObjectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["objectName", "String", "/", "表示上传文件到ceph时需要指定包含文件扩展名在内的完整名称，例如: 123.jpg。"]
                        }, ],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["data", "string", "/", "预览链接"]
                        }],
                        pre: '\n成功：\n{\n' +
                            '    "data": "http://192.168.1.144:12100/image/default/a.jpg?expires=1600399388592&idankeeAccessKeyId=TMP.f7638cf59879ca5006c624d74beba01d6f37cf9941dda593&signature=e93042ab1c918eea6e9d35f292a15af4",\n' +
                            '    "code": 200,\n' +
                            '    "message": "操作成功"\n' +
                            '}' +
                            '\n失败：\n{\n    "data": null,\n    "message": "请求异常",\n    "code": "509"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#linkObjectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#linkObjectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#linkObjectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#linkObjectExample"
                    }, {
                        navName: "其他说明",
                        href: "#linkObjectOther"
                    }]
                },
                {
                    navName: "删除文件",
                    show: !1,
                    text: {
                        title: "图片删除",
                        test: ["请求方式：delete，请求地址：{image_domain}/api/object"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "deleteObjectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "deleteObjectAddress",
                        requestHref: "deleteObjectRequest",
                        returnHref: "deleteObjectReturn",
                        exampleHref: "deleteObjectExample",
                        otherHref: "deleteObjectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["bucketName", "String", "/", "桶名称（只能是3-63个字符，只能包含数字和小写字母和短划线，不能以短划线结尾）"]
                        }, {
                            tdContent: ["objectName", "String", "/", "表示上传文件到ceph时需要指定包含文件扩展名在内的完整名称，例如: 123.jpg。"]
                        }, ],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }, {
                            tdContent: ["", "", "", ""]
                        }],
                        pre: '成功：\n{\n    "data": null,\n    "message": "请求成功",\n    "code": "200"\n}\n失败：\n{\n    "data": null,\n    "message": "请求失败",\n    "code": "500"\n}',
                        otherMore: ""
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#deleteObjectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#deleteObjectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#deleteObjectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#deleteObjectExample"
                    }, {
                        navName: "其他说明",
                        href: "#deleteObjectOther"
                    }]
                },

                {
                    navName: "私有文件下载（兼容）",
                    show: !1,
                    text: {
                        title: "私有文件下载（兼容）",
                        test: ["请求方式：post", "请求地址：上传图片时返回的图片地址({image_domain}/api/bucketName/{hash}/{subPath})", "需要accessToken和sign签名等公共参数"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "downloadObjectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "downloadObjectAddress",
                        requestHref: "downloadObjectRequest",
                        returnHref: "downloadObjectReturn",
                        exampleHref: "downloadObjectExample",
                        otherHref: "downloadObjectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }],
                        pre: "成功：\n    获得图片byte[]\n失败：",
                        otherMore: "需要accessToken和sign签名等公共参数"
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#downloadObjectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#downloadObjectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#downloadObjectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#downloadObjectExample"
                    }, {
                        navName: "其他说明",
                        href: "#downloadObjectOther"
                    }]
                },
                {
                    navName: "公共文件下载（兼容）",
                    show: !1,
                    text: {
                        title: "公共文件下载（兼容）",
                        test: ["请求方式：post", "请求地址：上传图片时返回的图片地址({image_domain}/image/{bucketName}/{appid}/get/{hash}/{subPath})", "不需要accessToken和sign签名等公共参数"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "downloadObjectPublicImageAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "downloadObjectPublicAddress",
                        requestHref: "downloadObjectPublicRequest",
                        returnHref: "downloadObjectPublicReturn",
                        exampleHref: "downloadObjectPublicExample",
                        otherHref: "downloadObjectPublicOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"]
                        }],
                        pre: "成功：\n    获得图片byte[]\n失败：",
                        otherMore: "不需要accessToken和sign签名等公共参数，但需要appid"
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#downloadObjectPublicAddress"
                    }, {
                        navName: "请求参数",
                        href: "#downloadObjectPublicRequest"
                    }, {
                        navName: "返回参数",
                        href: "#downloadObjectPublicReturn"
                    }, {
                        navName: "数据格式",
                        href: "#downloadObjectPublicExample"
                    }, {
                        navName: "其他说明",
                        href: "#downloadObjectPublicOther"
                    }]
                },

            //     {
            //     navName: "访问公有文件",
            //     show: !1,
            //     text: {
            //         title: "访问公有文件",
            //         test: ["请求方式：get", "请求地址：公有文件的访问地址({image_domain}/v2/{bucketName}/{objectName})"],
            //         thContent: ["请求参数", "类型", "可否为空", "属性"],
            //         testHref: "getObjectAPI",
            //         addressTitle: "请求方式及地址",
            //         requestTitle: "请求参数列表",
            //         returnTitle: "返回参数列表",
            //         exampleTitle: "数据格式 ",
            //         otherTitle: "其他说明",
            //         addressHref: "getObjectAddress",
            //         requestHref: "getObjectRequest",
            //         returnHref: "getObjectReturn",
            //         exampleHref: "getObjectExample",
            //         otherHref: "getObjectOther",
            //         trContent: [{
            //             tdContent: ["请求参数", "类型", "可否为空", "属性"]
            //         }],
            //         returnThContent: ["返回参数", "类型", "可否为空", "属性"],
            //         returnContent: [{
            //             tdContent: ["返回参数", "类型", "可否为空", "属性"]
            //         }],
            //         pre: "成功：\n    获得图片byte[]\n失败：",
            //         otherMore: ""
            //     },
            //     navTwo: [{
            //         navName: "请求方式及地址",
            //         href: "#getObjectAddress"
            //     }, {
            //         navName: "请求参数",
            //         href: "#getObjectRequest"
            //     }, {
            //         navName: "返回参数",
            //         href: "#getObjectReturn"
            //     }, {
            //         navName: "数据格式",
            //         href: "#getObjectExample"
            //     }, {
            //         navName: "其他说明",
            //         href: "#downloadOther"
            //     }]
            // },

            ]
        },
        {
            navName: "皮肤AI检测",
            show: !1,
            href: "#AIAPI",
            navOne: [
                //                 {
                //                 navName: "上传图片/生成图片/返回结果API",
                //                 show: !1,
                //                 text: {
                //                     title: "上传图片/生成图片/返回结果API",
                //                     test: ["请求方式：post，请求地址：{ai_domain}/api/skin/skinDetection", "备注：图片生成规则：极化图生成：0、1、2、3 ；平行偏振图：5、6、7；UV图生成：4；图片不规范可能造成图片生成失败"],
                //                     thContent: ["请求参数", "类型", "可否为空", "属性"],
                //                     testHref: "skinDetectionAPI",
                //                     addressTitle: "请求方式及地址",
                //                     requestTitle: "请求参数列表",
                //                     returnTitle: "返回参数列表",
                //                     exampleTitle: "数据格式 ",
                //                     otherTitle: "其他说明",
                //                     addressHref: "skinDetectionAddress",
                //                     requestHref: "skinDetectionRequest",
                //                     returnHref: "skinDetectionReturn",
                //                     exampleHref: "skinDetectionExample",
                //                     otherHref: "skinDetectionOther",
                //                     trContent: [{
                //                         tdContent: ["请求参数", "类型", "可否为空", "属性"],
                //                     }, {
                //                         tdContent: ["polarizationmap", "String", "/", "极化图（图片地址）"]
                //                     }, {
                //                         tdContent: ["parallel", "String", "/", "平行偏振图（图片地址）"]
                //                     }, {
                //                         tdContent: ["uv", "String", "/", "UV图（图片地址）"]
                //                     }, {
                //                         tdContent: ["imgresult_type", "String", "否", "选择返回参数，可多选。例如1,2,3 用逗号分隔，输入-1表示生成全部。\n    0.brown(棕色图)\n    1.pink(红色图)\n    2.complexion(肤色图)\n    3.spots_contours(斑点图)\n    4.uv_gray(紫外黑白图)\n    5.wrinkle(皱纹图)\n    6.wrinkle_deeper(皱纹加深图)\n    7.wrinkle_lighter(皱纹减淡图)"]
                //                     }],
                //                     returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                //                     returnContent: [{
                //                         tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                     }, {
                //                         tdContent: ["data", "Presentation", "/", "检测报告(Presentation表)"]
                //                     }, {
                //                         tdContent: ["images", "List<Image>", "/", "返回生成图像集合对象(Image表)"]
                //                     }, {
                //                         tdContent: ["urls", "ResultImage", "否", "返回检测对应的图片参数(ResultImage表)"]
                //                     }],
                //                     returnContents: [{
                //                         title: "Presentation",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["age", "Int", "/", "年龄"]
                //                         }, {
                //                             tdContent: ["blackhead", "Blackhead", "/", "黑头(Blackhead表)"]
                //                         }, {
                //                             tdContent: ["dark_circle", "DarkCircle", "/", "黑眼圈(DarkCircle表)"]
                //                         }, {
                //                             tdContent: ["ethnicity", "String", "/", "人种"]
                //                         }, {
                //                             tdContent: ["gender", "Int", "/", "性别"]
                //                         }, {
                //                             tdContent: ["head_pose", "HeadPose", "/", "人脸姿态(HeadPose表)"]
                //                         }, {
                //                             tdContent: ["landmarks", "Landmarks", "/", "人脸关键点(Landmarks表)"]
                //                         }]
                //                     }, {
                //                         title: "Blackhead",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["cords", "List<int[]>", "/", "黑头集合坐标点，对象是数字数组。"]
                //                         }, {
                //                             tdContent: ["count", "Int", "/", "黑头数量"]
                //                         }]
                //                     }, {
                //                         title: "DarkCircle",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["cords", "Int", "/", "状态"]
                //                         }, {
                //                             tdContent: ["status", "Int", "/", "状态"]
                //                         }]
                //                     }, {
                //                         title: "HeadPose",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["pitch", "BigDecimal", "/", "沿x轴旋转的角度"]
                //                         }, {
                //                             tdContent: ["roll", "BigDecimal", "/", "沿z轴旋转的角度"]
                //                         }, {
                //                             tdContent: ["yaw", "BigDecimal", "/", "沿y轴旋转的角度"]
                //                         }]
                //                     }, {
                //                         title: "Landmarks",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["contour_chin", "List<int[]>", "/", "下巴，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["contour_left", "List<int[]>", "/", "左边脸部轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["contour_right", "List<int[]>", "/", "右边脸部轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_bottom", "List<int[]>", "/", "左边下眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_center", "List<int[]>", "/", "左边眼睛中心，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_pupil", "List<int[]>", "/", "左边眼睛瞳孔，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eyebrow_bottom", "List<int[]>", "/", "左边下眉毛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eyebrow_top", "List<int[]>", "/", "左边上眉毛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["lower_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["lower_lip_top", "List<int[]>", "/", "上嘴唇上关键点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_bridge", "List<int[]>", "/", "鼻梁，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_left", "List<int[]>", "/", "鼻子左边轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_middle", "List<int[]>", "/", "鼻头中心点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_right", "List<int[]>", "/", "鼻子右边轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_bottom", "List<int[]>", "/", "右边下眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_center", "List<int[]>", "/", "右边眼睛中心，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_pupil", "List<int[]>", "/", "右边眼睛瞳孔，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eyebrow_bottom", "List<int[]>", "/", "右边下眉毛，68 关键点无此项"]
                //                         }, {
                //                             tdContent: ["right_eyebrow_top", "List<int[]>", "/", "右边上眉毛"]
                //                         }, {
                //                             tdContent: ["upper_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点"]
                //                         }, {
                //                             tdContent: ["upper_lip_top", "List<int[]>", "/", "上嘴唇上关键点"]
                //                         }]
                //                     }, {
                //                         title: "Image",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["name", "String", "/", "图像名称"]
                //                         }, {
                //                             tdContent: ["url", "String", "/", "图像访问路径"]
                //                         }, {
                //                             tdContent: ["data", "Object", "/", "其他检测信息"]
                //                         }]
                //                     }, {
                //                         title: "ResultImage",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["parallel", "String", "/", "极化图访问路径"]
                //                         }, {
                //                             tdContent: ["polarization", "String", "/", "平行偏振图访问路径"]
                //                         }, {
                //                             tdContent: ["uv", "String", "/", "UV图访问路径"]
                //                         }]
                //                     }],
                //                     pre: `
                // 成功: {
                // 	"data": {
                // 		"data": {
                // 			"age": 19,
                // 			"blackhead": {
                // 				"cords": Array[90],
                // 				"count": 90
                // 			},
                // 			"dark_circle": {
                // 				"code": 12100,
                // 				"status": 12100
                // 			},
                // 			"ethnicity": "ASIAN",
                // 			"gender": 2,
                // 			"head_pose": {
                // 				"pitch": -11.693628311157227,
                // 				"roll": 5.3686685562133789,
                // 				"yaw": -1.8990604877471924
                // 			},
                // 			 "landmarks": {
                // 				"contour_chin": Array[1],
                // 				"contour_left": Array[16],
                // 				"contour_right": Array[16],
                // 				"left_eye_bottom": Array[3],
                // 				"left_eye_center": Array[1],
                // 				"left_eye_pupil": Array[1],
                // 				"left_eye_top": Array[5],
                // 				"left_eyebrow_bottom": Array[4],
                // 				"left_eyebrow_top": Array[5],
                // 				"lower_lip_bottom": Array[5],
                // 				"lower_lip_top": Array[3],
                // 				"nose_contour_bridge": Array[4],
                // 				"nose_contour_left": Array[5],
                // 				"nose_contour_middle": Array[1],
                // 				"nose_contour_right": Array[2],
                // 				"right_eye_bottom": Array[3],
                // 				"right_eye_center": Array[1],
                // 				"right_eye_pupil": Array[1],
                // 				"right_eye_top": Array[5],
                // 				"right_eyebrow_bottom": Array[4],
                // 				"right_eyebrow_top": Array[5],
                // 				"upper_lip_bottom": Array[5],
                // 				"upper_lip_top": Array[7]
                // 			}
                // 		},
                // 		"images": [{
                // 			"name": "uv",
                // 			"url": "https://idankee-ai.oss-cn-shenzhen.aliyuncs.com/aiimages/DAj3F0/uv_gray.jpg",
                // 			"version": 0
                // 		}],
                // 		"urls ": {
                // 			"parallel": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/8E3E06E/2.jpg",
                // 			"polarization": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/06E/1.jpg",
                // 			"uv": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/E06E/3.jpg"
                // 		}
                // 	},
                // 	"message": "请求成功",
                // 	"code": "200"
                // }
                // 失败: {
                // 	"data": null,
                // 	"message": "系统繁忙，请稍后再试",
                // 	"code": "633"
                // }
                // 					`,
                //                     otherMore: "三张图片必须上传其中一张图片。三张图片的不规范以及不完整，导致最终的检测数据不会完整。等待结果大约1-10秒。"
                //                 },
                //                 navTwo: [{
                //                     navName: "请求方式及地址",
                //                     href: "#skinDetectionAddress"
                //                 }, {
                //                     navName: "请求参数",
                //                     href: "#skinDetectionRequest"
                //                 }, {
                //                     navName: "返回参数",
                //                     href: "#skinDetectionReturn"
                //                 }, {
                //                     navName: "数据格式",
                //                     href: "#skinDetectionExample"
                //                 }, {
                //                     navName: "其他说明",
                //                     href: "#skinDetectionOther"
                //                 }]
                //             },
                //             {
                //                 navName: "AI检测报告API",
                //                 show: !1,
                //                 text: {
                //                     title: "AI检测报告API",
                //                     test: ["请求方式：post，请求地址：{ai_domain}/api/skin/skinPresentation"],
                //                     thContent: ["请求参数", "类型", "可否为空", "属性"],
                //                     testHref: "skinPresentationAPI",
                //                     addressTitle: "请求方式及地址",
                //                     requestTitle: "请求参数列表",
                //                     returnTitle: "返回参数列表",
                //                     exampleTitle: "数据格式 ",
                //                     otherTitle: "其他说明",
                //                     addressHref: "skinPresentationAddress",
                //                     requestHref: "skinPresentationRequest",
                //                     returnHref: "skinPresentationReturn",
                //                     exampleHref: "skinPresentationExample",
                //                     otherHref: "skinPresentationOther",
                //                     trContent: [{
                //                         tdContent: ["请求参数", "类型", "可否为空", "属性"],
                //                     }, {
                //                         tdContent: ["polarizationmap", "file", "/", "极化图"]
                //                     }, {
                //                         tdContent: ["skintexture", "file", "/", "肌肤纹路图"]
                //                     }, {
                //                         tdContent: ["sundamage", "file", "/", "日晒损伤图"]
                //                     }],
                //                     returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                //                     returnContent: [{
                //                         tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                     }, {
                //                         tdContent: ["Data", "Presentation", "否", "报告结果返回Json(Presentation表)"]
                //                     }],
                //                     returnContents: [{
                //                         title: "Presentation",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["age", "Int", "/", "年龄"]
                //                         }, {
                //                             tdContent: ["blackhead", "Blackhead", "/", "黑头(Blackhead表)"]
                //                         }, {
                //                             tdContent: ["dark_circle", "DarkCircle", "/", "黑眼圈(DarkCircle表)"]
                //                         }, {
                //                             tdContent: ["ethnicity", "String", "/", "人种"]
                //                         }, {
                //                             tdContent: ["gender", "Int", "/", "性别"]
                //                         }, {
                //                             tdContent: ["head_pose", "HeadPose", "/", "人脸姿态(HeadPose表)"]
                //                         }, {
                //                             tdContent: ["landmarks", "Landmarks", "/", "人脸关键点(Landmarks表)"]
                //                         }]
                //                     }, {
                //                         title: "Blackhead",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["cords", "List<int[]>", "/", "黑头集合坐标点，对象是数字数组。"]
                //                         }, {
                //                             tdContent: ["count", "Int", "/", "黑头数量"]
                //                         }]
                //                     }, {
                //                         title: "DarkCircle",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["cords", "Int", "/", "状态"]
                //                         }, {
                //                             tdContent: ["status", "Int", "/", "状态"]
                //                         }]
                //                     }, {
                //                         title: "HeadPose",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["pitch", "BigDecimal", "/", "沿x轴旋转的角度"]
                //                         }, {
                //                             tdContent: ["roll", "BigDecimal", "/", "沿z轴旋转的角度"]
                //                         }, {
                //                             tdContent: ["yaw", "BigDecimal", "/", "沿y轴旋转的角度"]
                //                         }]
                //                     }, {
                //                         title: "Landmarks",
                //                         content: [{
                //                             tdContent: ["返回参数", "类型", "可否为空", "属性"],
                //                         }, {
                //                             tdContent: ["contour_chin", "List<int[]>", "/", "下巴，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["contour_left", "List<int[]>", "/", "左边脸部轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["contour_right", "List<int[]>", "/", "右边脸部轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_bottom", "List<int[]>", "/", "左边下眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_center", "List<int[]>", "/", "左边眼睛中心，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_pupil", "List<int[]>", "/", "左边眼睛瞳孔，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eyebrow_bottom", "List<int[]>", "/", "左边下眉毛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["left_eyebrow_top", "List<int[]>", "/", "左边上眉毛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["lower_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["lower_lip_top", "List<int[]>", "/", "上嘴唇上关键点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_bridge", "List<int[]>", "/", "鼻梁，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_left", "List<int[]>", "/", "鼻子左边轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_middle", "List<int[]>", "/", "鼻头中心点，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["nose_contour_right", "List<int[]>", "/", "鼻子右边轮廓，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_bottom", "List<int[]>", "/", "右边下眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_center", "List<int[]>", "/", "右边眼睛中心，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_pupil", "List<int[]>", "/", "右边眼睛瞳孔，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                //                         }, {
                //                             tdContent: ["right_eyebrow_bottom", "List<int[]>", "/", "右边下眉毛，68 关键点无此项"]
                //                         }, {
                //                             tdContent: ["right_eyebrow_top", "List<int[]>", "/", "右边上眉毛"]
                //                         }, {
                //                             tdContent: ["upper_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点"]
                //                         }, {
                //                             tdContent: ["upper_lip_top", "List<int[]>", "/", "上嘴唇上关键点"]
                //                         }]
                //                     }],
                //                     pre: `成功:
                // {
                // 	"data": {
                // 		"data": {
                // 			"age": 19,
                // 			"blackhead": {            
                // 				"cords": Array[90],
                // 				"count": 90        
                // 			},
                // 			"dark_circle": {            
                // 				"code": 12100,
                // 				"status": 12100        
                // 			},
                // 			"ethnicity": "ASIAN",
                // 			"gender": 2,
                // 			"head_pose": {            
                // 				"pitch": -11.693628311157227,
                // 				"roll": 5.3686685562133789,
                // 				"yaw": -1.8990604877471924        
                // 			},
                // 			 "landmarks": {            
                // 				"contour_chin": Array[1],
                // 				"contour_left": Array[16],
                // 				"contour_right": Array[16],
                // 				"left_eye_bottom": Array[3],
                // 				"left_eye_center": Array[1],
                // 				"left_eye_pupil": Array[1],
                // 				"left_eye_top": Array[5],
                // 				"left_eyebrow_bottom": Array[4],
                // 				"left_eyebrow_top": Array[5],
                // 				"lower_lip_bottom": Array[5],
                // 				"lower_lip_top": Array[3],
                // 				"nose_contour_bridge": Array[4],
                // 				"nose_contour_left": Array[5],
                // 				"nose_contour_middle": Array[1],
                // 				"nose_contour_right": Array[2],
                // 				"right_eye_bottom": Array[3],
                // 				"right_eye_center": Array[1],
                // 				"right_eye_pupil": Array[1],
                // 				"right_eye_top": Array[5],
                // 				"right_eyebrow_bottom": Array[4],
                // 				"right_eyebrow_top": Array[5],
                // 				"upper_lip_bottom": Array[5],
                // 				"upper_lip_top": Array[7]
                // 			}
                // 		},
                // 		"images": [{            
                // 			"name": "uv",
                // 			"url":"https://idankee-ai.oss-cn-shenzhen.aliyuncs.com/aiimages/DAj3F0/uv_gray.jpg",
                // 			"version": 0        
                // 		}],
                // 		"urls ": {        
                // 			"parallel": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/8E3E06E/2.jpg",
                // 			"polarization": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/06E/1.jpg",
                // 			"uv": "https://djm-skin.oss-cn-shenzhen.aliyuncs.com/Picture/E06E/3.jpg"    
                // 		}
                // 	},
                // 	"message": "请求成功",
                // 	"code": "200"
                // }
                // 失败:
                // {
                // 	"data": null,
                // 	"message": "系统繁忙，请稍后再试",
                // 	"code": "633"
                // }`,
                //                     otherMore: "只有完整并规范的三张检测图才能生成完整的检测数据。"
                //                 },
                //                 navTwo: [{
                //                     navName: "请求方式及地址",
                //                     href: "#skinPresentationAddress"
                //                 }, {
                //                     navName: "请求参数",
                //                     href: "#skinPresentationRequest"
                //                 }, {
                //                     navName: "返回参数",
                //                     href: "#skinPresentationReturn"
                //                 }, {
                //                     navName: "数据格式",
                //                     href: "#skinPresentationExample"
                //                 }, {
                //                     navName: "其他说明",
                //                     href: "#skinPresentationOther"
                //                 }]
                //             },
                {
                    navName: "AI图像处理API",
                    show: !1,
                    text: {
                        title: "AI图像处理API",
                        test: ["请求方式：post，请求地址：{ai_domain}/api/image/process"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "skinPresentationNewAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "skinPresentationNewAddress",
                        requestHref: "skinPresentationNewRequest",
                        returnHref: "skinPresentationNewReturn",
                        exampleHref: "skinPresentationNewExample",
                        otherHref: "skinPresentationNewOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"],
                        }, {
                            tdContent: ["img_type", "String", "否", "上传文件类型:url表示上传文件url地址，jpg表示本地上传文件"]
                        }, {
                            tdContent: ["polarization", "String", "/", "极化图如果img_type为url,则为文件的url的具体值；如果img_type为jpg,则为文件的base64编码字符串"]
                        }, {
                            tdContent: ["parallel", "String", "/", "平行偏振图（imgresult_type包含“19”时必须）,内容同上根据img_type字段确定"]
                        }, {
                            tdContent: ["uv", "String", "/", "UV图（imgresult_type包含“16”时必须）,内容同上根据img_type字段确定"]
                        }, {
                            tdContent: ["imgresult_type", "String", "否", "处理的维度 \n-1表示全部\n10.landmarks(人脸关键点)\n11.basic(脸型年龄等基础数据)\n12.brown(棕色图)\n13.pink(红色图)\n14.skin_complexion(肤色图)\n15.spots_contours(斑点图)\n16.uv(紫外黑白图)\n17.blackhead(黑头检测)\n18.pore(毛孔检测)\n19.wrinkle(皱纹图)\n20.ageing(老化图)\n多选用逗号分隔，例：10,12,13"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"],
                        }, {
                            tdContent: ["data", "List<AiItem>", "否", "检测报告(AiItem表)"]
                        }],
                        returnContents: [{
                            title: "AiItem(单项检测维度结果数据结构):",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["type", "Int", "/", "检测的维度编号id"]
                            }, {
                                tdContent: ["name", "String", "/", "检测的维度名称"]
                            }, {
                                tdContent: ["err_msg", "String", "/", "Ai处理出错时的消息，正常时为空"]
                            }, {
                                tdContent: ["img_path", "String", "/", "生成的图片的路径，该维度不需要生成图片时为空"]
                            }, {
                                tdContent: ["ai_text", "String", "/", "生成的数据，该维度不需要生成数据时为空，具体该维度内容对应如下"]
                            }]
                        }, {
                            title: "Landmarks",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["contour_chin", "List<int[]>", "/", "下巴，集合数组坐标点"]
                            }, {
                                tdContent: ["contour_left", "List<int[]>", "/", "左边脸部轮廓，集合数组坐标点"]
                            }, {
                                tdContent: ["contour_right", "List<int[]>", "/", "右边脸部轮廓，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eye_bottom", "List<int[]>", "/", "左边下眼睛，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eye_center", "List<int[]>", "/", "左边眼睛中心，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eye_pupil", "List<int[]>", "/", "左边眼睛瞳孔，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eyebrow_bottom", "List<int[]>", "/", "左边下眉毛，集合数组坐标点"]
                            }, {
                                tdContent: ["left_eyebrow_top", "List<int[]>", "/", "左边上眉毛，集合数组坐标点"]
                            }, {
                                tdContent: ["lower_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点，集合数组坐标点"]
                            }, {
                                tdContent: ["lower_lip_top", "List<int[]>", "/", "上嘴唇上关键点，集合数组坐标点"]
                            }, {
                                tdContent: ["nose_contour_bridge", "List<int[]>", "/", "鼻梁，集合数组坐标点"]
                            }, {
                                tdContent: ["nose_contour_left", "List<int[]>", "/", "鼻子左边轮廓，集合数组坐标点"]
                            }, {
                                tdContent: ["nose_contour_middle", "List<int[]>", "/", "鼻头中心点，集合数组坐标点"]
                            }, {
                                tdContent: ["nose_contour_right", "List<int[]>", "/", "鼻子右边轮廓，集合数组坐标点"]
                            }, {
                                tdContent: ["right_eye_bottom", "List<int[]>", "/", "右边下眼睛，集合数组坐标点"]
                            }, {
                                tdContent: ["right_eye_center", "List<int[]>", "/", "右边眼睛中心，集合数组坐标点"]
                            }, {
                                tdContent: ["right_eye_pupil", "List<int[]>", "/", "右边眼睛瞳孔，集合数组坐标点"]
                            }, {
                                tdContent: ["right_eye_top", "List<int[]>", "/", "右边上眼睛，集合数组坐标点"]
                            }, {
                                tdContent: ["right_eyebrow_bottom", "List<int[]>", "/", "右边下眉毛，68 关键点无此项"]
                            }, {
                                tdContent: ["right_eyebrow_top", "List<int[]>", "/", "右边上眉毛"]
                            }, {
                                tdContent: ["upper_lip_bottom", "List<int[]>", "/", "上嘴唇下关键点"]
                            }, {
                                tdContent: ["upper_lip_top", "List<int[]>", "/", "上嘴唇上关键点"]
                            }]
                        }, {
                            title: "Basic",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["ethnicity", "String", "/", "人种"]
                            }, {
                                tdContent: ["gender", "Int", "/", "性别：1,女2.男 -1未知"]
                            }, {
                                tdContent: ["head_pose", "object", "/", "人脸姿态\n pitch:沿x轴旋转的角度,\n yaw:沿y轴旋转的角度\n roll:沿z轴旋转的角度"]
                            }, {
                                tdContent: ["face_rectangle", "object", "/", "人脸区域,左上点坐标及宽高,top,left,width,height"]
                            }, {
                                tdContent: ["age", "Int", "/", "年龄"]
                            }]
                        }, {
                            title: "spots_contours",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["mask_area", "Int", "/", "Mask面积（像素个数）"]
                            }, {
                                tdContent: ["spots_area", "Int", "/", "斑点的面积（像素个数）"]
                            }, {
                                tdContent: ["spots_count", "Int", "/", "斑点的个数,即检测到脸上有多少个斑点"]
                            }]
                        }, {
                            title: "Blackhead",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["cords", "List<int[]> 示例[1804,1843,1]", "/", "黑头集合坐标点“x”,“y”及半径，对象是数字数组。"]
                            }, {
                                tdContent: ["count", "Int", "/", "黑头数量"]
                            }]
                        }, {
                            title: "pore",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["cords", "List<float[]> 示例[1691.1,1289.6,309]", "/", "毛孔中心坐标点“x”,“y”及“半径”"]
                            }, {
                                tdContent: ["pore_num", "Int", "/", "毛孔数量"]
                            }, {
                                tdContent: ["size_avg", "float", "/", "平均大小"]
                            }, {
                                tdContent: ["width", "Int", "/", ""]
                            }, {
                                tdContent: ["height", "Int", "/", ""]
                            }]
                        }, {
                            title: "wrinkle",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["nearLeyes", "List<int[]>,示例[29,5637,4342]", "/", '左眼下方区域皱纹检测结果：\n“皱纹数量”，“像素面积”，“像素面积占检测区域的百分比的1000倍”']
                            }, {
                                tdContent: ["nearReyes", "List<int[]>,示例[29,5637,4342]", "/", '右眼下方区域皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["forehead", "List<int[]>,示例[29,5637,4342]", "/", '抬头纹区域皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["crowsLeyes", "List<int[]>,示例[29,5637,4342]", "/", '左眼角区域皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["crowsReyes", "List<int[]>,示例[29,5637,4342]", "/", '右眼角区域皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["Lnasolabial", "List<int[]>,示例[29,5637,4342]", "/", '左脸侧法令纹皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["Rnasolabial", "List<int[]>,示例[29,5637,4342]", "/", '右脸侧法令纹皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["FrownLines", "List<int[]>,示例[29,5637,4342]", "/", '眉间纹（川字纹）皱纹检测结果：数值含义同上']
                            }, {
                                tdContent: ["sum_results", "List<int[]>,示例[29,5637,4342]", "/", '正脸或者左侧脸或右侧脸皱纹检测结果的和：\n “皱纹总数量”，“总像素面积”，“总像素面积占检测区域的百分比的1000倍”。']
                            }]
                        }],
                        pre: `成功：
{
    "data": [
        {
            "type": 10,
            "name": "landmarks",
            "err_msg": "",
            "img_path": "",
            "ai_text": "{
                \"nose_contour_right\":[[1133,1400],[1224,1753]],
                \"left_eye_top\":[[364,1446],[711,1412]],
                \"right_eyebrow_bottom\":[[1617,1110],[1184,1201]],
                \"left_eye_pupil\":[[558,1452]],
                \"left_eyebrow_bottom\":[[318,1190],[449,1167],[597,1178],[751,1229]],
                \"right_eye_top\":[[1611,1383],[1514,1400],[1423,1412],[1326,1406],[1235,1389]],
                \"lower_lip_bottom\":[[728,2420],[842,2494],[996,2522],[1167,2482],[1298,2402]],
                \"nose_contour_bridge\":[[968,1343],[973,1520],[979,1696],[985,1873]],
                \"lower_lip_top\":[[814,2346],[990,2357],[1195,2334]]
                \"right_eye_pupil\":[[1417,1412]],
                \"right_eyebrow_top\":[[1753,1104],[1634,990],[1463,973],[1298,1030],[1161,1116]],
                \"right_eye_bottom\":[[1520,1406],[1423,1417],[1326,1412]],
                \"contour_left\":[[102,1326],[96,1452],[96,1577],[102,1702],[119,1827],[142,1947],[745,2858],[871,2904]]
                \"nose_contour_left\":[[808,1417],[734,1770],[666,1936],[763,1987],[871,2010]],
                \"nose_contour_middle\":[[985,2038]],
                \"contour_chin\":[[1019,2915]],
                \"left_eye_center\":[[558,1452]],
                \"left_eyebrow_top\":[[204,1229],[279,1064],[438,1013],[614,1053],[768,1127]],
                \"right_eye_center\":[[1417,1412]],
                \"left_eye_bottom\":[[455,1463],[546,1463],[632,1446]],
                \"contour_right\":[[1901,1252],[1913,1383],[1924,1508],[1924,1634],[1303,2841],[1173,2892]]
                \"yaw\":-5.254570960998535},
                \"face_rectangle\":{\"top\":174,\"left\":11,\"width\":338,\"height\":338},
                \"age\":22
            }"
        },
        {
            "type": 12,
            "name": "brown",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmVJfn1xcP9syrWmAWS6Y8wTdQDB3568jMXKvee5JvoAuc/brown.jpg",
            "ai_text": ""
        },
        {
            "type": 13,
            "name": "pink",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmSkGpaXgEWYqCNMbtThHQ9MzR6Rzv1GbfAfEPczv8Ya2A/pink.jpg",
            "ai_text": ""
        },
        {
            "type": 14,
            "name": "skin_complexion",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmdPa9S1Ro9aJLoatcP8JL1a1tRRDL41b4ZHjVRYw1tZiM/skin_complexion.jpg",
            "ai_text": ""
        },
        {
            "type": 15,
            "name": "spots_contours",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmQihPvGihD7Q4jrcT3Dv4P22iURKKJtxHTEPHrjvzCjof/spots_contours.jpg",
            "ai_text": "{\"mask_area\":1817672,\"spots_area\":139844,\"spots_count\":74}"
        },
        {
            "type": 16,
            "name": "uv",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmVhUra8R1CYVQjBmrvsHprTJQkZnBE4HbKwgrMQAUx6x2/uv.jpg",
            "ai_text": ""
        },
        {
            "type": 17,
            "name": "blackhead",
            "err_msg": "",
            "img_path": "",
            "ai_text": "{
                \"cords\":[[1788,1786,1],[1841,1786,1],[1657,1784,1],[1875,1777,1],[1826,1776,1]],
                \"count\":5
            }"
        },
        {
            "type": 18,
            "name": "pore",
            "err_msg": "",
            "img_path": "",
            "ai_text": "{
                \"cords\":[[1463.0,826.8,7],[1476.4,844.0,11],[1478.6,819.6,9]],
                \"pore_num\":3,
                \"size_avg\":1357.9,
                \"width\":1993,
                \"height\":3027
            }"
        },
        {
            "type": 19,
            "name": "wrinkle",
            "err_msg": "",
            "img_path": "http://127.0.0.1:18000/developer/getImg/QmShcVaa4kryWeie3wPeW1zWySgvMoZqHWPQzRm1EreBS3/wrinkle_doneparallel.jpg",
            "ai_text": "{
                \"crowsReyes\":[0,0,0],
                \"Rnasolabial\":[0,0,0],
                \"nearLeyes\":[29,5637,4342],
                \"crowsLeyes\":[0,0,0],
                \"forehead\":[45,13855,2478],
                \"sum_results\":[101,26037,12040],
                \"nearReyes\":[25,5796,4876],
                \"FrownLines\":[2,749,344],
                \"Lnasolabial\":[0,0,0]
            }"
        },
        {
            "type": 20,
            "name": "ageing",
            "err_msg": "",
            "img_path": "http://192.168.1.36:18000/developer/getImg/Qme8y4ZjHG11QWTTTxWkftWaS9R4JcA5iDaDaRLDrQ8eDt/face_age_edit.jpg",
            "ai_text": ""
        }
    ],
    "code": 200,
    "message": "操作成功"
}
失败：
{
	"data": null,
	"message": "系统繁忙，请稍后再试",
	"code": "633"
}`,
                        otherMore: "只有完整并规范的三张检测图才能生成完整的检测数据。"
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#skinPresentationNewAddress"
                    }, {
                        navName: "请求参数",
                        href: "#skinPresentationNewRequest"
                    }, {
                        navName: "返回参数",
                        href: "#skinPresentationNewReturn"
                    }, {
                        navName: "数据格式",
                        href: "#skinPresentationNewExample"
                    }, {
                        navName: "其他说明",
                        href: "#skinPresentationNewOther"
                    }]
                }, {
                    navName: "AI分析接口",
                    show: !1,
                    text: {
                        title: "AI分析接口",
                        test: ["请求方式：post，请求地址：{ai_domain}/api/vivo/detect", "黑头，痘痘，黑眼圈，肤质，额头细纹，法令纹，毛孔，色斑，人脸检测九个维度"],
                        thContent: ["请求参数", "类型", "可否为空", "属性"],
                        testHref: "detectAPI",
                        addressTitle: "请求方式及地址",
                        requestTitle: "请求参数列表",
                        returnTitle: "返回参数列表",
                        exampleTitle: "数据格式 ",
                        otherTitle: "其他说明",
                        addressHref: "detectAddress",
                        requestHref: "detectRequest",
                        returnHref: "detectReturn",
                        exampleHref: "detectExample",
                        otherHref: "detectOther",
                        trContent: [{
                            tdContent: ["请求参数", "类型", "可否为空", "属性"],
                        }, {
                            tdContent: ["img_type", "String", "/", "上传文件类型:url表示上传文件url地址，jpg表示本地上传文件"]
                        }, {
                            tdContent: ["img_data", "String", "/", "如果img_type为url,则img_data为文件的url的具体值；如果img_type为jpg,则img_data为文件的base64编码字符串"]
                        }, {
                            tdContent: ["imgresult_type", "String", "/", "1表示检测黑头；\n2表示检测痘痘；\n3表示检测黑眼圈；\n4表示检测肤质；\n5表示检测额头细纹；\n6表示检测法令纹；\n7表示检测毛孔；\n8表示检测色斑；\n9表示人脸检测（年龄、颜值、人脸置信值）；\n  可以组合检测多维度，用','分割"]
                        }],
                        returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                        returnContent: [{
                            tdContent: ["返回参数", "类型", "可否为空", "属性"],
                        }, {
                            tdContent: ["black_head", "black_head", "/", "黑头（详见black_head表）"]
                        }, {
                            tdContent: ["dou_dou", "dou_dou", "/", "痘痘（详见dou_dou表）"]
                        }, {
                            tdContent: ["dark_circle", "dark_circle", "/", "黑眼圈（详见dark_circle表）"]
                        }, {
                            tdContent: ["skin_type", "skin_type", "/", "肤质（详见skin_typ表）"]
                        }, {
                            tdContent: ["wrinkle_thin", "wrinkle_thin", "/", "额头细纹（详见wrinkle_thin表）"]
                        }, {
                            tdContent: ["wrinkle_seg", "wrinkle_seg", "/", "法令纹（详见wrinkle_seg表）"]
                        },{
                            tdContent: ["pore", "pore", "/", "毛孔（详见pore表）"]
                        },{
                            tdContent: ["spots_contours", "spots_contours", "/", "色斑（详见spots_contours表）"]
                        },{
                            tdContent: ["avg_score", "int", "/", "人脸检测综合得分"]
                        },{
                            tdContent: ["face_detection", "face_detection", "/", "人脸检测（详见face_detection表）"]
                        },],
                        returnContents: [{
                            title: "黑头：black_head",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "string", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["num", "int", "/", "黑头个数"]
                            },{
                                tdContent: ["degree", "string", "/", "黑头程度，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+rectangularAreas", "array", "/", "数组图像信息"]
                            }, {
                                tdContent: ["++rectangularPoint", "Object", "/", "检测矩形区域坐标点信息"]
                            }, {
                                tdContent: ["+++x1", "double", "/", "矩形区域最小顶点横坐标"]
                            }, {
                                tdContent: ["+++y1", "double", "/", "矩形区域最小顶点纵坐标"]
                            }, {
                                tdContent: ["+++x2", "double", "/", "矩形区域最大顶点横坐标"]
                            }, {
                                tdContent: ["+++y2", "double", "/", "矩形区域最大顶点纵坐标"]
                            }]
                        }, {
                            title: "痘痘：dou_dou",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "array", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["num", "int", "/", "痘痘个数,num >=0"]
                            }, {
                                tdContent: ["degree", "string", "/", "痘痘程度，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+rectangularAreas", "array", "/", "数组图像信息"]
                            }, {
                                tdContent: ["++probability", "double", "/", "检测区域自信度"]
                            }, {
                                tdContent: ["++rectangularPoint", "Object", "/", "检测矩形区域坐标点信息"]
                            }, {
                                tdContent: ["+++x1", "double", "/", "矩形区域最小顶点横坐标"]
                            }, {
                                tdContent: ["+++y1", "double", "/", "矩形区域最小顶点纵坐标"]
                            }, {
                                tdContent: ["+++x2", "double", "/", "矩形区域最大顶点横坐标"]
                            }, {
                                tdContent: ["+++y2", "double", "/", "矩形区域最大顶点纵坐标"]
                            }]
                        }, {
                            title: "黑眼圈：dark_circle",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "array", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+rectangularAreas", "array", "/", "数组图像信息"]
                            }, {
                                tdContent: ["++classificationDesc", "string", "/", "黑眼圈分类描述，可选值：无；血管型； 色素型。"]
                            }, {
                                tdContent: ["++levelDesc", "string", "/", "黑眼圈严重程度描述，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["++advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["++rectangularPoint", "Object", "/", "检测矩形区域坐标点信息"]
                            }, {
                                tdContent: ["+++x1", "double", "/", "矩形区域最小顶点横坐标"]
                            }, {
                                tdContent: ["+++y1", "double", "/", "矩形区域最小顶点纵坐标"]
                            }, {
                                tdContent: ["+++x2", "double", "/", "矩形区域最大顶点横坐标"]
                            }, {
                                tdContent: ["+++y2", "double", "/", "矩形区域最大顶点纵坐标"]
                            }, {
                                tdContent: ["++classification", "string", "/", "黑眼圈分类，可选值：none,无；artery,血管型； pigment,色素型。"]
                            }, {
                                tdContent: ["++position", "string", "/", "黑眼圈所在方位，可选值：left，左侧；right，右侧。"]
                            }, {
                                tdContent: ["++level", "string", "/", "黑眼圈严重程度，可选值：none无，light,轻；medium，中；heavy，重。"]
                            }]
                        }, {
                            title: "肤质：skin_type",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "array", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["description", "string", "/", "指标说明"]
                            }, {
                                tdContent: ["oilyAdvise", "string", "/", "油性皮肤护理建议（没有油性部位，不返回）"]
                            }, {
                                tdContent: ["neutralAdvise", "string", "/", "中性皮肤护理建议（没有中性部位，不返回）"]
                            }, {
                                tdContent: ["dryAdvise", "string", "/", "干性皮肤护理建议（没有干性部位，不返回）"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+rectangularAreas", "string", "/", "区域肤质分类，可选值：oily，油性；neutral，中性；dry，干性；"]
                            }, {
                                tdContent: ["++classification", "Object", "/", "检测矩形区域坐标点信息"]
                            }, {
                                tdContent: ["++position", "string", "/", "检测部位，可选值：forehead,额头；nose,鼻子；left,左侧脸；right，右侧脸；chin，下巴。"]
                            }, {
                                tdContent: ["++degree", "double", "/", "肤质程度"]
                            }, {
                                tdContent: ["++rectangularPoint", "Object", "/", "检测矩形区域坐标点信息"]
                            }, {
                                tdContent: ["+++x1", "double", "/", "矩形区域最小顶点横坐标"]
                            }, {
                                tdContent: ["+++y1", "double", "/", "矩形区域最小顶点纵坐标"]
                            }, {
                                tdContent: ["+++x2", "double", "/", "矩形区域最大顶点横坐标"]
                            }, {
                                tdContent: ["+++y2", "double", "/", "矩形区域最大顶点纵坐标"]
                            }]
                        }, {
                            title: "额头细纹：wrinkle_thin:",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "string", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["degree", "string", "/", "额头细纹程度，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+commonLevel", "string", "/", "细纹整体程度，可选值：none,无；light,轻；medium，中；heavy，重。"]
                            }]
                        }, {
                            title: "法令纹：wrinkle_seg",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "string", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["degree", "string", "/", "法令纹程度，可选值：无，轻,中，重。（返回左右侧较重程度）"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["coordinate", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+rectangularAreas", "array", "/", "数组图像信息"]
                            }, {
                                tdContent: ["++postion", "string", "/", "检测脸部方位，可选值:left,左侧；right,右侧。"]
                            }, {
                                tdContent: ["++level", "string", "/", "法令纹检测程度，可选值：none,无；light,轻；medium，中；heavy，重。"]
                            }]
                        }, {
                            title: "毛孔：pore",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "string", "/", "生成效果图地址"]
                            }, {
                                tdContent: ["num", "int", "/", "毛孔个数"]
                            }, {
                                tdContent: ["degree", "string", "/", "毛孔程度，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["size_avg", "double", "/", "平均大小"]
                            }, {
                                tdContent: ["width", "int", "/", "宽度"]
                            }, {
                                tdContent: ["height", "int", "/", "高度"]
                            }, {
                                tdContent: ["cords", "Object", "/", "检测维度位置信息"]
                            }, {
                                tdContent: ["+x", "BigDecimal", "/", "毛孔中心横坐标"]
                            }, {
                                tdContent: ["+y", "BigDecimal", "/", "毛孔中心纵坐标"]
                            }, {
                                tdContent: ["+r", "int", "/", "毛孔半径"]
                            }]
                        }, {
                            title: "色斑：spots_contours",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["img_path", "string", "/", "生成效果图地址"]
							}, {
                                tdContent: ["degree", "string", "/", "色斑程度，可选值：无，轻,中，重。"]
                            }, {
                                tdContent: ["causes", "string", "/", "产生原因"]
                            }, {
                                tdContent: ["advise", "string", "/", "护理建议"]
                            }, {
                                tdContent: ["score", "string", "/", "得分"]
                            },  {
                                tdContent: ["area", "string", "/", "色斑所占面积"]
                            },  {
                                tdContent: ["mask_area", "int", "/", "Mask面积（像素个数）"]
                            }, {
                                tdContent: ["spots_area", "int", "/", "斑点的面积（像素个数）"]
                            }, {
                                tdContent: ["spots_count", "int", "/", "斑点的个数,即检测到脸上有多少个斑点"]
                            }]
                        }, {
                            title: "人脸检测：face_detection",
                            content: [{
                                tdContent: ["返回参数", "类型", "可否为空", "属性"],
                            }, {
                                tdContent: ["age", "int", "/", "年龄"]
                            }, {
                                tdContent: ["beauty", "double", "/", "颜值"]
                            }, {
                                tdContent: ["faceProbability", "int", "/", "人脸置信值，范围【0~1】，代表这是一张人脸的概率，0最小、1最大。"]
                            }]
                        }],
                        pre: `成功:
{
    "data": null,
    "code": 200,
    "message": "操作成功",
    "black_head": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmYieFK3k8gVXS63w848HU5uS3yRGkuFaVPRsgUAjGEG2C/black_head.jpg",
        "coordinate": {
            "rectangularAreas": [
                {
                    "rectangularPoint": {
                        "x1": 441,
                        "y1": 722,
                        "x2": 445,
                        "y2": 728
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 591,
                        "y1": 760,
                        "x2": 596,
                        "y2": 765
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 445,
                        "y1": 791,
                        "x2": 449,
                        "y2": 796
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 514,
                        "y1": 809,
                        "x2": 519,
                        "y2": 815
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 480,
                        "y1": 827,
                        "x2": 485,
                        "y2": 831
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 548,
                        "y1": 839,
                        "x2": 553,
                        "y2": 845
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 413,
                        "y1": 848,
                        "x2": 417,
                        "y2": 853
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 525,
                        "y1": 850,
                        "x2": 529,
                        "y2": 855
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 477,
                        "y1": 851,
                        "x2": 480,
                        "y2": 855
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 453,
                        "y1": 853,
                        "x2": 456,
                        "y2": 856
                    }
                },
                {
                    "rectangularPoint": {
                        "x1": 459,
                        "y1": 857,
                        "x2": 463,
                        "y2": 861
                    }
                }
            ]
        },
        "num": 11,
        "degree": "中度",
        "causes": "黑头全称为“黑头粉刺”，",
        "advise": "日常护理注意清洁皮肤+溶解角质+控油，",
        "score": 78
    },
    "dou_dou": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmakygLfQBgRm6hzw1DVtEM14LRyuE5c4oiK9jpwZP9qGu/dou_dou.jpg",
        "coordinate": {},
        "num": 0,
        "degree": "无",
        "causes": "1. 皮脂分泌过多",
        "advise": "没有检测到痘痘哦！请继续保持良好生活习惯，平时注意皮肤基础清洁，让你的脸保持清新状态。",
        "score": 100
    },
    "dark_circle": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmUTMn98bQ1aABXYZz2Vde7LexW4ZdzX7Y1Sa6hPVcQpLZ/dark_circle.jpg",
        "coordinate": {
            "rectangularAreas": [
                {
                    "classification": "pigment",
                    "level": "light",
                    "position": "left",
                    "rectangularPoint": {
                        "x1": 218,
                        "y1": 602,
                        "x2": 426,
                        "y2": 761
                    },
                    "classificationDesc": "色素型",
                    "levelDesc": "轻度",
                    "advise": "平时避免过度用眼，保证充足睡眠以减少眼疲劳。除了使用含视黄醇、咖啡因、维K成分的眼霜，还可采用冷敷"
                },
                {
                    "classification": "pigment",
                    "level": "light",
                    "position": "right",
                    "rectangularPoint": {
                        "x1": 560,
                        "y1": 601,
                        "x2": 779,
                        "y2": 761
                    },
                    "classificationDesc": "色素型",
                    "levelDesc": "轻度",
                    "advise": "平时避免过度用眼，保证充足睡眠以减少眼疲劳。除了使用含视黄醇、咖啡因、维K成分的眼霜，"
                }
            ]
        },
        "causes": "黑眼圈有三种：色素型，血管型、阴影型\n色素型黑眼圈：日晒、眼周皮肤病、皮肤损伤、化妆品沉积等黑色素过度沉积引起眼圈周围颜色加深。",
        "score": 80
    },
    "skin_type": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmcQmQhVkqtpJf2J8YXWwnRQXt1d8B3tYhSXHnUwMaeVVG/skin_type.jpg",
        "coordinate": {
            "rectangularAreas": [
                {
                    "classification": "oily",
                    "position": "forehead",
                    "degree": 30.1,
                    "rectangularPoint": {
                        "x1": 211,
                        "y1": 155,
                        "x2": 782,
                        "y2": 435
                    }
                },
                {
                    "classification": "oily",
                    "position": "nose",
                    "degree": 93.2,
                    "rectangularPoint": {
                        "x1": 382,
                        "y1": 626,
                        "x2": 612,
                        "y2": 919
                    }
                },
                {
                    "classification": "neutral",
                    "position": "left",
                    "degree": 56.1,
                    "rectangularPoint": {
                        "x1": 112,
                        "y1": 657,
                        "x2": 382,
                        "y2": 968
                    }
                },
                {
                    "classification": "oily",
                    "position": "right",
                    "degree": 27.4,
                    "rectangularPoint": {
                        "x1": 612,
                        "y1": 651,
                        "x2": 926,
                        "y2": 978
                    }
                },
                {
                    "classification": "neutral",
                    "position": "chin",
                    "degree": 92.9,
                    "rectangularPoint": {
                        "x1": 313,
                        "y1": 1113,
                        "x2": 713,
                        "y2": 1254
                    }
                }
            ]
        },
        "description": "干性皮肤是指皮脂腺分泌量少、皮肤角质层水分低于10％的肤质。",
        "oilyAdvise": "油皮的护肤重点在于在保湿的基础上做好控油工作。",
        "neutralAdvise": "春夏季节和秋冬季节分别用不同的护肤方法。",
        "dryAdvise": null
    },
    "wrinkle_thin": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmXwQ8bS6ckPa3DHYLaEJg6FzhcK1yqx6JBLDvgTw91PR1/wrinkle_thin.jpg",
        "coordinate": {
            "commonLevel": "none"
        },
        "degree": "无",
        "causes": "肌肤老化是产生皱纹的首要原因。随着年龄的增长，皮肤里的胶原蛋白、水分会渐渐流失，皮下脂肪也会萎缩下垂，皮肤弹性下降，",
        "advise": "皮肤营养供应充足，皮下组织丰满。每天做好肌肤的保湿、防晒、适当清洁即可。",
        "score": 100
    },
    "wrinkle_seg": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmUUd9KhTzcxcpHE2aLEEqFDLMZnTM9U3wpeGzLxz2rsGd/wrinkle_seg.jpg",
        "coordinate": {
            "rectangularAreas": [
                {
                    "level": "none",
                    "position": "left"
                },
                {
                    "level": "none",
                    "position": "right"
                }
            ]
        },
        "degree": "无",
        "causes": "肌肤老化是产生皱纹的首要原因。随着年龄的增长，皮肤里的胶原蛋白、水分会渐渐流失，",
        "advise": "皮肤营养供应充足，皮下组织丰满。每天做好肌肤的保湿、防晒、适当清洁即可。",
        "score": 100
    },
    "pore": {
        "img_path": "",
        "num": 136,
        "size_avg": 710.3,
        "width": 1030,
        "height": 1268,
        "cords": [
            {
                "x": 620.5,
                "y": 496.5,
                "r": 6
            },
            {
                "x": 726.2,
                "y": 564,
                "r": 129
            },
            {
                "x": 628.1,
                "y": 445,
                "r": 10
            },
            {
                "x": 631,
                "y": 421,
                "r": 6
            },
            {
                "x": 631.5,
                "y": 460.5,
                "r": 6
            },
            {
                "x": 644.5,
                "y": 542.5,
                "r": 7
            },
            {
                "x": 691.5,
                "y": 300.3,
                "r": 40
            },
            {
                "x": 671,
                "y": 375.5,
                "r": 6
            },
            {
                "x": 687.6,
                "y": 170.7,
                "r": 15
            },
            {
                "x": 688.6,
                "y": 223.2,
                "r": 12
            },
            {
                "x": 681.6,
                "y": 258,
                "r": 9
            },
            {
                "x": 695.9,
                "y": 847.7,
                "r": 13
            },
            {
                "x": 689.3,
                "y": 193.5,
                "r": 8
            },
            {
                "x": 710,
                "y": 363.7,
                "r": 13
            },
            {
                "x": 707.3,
                "y": 541.6,
                "r": 8
            },
            {
                "x": 712.7,
                "y": 153.2,
                "r": 10
            },
            {
                "x": 709,
                "y": 208.5,
                "r": 6
            },
            {
                "x": 716.5,
                "y": 191.5,
                "r": 9
            },
            {
                "x": 717.3,
                "y": 825.9,
                "r": 10
            },
            {
                "x": 722.2,
                "y": 865.1,
                "r": 10
            },
            {
                "x": 722.6,
                "y": 558,
                "r": 8
            },
            {
                "x": 731,
                "y": 212.3,
                "r": 8
            },
            {
                "x": 732,
                "y": 335.5,
                "r": 6
            },
            {
                "x": 735.5,
                "y": 539,
                "r": 6
            },
            {
                "x": 736.7,
                "y": 566.7,
                "r": 8
            },
            {
                "x": 738.5,
                "y": 196,
                "r": 6
            },
            {
                "x": 740.5,
                "y": 808.5,
                "r": 6
            },
            {
                "x": 756.1,
                "y": 857.2,
                "r": 17
            },
            {
                "x": 740.5,
                "y": 318.5,
                "r": 6
            },
            {
                "x": 745,
                "y": 756.8,
                "r": 10
            },
            {
                "x": 743.5,
                "y": 256.5,
                "r": 6
            },
            {
                "x": 744.5,
                "y": 820.5,
                "r": 6
            },
            {
                "x": 748,
                "y": 239,
                "r": 6
            },
            {
                "x": 747.5,
                "y": 299.5,
                "r": 6
            },
            {
                "x": 753.6,
                "y": 170.9,
                "r": 12
            },
            {
                "x": 751.5,
                "y": 283.5,
                "r": 6
            },
            {
                "x": 751.5,
                "y": 835.5,
                "r": 6
            },
            {
                "x": 765,
                "y": 204,
                "r": 14
            },
            {
                "x": 779,
                "y": 228.5,
                "r": 14
            },
            {
                "x": 767.5,
                "y": 823.5,
                "r": 6
            },
            {
                "x": 780.7,
                "y": 311.3,
                "r": 14
            },
            {
                "x": 773,
                "y": 812,
                "r": 6
            },
            {
                "x": 775.5,
                "y": 259.5,
                "r": 6
            },
            {
                "x": 780.2,
                "y": 170.8,
                "r": 7
            },
            {
                "x": 789.1,
                "y": 194.2,
                "r": 13
            },
            {
                "x": 781.5,
                "y": 778.5,
                "r": 6
            },
            {
                "x": 784.5,
                "y": 595,
                "r": 6
            },
            {
                "x": 808.5,
                "y": 830.7,
                "r": 24
            },
            {
                "x": 790.8,
                "y": 803.5,
                "r": 8
            },
            {
                "x": 793.8,
                "y": 577.6,
                "r": 10
            },
            {
                "x": 815.7,
                "y": 604.3,
                "r": 18
            },
            {
                "x": 817.5,
                "y": 239.5,
                "r": 18
            },
            {
                "x": 800.8,
                "y": 218.1,
                "r": 9
            },
            {
                "x": 800,
                "y": 782,
                "r": 8
            },
            {
                "x": 804,
                "y": 173.5,
                "r": 6
            },
            {
                "x": 819.5,
                "y": 278.9,
                "r": 13
            },
            {
                "x": 816.5,
                "y": 741.1,
                "r": 10
            },
            {
                "x": 810.5,
                "y": 194.5,
                "r": 6
            },
            {
                "x": 811,
                "y": 208.5,
                "r": 6
            },
            {
                "x": 817.1,
                "y": 639,
                "r": 12
            },
            {
                "x": 828,
                "y": 375.3,
                "r": 18
            },
            {
                "x": 825,
                "y": 699.1,
                "r": 9
            },
            {
                "x": 829.4,
                "y": 788.2,
                "r": 8
            },
            {
                "x": 832.8,
                "y": 454.2,
                "r": 9
            },
            {
                "x": 836.7,
                "y": 763,
                "r": 11
            },
            {
                "x": 833.1,
                "y": 808.5,
                "r": 6
            },
            {
                "x": 847.1,
                "y": 705.2,
                "r": 13
            },
            {
                "x": 852.3,
                "y": 499.9,
                "r": 13
            },
            {
                "x": 841.8,
                "y": 678.6,
                "r": 9
            },
            {
                "x": 851,
                "y": 474.5,
                "r": 11
            },
            {
                "x": 846.2,
                "y": 204.7,
                "r": 8
            },
            {
                "x": 850.8,
                "y": 258.8,
                "r": 12
            },
            {
                "x": 867.4,
                "y": 648.4,
                "r": 22
            },
            {
                "x": 849,
                "y": 800.1,
                "r": 11
            },
            {
                "x": 848,
                "y": 306,
                "r": 7
            },
            {
                "x": 847.5,
                "y": 332,
                "r": 6
            },
            {
                "x": 847.5,
                "y": 519,
                "r": 6
            },
            {
                "x": 849,
                "y": 183.5,
                "r": 6
            },
            {
                "x": 866.3,
                "y": 823,
                "r": 15
            },
            {
                "x": 851.5,
                "y": 490.5,
                "r": 6
            },
            {
                "x": 878,
                "y": 748.3,
                "r": 18
            },
            {
                "x": 862.4,
                "y": 727.8,
                "r": 10
            },
            {
                "x": 864.6,
                "y": 211.9,
                "r": 8
            },
            {
                "x": 862.4,
                "y": 287.6,
                "r": 8
            },
            {
                "x": 863,
                "y": 778.5,
                "r": 6
            },
            {
                "x": 863.5,
                "y": 324.5,
                "r": 6
            },
            {
                "x": 864.5,
                "y": 259.5,
                "r": 6
            },
            {
                "x": 874.5,
                "y": 799,
                "r": 6
            },
            {
                "x": 881.8,
                "y": 297.2,
                "r": 11
            },
            {
                "x": 883.4,
                "y": 221.2,
                "r": 10
            },
            {
                "x": 902.9,
                "y": 243.7,
                "r": 18
            },
            {
                "x": 884.5,
                "y": 712.5,
                "r": 6
            },
            {
                "x": 887.5,
                "y": 348.5,
                "r": 6
            },
            {
                "x": 887.5,
                "y": 633,
                "r": 6
            },
            {
                "x": 887.5,
                "y": 784.5,
                "r": 6
            },
            {
                "x": 887.5,
                "y": 800.5,
                "r": 6
            },
            {
                "x": 895,
                "y": 697.5,
                "r": 6
            },
            {
                "x": 895.5,
                "y": 679.5,
                "r": 6
            },
            {
                "x": 900.6,
                "y": 310.5,
                "r": 10
            },
            {
                "x": 900.2,
                "y": 278.3,
                "r": 10
            },
            {
                "x": 901.5,
                "y": 751.5,
                "r": 6
            },
            {
                "x": 903.5,
                "y": 648.5,
                "r": 6
            },
            {
                "x": 911.5,
                "y": 209,
                "r": 8
            },
            {
                "x": 920.5,
                "y": 783.7,
                "r": 19
            },
            {
                "x": 910.5,
                "y": 263.5,
                "r": 6
            },
            {
                "x": 921,
                "y": 708.6,
                "r": 16
            },
            {
                "x": 916,
                "y": 310.5,
                "r": 6
            },
            {
                "x": 940,
                "y": 319.2,
                "r": 16
            },
            {
                "x": 927.5,
                "y": 745,
                "r": 6
            },
            {
                "x": 931.1,
                "y": 296.1,
                "r": 10
            },
            {
                "x": 934.8,
                "y": 262.5,
                "r": 8
            },
            {
                "x": 932.9,
                "y": 655.1,
                "r": 8
            },
            {
                "x": 947.5,
                "y": 789.2,
                "r": 9
            },
            {
                "x": 946.5,
                "y": 241,
                "r": 6
            },
            {
                "x": 948.1,
                "y": 665.9,
                "r": 7
            },
            {
                "x": 948,
                "y": 757.5,
                "r": 6
            },
            {
                "x": 953.5,
                "y": 719.5,
                "r": 6
            },
            {
                "x": 956,
                "y": 288.5,
                "r": 6
            },
            {
                "x": 958.5,
                "y": 247.5,
                "r": 6
            },
            {
                "x": 962.5,
                "y": 332.5,
                "r": 7
            },
            {
                "x": 962.5,
                "y": 301,
                "r": 6
            },
            {
                "x": 965,
                "y": 777.5,
                "r": 6
            },
            {
                "x": 980.4,
                "y": 731.7,
                "r": 12
            },
            {
                "x": 970,
                "y": 750.5,
                "r": 7
            },
            {
                "x": 970,
                "y": 271.5,
                "r": 6
            },
            {
                "x": 973.5,
                "y": 697,
                "r": 6
            },
            {
                "x": 981.3,
                "y": 272.7,
                "r": 13
            },
            {
                "x": 977,
                "y": 314,
                "r": 7
            },
            {
                "x": 983.5,
                "y": 301.5,
                "r": 6
            },
            {
                "x": 985.5,
                "y": 712.5,
                "r": 6
            },
            {
                "x": 990.5,
                "y": 685.4,
                "r": 9
            },
            {
                "x": 1000.9,
                "y": 314.3,
                "r": 10
            },
            {
                "x": 1003.7,
                "y": 281.3,
                "r": 12
            },
            {
                "x": 1003.5,
                "y": 298.5,
                "r": 6
            },
            {
                "x": 1012.5,
                "y": 731.3,
                "r": 9
            },
            {
                "x": 1008.5,
                "y": 695.5,
                "r": 6
            }
        ],
        "degree": "中度",
        "causes": "毛孔粗大最常见的原因是皮质分泌过旺。让毛孔紧致的，",
        "advise": "坚持防晒+抗氧化，比如高浓度VC、白藜芦醇等，VC抗氧化并刺激胶原蛋白合成，",
        "score": 72
    },
    "spots_contours": {
        "img_path": "http://192.168.1.144:18000/developer/getImg/QmaAQ6gRzM91yqecTGtoX1jRMsn7WLUWpJNioAsgKSdjES/spots_contours.jpg",
        "mask_area": 354524,
        "spots_area": 490,
        "spots_count": 3,
        "degree": "无",
        "causes": "1、雀斑\n雀斑，比较常见的是分布在面中的一颗一颗密密麻麻的小小黄褐色斑点，",
        "advise": "肤色白皙匀净，没有瑕疵。每天做好肌肤的保湿、防晒、适当清洁即可。",
        "score": 100,
        "area": 0
    },
    "face_detection": {
        "age": 26,
        "beauty": 39.84,
        "faceProbability": 1
    }
}
失败：
{
    "data": null,
"message": "系统繁忙，请稍后再试",
"code": "633"
}`,
                        otherMore: "上传图片要求大小：建议在1MB - 4MB 之间；格式：分辨率在 600x 800 - 3000x4000 像素之间，jpg格式；"
                    },
                    navTwo: [{
                        navName: "请求方式及地址",
                        href: "#detectAddress"
                    }, {
                        navName: "请求参数",
                        href: "#detectRequest"
                    }, {
                        navName: "返回参数",
                        href: "#detectReturn"
                    }, {
                        navName: "数据格式",
                        href: "#detectExample"
                    }, {
                        navName: "其他说明",
                        href: "#detectOther"
                    }]
                }]
        },
        {
            navName: "设备管理",
            show: !1,
            href: "#deviceAPI",
            navOne: [{
                navName: "设备注册",
                show: !1,
                text: {
                    title: "设备注册",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/iotdevice/registerIotdevice"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "registerIotdeviceAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "registerIotdeviceAddress",
                    requestHref: "registerIotdeviceRequest",
                    returnHref: "registerIotdeviceReturn",
                    exampleHref: "registerIotdeviceExample",
                    otherHref: "registerIotdeviceOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号，同一开发者账号的同一设备类型下唯一", "32"]
                    }, {
                        tdContent: ["dname", "string", "否", "设备名称", "50"]
                    }, {
                        tdContent: ["dmac", "string", "否", "设备MAC地址", "64"]
                    }, {
                        tdContent: ["dip", "string", "/", "设备IP地址", "64"]
                    }, {
                        tdContent: ["dgps", "string", "否", "设备当前经纬度（经纬度以逗号隔开）", "20"]
                    }, {
                        tdContent: ["version", "string", "否", "版本号，必须先在firmware中插入版本", "12"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["iotdeviceId", "String", "否", "设备id"]
                    }],
                    pre: '成功：\n{\n    "data": {\n       "iotdeviceId":"1"\n    },\n    "code": 200,\n    "messages": "注册成功"\n}\n失败：\n{\n    "data": null,\n    "code": 400,\n    "messages": "此设备已存在"\n}',
                    otherMore: "version为当前设备的固件版本号，必须先在后台增加此版本号"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#registerIotdeviceAddress"
                }, {
                    navName: "请求参数",
                    href: "#registerIotdeviceRequest"
                }, {
                    navName: "返回参数",
                    href: "#registerIotdeviceReturn"
                }, {
                    navName: "数据格式",
                    href: "#registerIotdeviceExample"
                }, {
                    navName: "其他说明",
                    href: "#registerIotdeviceOther"
                }]
            }, {
                navName: "修改设备信息",
                show: !1,
                text: {
                    title: "修改设备信息",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/iotdevice/updateIotdevice"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "updateIotdeviceAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "updateIotdeviceAddress",
                    requestHref: "updateIotdeviceRequest",
                    returnHref: "updateIotdeviceReturn",
                    exampleHref: "updateIotdeviceExample",
                    otherHref: "updateIotdeviceOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号，同一开发者账号的同一设备类型下唯一", "32"]
                    }, {
                        tdContent: ["dname", "string", "/", "设备名称", "50"]
                    }, {
                        tdContent: ["dmac", "string", "/", "设备MAC地址", "64"]
                    }, {
                        tdContent: ["dip", "string", "/", "设备IP地址", "64"]
                    }, {
                        tdContent: ["dgps", "string", "/", "设备当前经纬度（经纬度以逗号隔开）", "20"]
                    }, {
                        tdContent: ["version", "string", "/", "版本号，必须先在firmware中插入版本", "12"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": null\n    "code": 200,\n    "messages": "修改成功"\n}\n失败：\n{\n    "data": null,\n    "code":" 400",\n    "messages": "该设备不存在"\n}',
                    otherMore: "除设备类型和设备编号外，其他信息需要的字段需要对应值，不需要修改的字段可不传或传空。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#updateIotdeviceAddress"
                }, {
                    navName: "请求参数",
                    href: "#updateIotdeviceRequest"
                }, {
                    navName: "返回参数",
                    href: "#updateIotdeviceReturn"
                }, {
                    navName: "数据格式",
                    href: "#updateIotdeviceExample"
                }, {
                    navName: "其他说明",
                    href: "#updateIotdeviceOther"
                }]
            }, {
                navName: "获取设备信息",
                show: !1,
                text: {
                    title: "获取设备信息",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/iotdevice/getIotdevice"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "getIotdeviceAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "getIotdeviceAddress",
                    requestHref: "getIotdeviceRequest",
                    returnHref: "getIotdeviceReturn",
                    exampleHref: "getIotdeviceExample",
                    otherHref: "getIotdeviceOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型"]
                    }, {
                        tdContent: ["dtypename", "string", "否", "设备类型名称"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号"]
                    }, {
                        tdContent: ["dname", "string", "/", "设备名称"]
                    }, {
                        tdContent: ["dmac", "string", "/", "设备MAC地址"]
                    }, {
                        tdContent: ["dip", "string", "/", "设备IP地址"]
                    }, {
                        tdContent: ["dgps", "string", "/", "设备当前经纬度（经纬度以逗号隔开）"]
                    }, {
                        tdContent: ["version", "string", "/", "版本号"]
                    }],
                    pre: `成功：
{
    "data": {
        "devid": 69,
        "createtime": "16:43:53",
        "dmac": "00-01-6C-06-A6-30",
        "dnumber": "shebeibianhao9",
        "dtypeid": 5,
        "createdate": "2020-04-29",
        "dgps": "22.525292,113.922391",
        "endtime": null,
        "dtype": "HBR2-W",
        "dip": "192.168.1.80",
        "version": null,
        "verid": 17,
        "accountid": null,
        "times": null,
        "enddate": null,
        "statuz": -1,
        "accountname": null,
        "isiot": -1,
        "id": 1025495,
        "dname": "猫咪9号1",
        "isdel": 0,
        "dtypename": "射频仪"
    },
    "code": 200,
    "message": "操作成功"
}
失败：
{
	"data": null,
	"message": "该设备不存在",
	"code": "400"
}`,
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#getIotdeviceAddress"
                }, {
                    navName: "请求参数",
                    href: "#getIotdeviceRequest"
                }, {
                    navName: "返回参数",
                    href: "#getIotdeviceReturn"
                }, {
                    navName: "数据格式",
                    href: "#getIotdeviceExample"
                }, {
                    navName: "其他说明",
                    href: "#getIotdeviceOther"
                }]
            }, {
                navName: "获取设备类型信息",
                show: !1,
                text: {
                    title: "获取设备类型信息",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/iotdeviceType/getIotdeviceType"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "getIotdeviceTypeAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "getIotdeviceTypeAddress",
                    requestHref: "getIotdeviceTypeRequest",
                    returnHref: "getIotdeviceTypeReturn",
                    exampleHref: "getIotdeviceTypeExample",
                    otherHref: "getIotdeviceTypeOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型"]
                    }, {
                        tdContent: ["dtypename", "string", "否", "设备类型名称"]
                    }, {
                        tdContent: ["createdate", "string", "否", "创建日期"]
                    }, {
                        tdContent: ["createtime", "string", "否", "创建时间"]
                    }],
                    pre: `成功：
{
    "data": {
        "id": 5,
        "devid": 69,
        "dtype": "HBR2-W",
        "dtypename": "射频仪",
        "createdate": "2020-04-28",
        "createtime": "14:21:43",
        "isdel": 0
    },
    "code": 200,
    "message": "操作成功"
}
失败：
{
    "data": null,
    "code":" 433",
    "messages": "该设备类型不存在"
}
`,
                    otherMore: "除设备类型和设备编号外，其他信息需要的字段需要对应值，不需要修改的字段可不传或传空。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#getIotdeviceTypeAddress"
                }, {
                    navName: "请求参数",
                    href: "#getIotdeviceTypeRequest"
                }, {
                    navName: "返回参数",
                    href: "#getIotdeviceTypeReturn"
                }, {
                    navName: "数据格式",
                    href: "#getIotdeviceTypeExample"
                }, {
                    navName: "其他说明",
                    href: "#getIotdeviceTypeOther"
                }]
            }, {
                navName: "设备授权",
                show: !1,
                text: {
                    title: "设备授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/addDeviceAccount"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addDeviceAccountAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addDeviceAccountAddress",
                    requestHref: "addDeviceAccountRequest",
                    returnHref: "addDeviceAccountReturn",
                    exampleHref: "addDeviceAccountExample",
                    otherHref: "addDeviceAccountOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }, {
                        tdContent: ["accountname", "string", "否", "设备绑定账号名称", "64"]
                    }, {
                        tdContent: ["isiot", "int", "否", "0全款，永久使用，1租赁有效期，2 租赁次数， 3 设备类型租赁次数", "限制0/1/2/3三种值"]
                    }, {
                        tdContent: ["enddate", "datetime", "/", "根据此日期时间判断设备租赁期限（isiot为1时必填）格式：yyyy-MM-dd HH:mm:ss", "20"]
                    }, {
                        tdContent: ["times", "int", "/", "根据此次数判断设备租赁次数（isiot为2时必填）", "11（最小值为1）"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": { \n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":405,\n    "messages": "设备之前绑定的账号还在有效期，请先修改"\n}',
                    otherMore: "accountid为设备归属人账号，endDate 采用日期+时间形式传值，例如：2019-04-23 10:00:00，如设备之前绑定的账号授权权在有效期，则提示开发者先修改之前账号的授权有效期"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addDeviceAccountAddress"
                }, {
                    navName: "请求参数",
                    href: "#addDeviceAccountRequest"
                }, {
                    navName: "返回参数",
                    href: "#addDeviceAccountReturn"
                }, {
                    navName: "数据格式",
                    href: "#addDeviceAccountExample"
                }, {
                    navName: "其他说明",
                    href: "#addDeviceAccountOther"
                }]
            }, {
                navName: "解除设备授权",
                show: !1,
                text: {
                    title: "解除设备授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/cancleDeviceAccount"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "cancleDeviceAccountAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "cancleDeviceAccountAddress",
                    requestHref: "cancleDeviceAccountRequest",
                    returnHref: "cancleDeviceAccountReturn",
                    exampleHref: "cancleDeviceAccountExample",
                    otherHref: "cancleDeviceAccountOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":406,\n    "messages": "设备还未绑定此账号"\n}',
                    otherMore: "解除设备账号相当于把当前这个账号的授权信息置为失效"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#cancleDeviceAccountAddress"
                }, {
                    navName: "请求参数",
                    href: "#cancleDeviceAccountRequest"
                }, {
                    navName: "返回参数",
                    href: "#cancleDeviceAccountReturn"
                }, {
                    navName: "数据格式",
                    href: "#cancleDeviceAccountExample"
                }, {
                    navName: "其他说明",
                    href: "#cancleDeviceAccountOther"
                }]
            }, {
                navName: "修改设备授权",
                show: !1,
                text: {
                    title: "修改设备授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/updateDeviceAccount"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "updateDeviceAccountAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "updateDeviceAccountAddress",
                    requestHref: "updateDeviceAccountRequest",
                    returnHref: "updateDeviceAccountReturn",
                    exampleHref: "updateDeviceAccountExample",
                    otherHref: "updateDeviceAccountOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }, {
                        tdContent: ["accountname", "string", "/", "设备绑定账号名称", "64"]
                    }, {
                        tdContent: ["isiot", "int", "否", "0全款，永久使用，1租赁有效期，2 租赁次数， 3 设备类型租赁次数", "限制0/1/2/3三种值"]
                    }, {
                        tdContent: ["enddate", "datetime", "/", "根据此日期时间判断设备租赁期限（isiot为1时必填）格式：yyyy-MM-dd HH:mm:ss", "20"]
                    }, {
                        tdContent: ["times", "int", "/", "根据此次数判断设备租赁次数（isiot为2时必填）", "11（最小值为1）"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":406,\n    "messages": "设备还未绑定此账号"\n}',
                    otherMore: "根据当前设备绑定的账号修改授权信息"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#updateDeviceAccountAddress"
                }, {
                    navName: "请求参数",
                    href: "#updateDeviceAccountRequest"
                }, {
                    navName: "返回参数",
                    href: "#updateDeviceAccountReturn"
                }, {
                    navName: "数据格式",
                    href: "#updateDeviceAccountExample"
                }, {
                    navName: "其他说明",
                    href: "#updateDeviceAccountOther"
                }]
            }, {
                navName: "检查设备是否授权",
                show: !1,
                text: {
                    title: "检查设备是否授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/checkDeviceBound"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "checkDeviceBoundAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "checkDeviceBoundAddress",
                    requestHref: "checkDeviceBoundRequest",
                    returnHref: "checkDeviceBoundReturn",
                    exampleHref: "checkDeviceBoundExample",
                    otherHref: "checkDeviceBoundOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: `成功：
{
    "data": null,
    "code": 200,
    "message": "操作成功"
}
失败:
{
    "data": null,
    "code": 409,
    "message": "设备之前绑定的账号还在有效期，请先修改。"
}`,
                    otherMore: "根据当前设备绑定的账号验证账号的最新授权信息是否有效"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#checkDeviceBoundAddress"
                }, {
                    navName: "请求参数",
                    href: "#checkDeviceBoundRequest"
                }, {
                    navName: "返回参数",
                    href: "#checkDeviceBoundReturn"
                }, {
                    navName: "数据格式",
                    href: "#checkDeviceBoundExample"
                }, {
                    navName: "其他说明",
                    href: "#checkDeviceBoundOther"
                }]
            }, {
                navName: "设备鉴权（验证设备授权）",
                show: !1,
                text: {
                    title: "设备鉴权（验证设备授权）",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/checkDeviceAccount"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "checkDeviceAccountAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "checkDeviceAccountAddress",
                    requestHref: "checkDeviceAccountRequest",
                    returnHref: "checkDeviceAccountReturn",
                    exampleHref: "checkDeviceAccountExample",
                    otherHref: "checkDeviceAccountOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["id", "int", "否", "授权id"]
                    }, {
                        tdContent: ["isiot", "int", "否", "0全款，永久使用，1租赁有效期，2 租赁次数， 3 设备类型租赁次数"]
                    }, {
                        tdContent: ["enddate", "datetime", "/", "格式：yyyy-MM-dd"]
                    }, {
                        tdContent: ["endtime", "datetime", "/", "格式：HH:mm:ss"]
                    }, {
                        tdContent: ["times", "int", "/", "根据此次数判断设备租赁次数（isiot为2时必填）"]
                    }, {
                        tdContent: ["statuz", "int", "/", "0有效 1失效"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号"]
                    }, {
                        tdContent: ["accountname", "string", "否", "设备绑定账号名称"]
                    }],
                    pre: '成功：\n{\n    "data": {\n        "id": 1008908,\n        "isiot": 1,\n        "enddate": "2020-05-06",\n        "endtime": "16:00:01",\n        "statuz": 0,\n        "accountid": "mm001",\n        "accountname": "maomikehu1",\n        "times": 2,\n     },\n    "code": 200,\n    "message": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":407,\n    "messages": "设备未绑定此账号或此账号已到期"\n}',
                    otherMore: "根据当前设备绑定的账号验证账号的最新授权信息是否有效"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#checkDeviceAccountAddress"
                }, {
                    navName: "请求参数",
                    href: "#checkDeviceAccountRequest"
                }, {
                    navName: "返回参数",
                    href: "#checkDeviceAccountReturn"
                }, {
                    navName: "数据格式",
                    href: "#checkDeviceAccountExample"
                }, {
                    navName: "其他说明",
                    href: "#checkDeviceAccountOther"
                }]
            }, {
                navName: "设备类型授权",
                show: !1,
                text: {
                    title: "设备类型授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/addDevicetypeauth"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addDevicetypeauthAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addDevicetypeauthAddress",
                    requestHref: "addDevicetypeauthRequest",
                    returnHref: "addDevicetypeauthReturn",
                    exampleHref: "addDevicetypeauthExample",
                    otherHref: "addDevicetypeauthOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }, {
                        tdContent: ["accountname", "string", "否", "设备绑定账号名称", "64"]
                    }, {
                        tdContent: ["enddate", "datetime", "/", "根据此日期时间判断设备租赁期限（isiot为1时必填）格式：yyyy-MM-dd HH:mm:ss", "20"]
                    }, {
                        tdContent: ["times", "int", "/", "根据此次数判断设备租赁次数（isiot为2时必填）", "11（最小值为1）"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": { \n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":405,\n    "messages": "设备不存在"\n}',
                    otherMore: "accounidt为设备类型归属人账号，endDate 采用日期+时间形式传值，例如：2019-04-23 10:00:00，如设备之前已有此账号类型的次数，鉴权有效，则提示开发者先修改之前记录置为失效"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addDevicetypeauthAddress"
                }, {
                    navName: "请求参数",
                    href: "#addDevicetypeauthRequest"
                }, {
                    navName: "返回参数",
                    href: "#addDevicetypeauthReturn"
                }, {
                    navName: "数据格式",
                    href: "#addDevicetypeauthExample"
                }, {
                    navName: "其他说明",
                    href: "#addDevicetypeauthOther"
                }]
            }, {
                navName: "修改设备类型授权",
                show: !1,
                text: {
                    title: "修改设备类型授权",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/deviceauth/updateDevicetypeauth"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "updateDevicetypeauthAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "updateDevicetypeauthAddress",
                    requestHref: "updateDevicetypeauthRequest",
                    returnHref: "updateDevicetypeauthReturn",
                    exampleHref: "updateDevicetypeauthExample",
                    otherHref: "updateDevicetypeauthOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["accountid", "string", "否", "设备绑定账号名称", "50"]
                    }, {
                        tdContent: ["accountname", "string", "否", "设备绑定账号名称", "64"]
                    }, {
                        tdContent: ["enddate", "datetime", "/", "根据此日期时间判断设备租赁期限（isiot为1时必填）格式：yyyy-MM-dd HH:mm:ss", "20"]
                    }, {
                        tdContent: ["times", "int", "/", "根据此次数判断设备租赁次数（isiot为2时必填）", "11（最小值为1）"]
                    }, {
                        tdContent: ["statuz", "int", "/", "0 失效 1有效", "0/1选值"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": { \n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":405,\n    "messages": "设备不存在"\n}',
                    otherMore: "accountid为设备类型归属人账号，enddate 采用日期+时间形式传值，例如：2019-04-23 10:00:00，只修改最新的数据记录。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#updateDevicetypeauthAddress"
                }, {
                    navName: "请求参数",
                    href: "#updateDevicetypeauthRequest"
                }, {
                    navName: "返回参数",
                    href: "#updateDevicetypeauthReturn"
                }, {
                    navName: "数据格式",
                    href: "#updateDevicetypeauthExample"
                }, {
                    navName: "其他说明",
                    href: "#updateDevicetypeauthOther"
                }]
            }, {
                navName: "获取最新固件版本",
                show: !1,
                text: {
                    title: "获取最新固件版本",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/firmware/getFirmware"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "getFirmwareAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "getFirmwareAddress",
                    requestHref: "getFirmwareRequest",
                    returnHref: "getFirmwareReturn",
                    exampleHref: "getFirmwareExample",
                    otherHref: "getFirmwareOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }, {
                        tdContent: ["version", "string", "否", "最新固件版本"]
                    }, {
                        tdContent: ["fileurl", "string", "否", "固件下载地址"]
                    }, {
                        tdContent: ["ismust", "int", "否", "是否必须更新(0否 1是)"]
                    }, {
                        tdContent: ["createtime", "string", "否", "创建时间 格式：yyyy-MM-dd HH:mm:ss"]
                    }],
                    pre: '成功：\n{\n    "data": {\n\t"version":"3.1.1",\n\t"fileurl":"http://www.dk.com/3.1.1.apk/",\n\t"ismust":1,\n\t"createtime":"2019-04-24",\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":200,\n    "messages": "获取成功"\n}',
                    otherMore: "ismust为0时，允许设备不更新系统继续使用，ismust为1时，设备必须强制更新。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#getFirmwareAddress"
                }, {
                    navName: "请求参数",
                    href: "#getFirmwareRequest"
                }, {
                    navName: "返回参数",
                    href: "#getFirmwareReturn"
                }, {
                    navName: "数据格式",
                    href: "#getFirmwareExample"
                }, {
                    navName: "其他说明",
                    href: "#getFirmwareOther"
                }]
            }, {
                navName: "设备上传日志接口（文件url地址）",
                show: !1,
                text: {
                    title: "设备上传日志接口（文件url地址）",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/devicerecord/addDevicerecordUrl"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addDevicerecordUrlAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addDevicerecordUrlAddress",
                    requestHref: "addDevicerecordUrlRequest",
                    returnHref: "addDevicerecordUrlReturn",
                    exampleHref: "addDevicerecordUrlExample",
                    otherHref: "addDevicerecordUrlOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["recorddata", "string", "否", "日志内容", "500"]
                    }, {
                        tdContent: ["dgps", "string", "/", "地理位置信息（经纬度以逗号隔开）", "20"]
                    }, {
                        tdContent: ["dip", "string", "否", "IP地址", "64"]
                    }, {
                        tdContent: ["urls", "String[]", "否", "数组形式，可添加多张图片地址", ""]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":402,\n    "messages": "更新日志失败"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addDevicerecordUrlAddress"
                }, {
                    navName: "请求参数",
                    href: "#addDevicerecordUrlRequest"
                }, {
                    navName: "返回参数",
                    href: "#addDevicerecordUrlReturn"
                }, {
                    navName: "数据格式",
                    href: "#addDevicerecordUrlExample"
                }, {
                    navName: "其他说明",
                    href: "#addDevicerecordUrlOther"
                }]
            }, {
                navName: "设备上传状态接口",
                show: !1,
                text: {
                    title: "设备上传状态接口",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/devicestatus/uploadStatus"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "uploadStatusAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "uploadStatusAddress",
                    requestHref: "uploadStatusRequest",
                    returnHref: "uploadStatusReturn",
                    exampleHref: "uploadStatusExample",
                    otherHref: "uploadStatusOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["isonline", "int", "否", "是否在线", "0 在线，1 离线"]
                    }, {
                        tdContent: ["createdate", "string", "否", "创建时间", "格式：yyyy-MM-dd hh:mmm:ss"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":402,\n    "messages": "上传失败"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#uploadStatusAddress"
                }, {
                    navName: "请求参数",
                    href: "#uploadStatusRequest"
                }, {
                    navName: "返回参数",
                    href: "#uploadStatusReturn"
                }, {
                    navName: "数据格式",
                    href: "#uploadStatusExample"
                }, {
                    navName: "其他说明",
                    href: "#uploadStatusOther"
                }]
            }, {
                navName: "设备保存文件记录接口",
                show: !1,
                text: {
                    title: "设备保存文件记录接口",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/devicestorage/addDevicestorageUrl"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addDevicestorageUrlAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addDevicestorageUrlAddress",
                    requestHref: "addDevicestorageUrlRequest",
                    returnHref: "addDevicestorageUrlReturn",
                    exampleHref: "addDevicestorageUrlExample",
                    otherHref: "addDevicestorageUrlOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["urls", "String[]", "否", "数组形式，可添加多张图片地址", ""]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":402,\n    "messages": "文件上传失败"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addDevicestorageUrlAddress"
                }, {
                    navName: "请求参数",
                    href: "#addDevicestorageUrlRequest"
                }, {
                    navName: "返回参数",
                    href: "#addDevicestorageUrlReturn"
                }, {
                    navName: "数据格式",
                    href: "#addDevicestorageUrlExample"
                }, {
                    navName: "其他说明",
                    href: "#addDevicestorageUrlOther"
                }]
            }]
        },
        {
            navName: "前置校验管理",
            show: !1,
            href: "#addPrepareruleAPI",
            navOne: [{
                navName: "校验规则录入",
                show: !1,
                text: {
                    title: "校验规则录入",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/preparerule/addPreparerule"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addPrepareruleAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addPrepareruleAddress",
                    requestHref: "addPrepareruleRequest",
                    returnHref: "addPrepareruleReturn",
                    exampleHref: "addPrepareruleExample",
                    otherHref: "addPrepareruleOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", "dnumber有值时，dtype必传，根据这两个字段确定设备"]
                    }, {
                        tdContent: ["rulename", "String", "否", "规则名称", "20"]
                    }, {
                        tdContent: ["ruleType", "int", "否", "规则类型\n    0按订单可用设备校验，\n    1按订单时间校验，\n    2按订单次数校验，\n    3按订单可用设备类型校验，\n    4按设备授权次数校验", "20"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":401,\n    "messages": "录入失败"\n}',
                    otherMore: "规则类型目前只支持这五种方式，0按订单可用设备校验，1按订单时间校验，2按订单次数校验，3按订单可用设备类型校验，4按设备授权次数校验。规则可以设置给设备类型，也可以设置给具体设备，具体设备时需传dtype和dnumber"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addPrepareruleAddress"
                }, {
                    navName: "请求参数",
                    href: "#addPrepareruleRequest"
                }, {
                    navName: "返回参数",
                    href: "#addPrepareruleReturn"
                }, {
                    navName: "数据格式",
                    href: "#addPrepareruleExample"
                }, {
                    navName: "其他说明",
                    href: "#addPrepareruleOther"
                }]
            }, {
                navName: "校验源数据录入",
                show: !1,
                text: {
                    title: "校验源数据录入",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/preparedata/addPreparedata"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "addPreparedataAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "addPreparedataAddress",
                    requestHref: "addPreparedataRequest",
                    returnHref: "addPreparedataReturn",
                    exampleHref: "addPreparedataExample",
                    otherHref: "addPreparedataOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["validatedata", "String", "否", "数据唯一标识（比如订单号）", "100"]
                    }, {
                        tdContent: ["dnumber", "string", "/", "设备编号", ""]
                    }, {
                        tdContent: ["endDate", "datetime", "/", "结束时间,格式：yyyy-MM-dd HH:mm:ss", "20"]
                    }, {
                        tdContent: ["dtype", "string", "/", "设备类型", "32"]
                    }, {
                        tdContent: ["limitNum", "int", "/", "剩余次数", "大于0"]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":401,\n    "messages": "录入失败"\n}',
                    otherMore: "dtype，endDate，limitnum三者不可同时为空，dnumber和dtype必须同时有值.请求参数以数组形式传递，并放入requestBody中。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#addPreparedataAddress"
                }, {
                    navName: "请求参数",
                    href: "#addPreparedataRequest"
                }, {
                    navName: "返回参数",
                    href: "#addPreparedataReturn"
                }, {
                    navName: "数据格式",
                    href: "#addPreparedataExample"
                }, {
                    navName: "其他说明",
                    href: "#addPreparedataOther"
                }]
            }, {
                navName: "前置校验",
                show: !1,
                text: {
                    title: "前置校验",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/preparerule/checkPrepare"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "checkPrepareAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "checkPrepareAddress",
                    requestHref: "checkPrepareRequest",
                    returnHref: "checkPrepareReturn",
                    exampleHref: "checkPrepareExample",
                    otherHref: "checkPrepareOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["validatedata", "String", "否", "数据唯一标识（比如订单号）", "100"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["usernum", "int", "/", "使用次数（默认为1，如果传值小于0，则默认修改为1）", "大于0"]
                    }, {
                        tdContent: ["accountid", "int", "否", "鉴权账户id", ""]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":408,\n    "messages": "未查到此订单"\n}\n{\n    "data": null,\n    "code":409,\n    "messages": "此订单已过期或已使用完"\n}',
                    otherMore: "如果校验通过，数据库会保存一条该validateData的校验记录，不通过，则不会记录。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#checkPrepareAddress"
                }, {
                    navName: "请求参数",
                    href: "#checkPrepareRequest"
                }, {
                    navName: "返回参数",
                    href: "#checkPrepareReturn"
                }, {
                    navName: "数据格式",
                    href: "#checkPrepareExample"
                }, {
                    navName: "其他说明",
                    href: "#checkPrepareOther"
                }]
            }, {
                navName: "前置校验并锁定",
                show: !1,
                text: {
                    title: "前置校验并锁定",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/preparevalidate/lockDeviceOrPreparedata"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "lockDeviceOrPreparedataAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "lockDeviceOrPreparedataAddress",
                    requestHref: "lockDeviceOrPreparedataRequest",
                    returnHref: "lockDeviceOrPreparedataReturn",
                    exampleHref: "lockDeviceOrPreparedataExample",
                    otherHref: "lockDeviceOrPreparedataOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["validatedata", "String", "否", "数据唯一标识（比如订单号）", "100"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["usernum", "int", "/", "使用次数（默认为1，如果传值小于0，则默认修改为1）", "大于0"]
                    }, {
                        tdContent: ["accountid", "int", "否", "鉴权账户id", ""]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":408,\n    "messages": "未查到此订单"\n}\n{\n    "data": null,\n    "code":409,\n    "messages": "此订单已过期或已使用完"\n}',
                    otherMore: "如果校验通过，数据库会保存一条该validateData的校验记录，并且锁定设备和订单，不通过，则不会记录。"
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#lockDeviceOrPreparedataAddress"
                }, {
                    navName: "请求参数",
                    href: "#lockDeviceOrPreparedataRequest"
                }, {
                    navName: "返回参数",
                    href: "#lockDeviceOrPreparedataReturn"
                }, {
                    navName: "数据格式",
                    href: "#lockDeviceOrPreparedataExample"
                }, {
                    navName: "其他说明",
                    href: "#lockDeviceOrPreparedataOther"
                }]
            }, {
                navName: "解锁并扣除次数",
                show: !1,
                text: {
                    title: "解锁并扣除次数",
                    test: ["请求方式：post，请求地址：{iot_domain}/api/preparevalidate/unlockPredataAndCutTimes"],
                    thContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"],
                    testHref: "unlockPredataAndCutTimesAPI",
                    addressTitle: "请求方式及地址",
                    requestTitle: "请求参数列表",
                    returnTitle: "返回参数列表",
                    exampleTitle: "数据格式 ",
                    otherTitle: "其他说明",
                    addressHref: "unlockPredataAndCutTimesAddress",
                    requestHref: "unlockPredataAndCutTimesRequest",
                    returnHref: "unlockPredataAndCutTimesReturn",
                    exampleHref: "unlockPredataAndCutTimesExample",
                    otherHref: "unlockPredataAndCutTimesOther",
                    trContent: [{
                        tdContent: ["请求参数", "类型", "可否为空", "属性", "限制条件"]
                    }, {
                        tdContent: ["validatedata", "String", "否", "数据唯一标识（比如订单号）", "100"]
                    }, {
                        tdContent: ["dnumber", "string", "否", "设备编号", ""]
                    }, {
                        tdContent: ["dtype", "string", "否", "设备类型", "32"]
                    }, {
                        tdContent: ["usernum", "int", "/", "使用次数（默认为1，如果传值小于0，则默认修改为1）", "大于0"]
                    }, {
                        tdContent: ["accountid", "int", "否", "鉴权账户id", ""]
                    }],
                    returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                    returnContent: [{
                        tdContent: ["返回参数", "类型", "可否为空", "属性"]
                    }],
                    pre: '成功：\n{\n    "data": {\n    },\n    "code":200,\n    "messages": "操作成功"\n}\n失败：\n{\n    "data": null,\n    "code":408,\n    "messages": "未查到此订单"\n}',
                    otherMore: ""
                },
                navTwo: [{
                    navName: "请求方式及地址",
                    href: "#unlockPredataAndCutTimesAddress"
                }, {
                    navName: "请求参数",
                    href: "#unlockPredataAndCutTimesRequest"
                }, {
                    navName: "返回参数",
                    href: "#unlockPredataAndCutTimesReturn"
                }, {
                    navName: "数据格式",
                    href: "#unlockPredataAndCutTimesExample"
                }, {
                    navName: "其他说明",
                    href: "#unlockPredataAndCutTimesOther"
                }]
            }]
        },
        {
            navName: "推送消息监听",
            show: !1,
            href: "#",
            text: {
                title: "推送消息监听",
                test: ["使用mqtt协议，通过监听topic，获取服务器的推送消息", "地址：{iot_domain}:{topicport}", "账户 : 你的appid", " 密码 你的appsecret"],
                thContent: ["Topic名称", "属性"],
                testHref: "messageAPI",
                addressTitle: "监听地址以及账户",
                requestTitle: "需要监听的topic列表",
                returnTitle: "返回参数列表",
                exampleTitle: "数据格式 ",
                otherTitle: "其他说明",
                addressHref: "messageAddress",
                requestHref: "messageRequest",
                returnHref: "messageReturn",
                exampleHref: "messageExample",
                otherHref: "messageOther",
                trContent: [{
                    tdContent: ["Topic名称", "属性"]
                }, {
                    tdContent: ["idankee/topic", "系统通知"]
                }, {
                    // eslint-disable-next-line no-template-curly-in-string
                    tdContent: ["idankee/${appid}/topic", "开发者通知"]
                }, {
                    // eslint-disable-next-line no-template-curly-in-string
                    tdContent: ["idankee/${appid}/${deviceType}/topic", "设备类型通知"]
                }, {
                    // eslint-disable-next-line no-template-curly-in-string
                    tdContent: ["idankee/${appid}/${deviceType}/${deviceNumber}/topic", "设备通知"]
                }],
                returnThContent: ["返回参数", "类型", "可否为空", "属性"],
                returnContent: [{
                    tdContent: ["返回参数", "类型", "可否为空", "属性"]
                }, {
                    tdContent: ["pushtype", "int", "否", "0消息 1指令"]
                }, {
                    tdContent: ["pushtitle", "String", "否", "推送标题"]
                }, {
                    tdContent: ["pushtxt", "String", "否", "推送内容"]
                }, {
                    tdContent: ["createdate", "String", "否", "创建日期"]
                }, {
                    tdContent: ["createtime", "String", "否", "创建时间"]
                }],
                pre: '\n{\n    "pushtype": 0,\n    "pushtitle":"有版本更新",\n    "pushtxt":"V2.1版本需要更新，点击链接更新http://xxxxxxxx",\n    "createdate":"2019-07-05"，\n    "createtime":"11:20:20"，\n}',
                // eslint-disable-next-line no-template-curly-in-string
                otherMore: "其中${appid}替换为开发者appid，${deviceType}替换为设备类型编号，${deviceNumber}替换为设备编号，分别监听以上四个topic才能接收到全部推送，否则，可能对应的topic消息无法接收到"
            },
            navTwo: [{
                navName: "监听地址以及账户",
                href: "#messageAddress"
            }, {
                navName: "需要监听的topic列表",
                href: "#messageRequest"
            }, {
                navName: "返回参数列表",
                href: "#messageReturn"
            }, {
                navName: "数据格式",
                href: "#messageExample"
            }, {
                navName: "其他说明",
                href: "#messageOther"
            }]
        }],
    leftActive: {
        one: 0,
        two: 999,
        three: 999,
        index: []
    },
    // textContent: {
    //     title: "收费说明",
    //     test: ["", "各功能模块收费详情"],
    //     thContent: ["收费模块", "收费金额", "收费说明"],
    //     testHref: "getMoney",
    //     trContent: [{
    //         tdContent: ["设备注册", "5元/台", "设备按数量计费，前1000台免费，后面每增加一台5元/月。"]
    //     }, {
    //         tdContent: ["设备续期", "5元/台", "设备过期后，续期5元/台/月。设备到期自动续费。若余额不足，设备将被停用。"]
    //     }, {
    //         tdContent: ["计费模块", "20元/月", "计费模块包含后台管理模块中的IOT管理-统计信息，IOT管理-大屏展示，IOT管理-消息推送。\n  按月收费，20元/月，到期后需手动续费。"]
    //     }, {
    //         tdContent: ["AI生成图片api", "0.1元/张", "调用AI生成图片接口，按申请生成图片张数收费，1张0.1元，8张0.8元。"]
    //     }, {
    //         tdContent: ["AI检测报告api", "1元/次", "调用AI检测报告API获取检测结果，每次1元"]
    //     }, {
    //         tdContent: ["AI图像处理api", "0.1元/维度", "调用AI分析api获取检测结果，每个维度0.1元\n\t接口维度包括：肤质、痘痘、黑眼圈、黑头、抬头纹、法令纹"]
    //     }, {
    //         tdContent: ["AI分析接口api", "0.1元/维度", "调用AI图像处理api获取图像处理结果，每个维度0.1元\n\t接口维度包括：人脸关键点、脸型年龄等基础数据、棕色图、红色图、肤色图、斑点图、紫外黑白图、黑头检测、毛孔检测、皱纹图"]
    //     }, {
    //         tdContent: ["图片API调用", "0.000001元/次", "调用图片上传、图片下载、图片删除API一次一扣，调用完直接扣除。"]
    //     }, {
    //         tdContent: ["图片存储收费", "0.00017元/GB", "图片上传后，存储空间按小时计费，计算前一小时的费用，计量单位GB。\n  费用截取到分。低于1分钱的部分将不扣除费用。"]
    //     }, {
    //         tdContent: ["图片下载收费", "0.5元/GB", "下载图片后，流量按小时计费，计算前一小时的下载总流量，计量单位GB。"]
    //     }]
    // }
}
