import React from 'react';
import "animate.css";
import WOW from 'wowjs';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import './accessGuide.scss';
// import logo from '../../static/home/DJM-Logo@2x.png';
import AccessGuideImg from "../../static/productService/Access_guide.png";

class AccessGuide extends React.Component {
    constructor(Props: any) {
        super(Props);
        this.state = {};
        // This binding is necessary to make `this` work in the callback
    }
    componentDidMount() {
        const wow = new WOW.WOW({ live: false,mobile:true });
        wow.init();
    }
    render() {
        return (
            <>
                <ReactCSSTransitionGroup
                    transitionLeave={true}
                    transitionEnterTimeout={2500}
                    transitionLeaveTimeout={1500}
                    transitionName="animate__animated"
                >
                    <img className={"wow slideInRigh animate__animated animate__fadeInDown access-guide-img"} data-wow-offset="200" src={AccessGuideImg} alt="" />
                    <div className="access-guide-bottom-centent">
                        {[
                            {
                                name: "注册账号",
                                centent: "填写联系方式并提交",
                                imgSrc: ""
                            }, {
                                name: "申请认证",
                                centent: "专员会与您电话联系确认服务需求",
                                imgSrc: ""
                            }, {
                                name: "充值",
                                centent: "签订服务合同，获得开发者权限",
                                imgSrc: ""
                            }, {
                                name: "调用接口",
                                centent: "创建应用，获取密码，接入服务",
                                imgSrc: ""
                            },
                        ].map((item, index) => {
                            return (
                                <div className="bottom-item" key={index}>
                                    <strong className={"wow slideInRigh animate__animated animate__flipInX name"} data-wow-offset="200">{item.name}</strong>
                                    {/* <div className={"centent"}>{item.centent}</div> */}
                                </div>)
                        })}
                    </div>
                </ReactCSSTransitionGroup>
            </>
        )
    }
}

export default AccessGuide
