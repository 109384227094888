import Types from '../../action/types';
import ThemeFactory, {ThemeFlags} from "../../res/styles/ThemeFactory";

const defaultState = ThemeFactory.createTheme(ThemeFlags.Default)
export default function onAction(state = defaultState, action:any) {
    switch (action.type) {
        case Types.THEME_CHANGE:
            // console.log(action);
            return {
                ...state,
                width: action.theme.width,
                AIslidesPerView: action.theme.AIslidesPerView
            };
        // case Types.SHOW_THEME_VIEW:
        //     return {
        //         ...state,
        //         customThemeViewVisible: action.customThemeViewVisible,
        //     };
        default:
            // console.log(defaultState);
            return state;
    }

}
