import 'react-app-polyfill/ie9';


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './pages/index/index.css';
import Index from './pages/index/App';
import AboutUs from './pages/aboutUs/aboutUs';
import OpenPlatform from './pages/openPlatform/openPlatform';
import ProductService from './pages/productService/productService';
import Solution from './pages/solution/solution';
// import Loadable from 'react-loadable'

import { Provider } from 'react-redux'
import store from './store'

import FooterCus from './component/footer/footer'
import HeaderCus from './component/header/header'
import ScrollToTop from './component/ScrollToTop/scrollToTop.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import * as serviceWorker from './serviceWorker';

// console.log("<------- store ------->");
// console.log(store);
export default class App extends Component<{}, any> {
    render() {
        return (
            <Router>
                <>
                    <HeaderCus />
                    <ScrollToTop>
                        <Switch>
                            <Route path="/aboutUs" component={AboutUs} />
                            <Route path="/productService/" component={ProductService}>
                                <Route path="/productService/:user" component={ProductService} />
                            </Route>
                            <Route path="/solution" component={Solution} />
                            <Route path="/openPlatform" component={OpenPlatform} />
                            <Route path="/index" component={Index} />
                            <Redirect to="/index" />
                        </Switch>
                    </ScrollToTop>
                    <FooterCus name="FooterCus" />
                </>
            </Router>
        );
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
